.hero_course {
  /* padding: 56px 0 92px;
  background-image: url("./../../assets/images/english/hero-english.png"); */
  padding: 72px 0 86px;
  background-image: url("./../../assets/images/english/hero-english.webp");
  background-position: unset;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero_course .hero_img {
  position: relative;
  top: unset;
  bottom: -80px;
}

.hero_course .heading_course {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #f2f2f2;
  margin-bottom: 11px;
}

.hero_course .heading_course span {
  color: #f0c02a;
}

.hero_course .description_course {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 8px;
  color: #f2f2f2;
}

.hero_course .description_course span {
  font-weight: 700;
}

.description_course.yellow span {
  color: #f0c02a;
}

.hero_course .author_course {
  margin-top: 36px;
  display: flex;
}

.hero_course .author_course .logo img {
  margin-right: 16px;
}

.hero_course .author_course .text {
  font-weight: 400;
  font-size: 13px;
  line-height: 125.18%;
  color: rgba(242, 242, 242, 0.7);
}

#english {
  text-decoration: none !important;
}

.english-card {
  background: #ffffff;
  box-shadow: 0px 0px 5.98477px 0.997461px rgba(0, 0, 0, 0.1);
  border-radius: 9.99999px;
  margin-bottom: 32px;
  color: #000;
}
.wrap {
  position: relative;
}
.wrap .thumbnail-card > img {
  width: 100% !important;
  border-radius: 8.35999px;
  height: 190px;
  object-fit: cover;
}
.thumbnail-card-chro > img {
  width: 100% !important;
  object-fit: contain;
  border-radius: 8.35999px;
}
.other img {
  object-fit: cover !important;
}

.wrap .price-card {
  position: absolute;
  background: #306ccc;
  border-radius: 8.57143px 0px 0px 0px;
  font-weight: 700;
  font-size: 27.4286px;
  line-height: 41px;
  color: #fff;
  bottom: 0;
  right: 0;
  padding: 6px 11px;
}
.wrap .price-card-chro {
  position: absolute;
  background: #306ccc;
  border-radius: 8.57143px 0px 10px 0px;
  font-weight: 700;
  font-size: 27.4286px;
  line-height: 41px;
  color: #fff;
  bottom: 0;
  right: 0;
  padding: 6px 11px;
}

.wrap .price-card .currency {
  font-weight: 600;
  font-size: 10.2857px;
  line-height: 15px;
  position: relative;
  top: -20px;
}
.wrap .price-card-chro .currency {
  font-weight: 600;
  font-size: 10.2857px;
  line-height: 15px;
  position: relative;
  top: -20px;
}

.wrap .price-card .month {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
}
.wrap .price-card-chro .month {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
}

.subs-promo__wrap {
  background-color: #f5f8fb;
  padding: 36px 0;
  position: relative;
}

.subs-promo__wrap .subtitle-subs__promo {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 14px;
}

.subs-promo__wrap img#person {
  position: relative;
  bottom: unset !important;
  width: 380px;
  margin-bottom: 16px !important;
  z-index: 9;
}

.subs-promo__wrap img#polkadot {
  position: absolute;
  right: 20px;
  bottom: 50px;
}

.circle-ornament {
  width: 318px;
  height: 318px;
  border-radius: 200px;
  background: #fbede5;
  position: absolute;
  top: -60px;
  bottom: unset !important;
  left: -80px;
  clip: rect(60px, 418px, 347px, 0px);
}

@media (min-width: 992px) {
  .subs-promo__wrap img#person {
    margin-bottom: 0 !important;
    position: absolute;
    bottom: -36px !important;
    width: 380px;
  }
  .circle-ornament {
    width: 318px;
    height: 318px;
    border-radius: 200px;
    background: #fbede5;
    position: absolute;
    bottom: -70px !important;
    top: unset !important;
    left: -80px;
    clip: rect(0px, 336px, 247px, 0px);
  }
}

.circle-ornament__purple {
  width: 1074px;
  height: 400px;
  border-radius: 50%;
  background: #e5f0fb;
  position: absolute;
  bottom: -152px;
  right: -150px;
  clip: rect(0px, 976px, 247px, 0px);
}

.subs-promo__wrap .subtitle-subs__promo span {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.subs-promo__wrap .subtitle-subs__promo span.orange {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #f87932;
  background-color: unset !important;
}

.cta-subs__promo button {
  padding: 12px 24px !important;
  background: #306ccc;
  color: #fff !important;
  border-radius: 9.97041px;
}

.wrap-info {
  padding: 16px 20px 20px;
}

.wrap-info .english-label {
  width: fit-content;
  background: #c5dbff;
  border-radius: 38px;
  padding: 4px 12px;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  color: #306ccc;
  margin-bottom: 16px;
  margin-right: 8px;
}

.wrap-info .english-label-subs {
  width: fit-content;
  background: #0fb76b;
  padding: 4px 12px;
  border-radius: 19.9744px;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  margin-bottom: 16px;
}

.other-name {
  height: 46px;
  overflow: hidden;
  display: -webkit-box;
  line-height: 24px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.wrap-info .english-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  height: 60px;
  color: #2d2c2a;
  margin-bottom: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.wrap-info .english-instructor {
  display: flex;
  margin-bottom: 18px;
}

.wrap-info .english-info {
  margin-left: 12px;
}

.wrap-info .english-info .name,
.wrap-info .english-info .level {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #2d2c2a;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.wrap-info .english-info .level {
  font-weight: 400;
}

.wrap-info .english-desc {
  display: flex;
}

.english-desc.border-total {
  padding-top: 16px;
  border-top: 0.5px solid #cfcfcf;
}

.wrap-info .english-desc .total-lesson,
.wrap-info .english-desc .total-duration {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2d2c2a;
  margin-right: 12px;
}

.wrap-info .english-desc .total-lesson > span,
.wrap-info .english-desc .total-duration > span {
  margin-right: 8px;
}

.english-subs {
  padding: 22px 27px;
  background: linear-gradient(
    176deg,
    rgba(10, 45, 103, 1) 0%,
    rgba(9, 67, 101, 1) 47%,
    rgba(6, 131, 96, 1) 100%
  );
  border-radius: 12px;
  margin-bottom: 42px;
  align-items: center;
}

.promo-wrap {
  margin-top: 62px;
}

.promo-wrap .title-promo {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 28px;
}

.promo-wrap .title-promo span {
  color: #f87932;
}

.promo-wrap .dev-promo,
.desc-wrap .desc-promo {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #2d2c2a;
  margin-bottom: 24px;
  /* display: flex;
  align-items: center; */
}

.promo-wrap .src-promo {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d2c2a;
}

#src.src-promo {
  font-style: italic;
}

.promo-wrap .dev-promo span,
.desc-wrap .desc-promo span {
  font-weight: 700;
  display: inline !important;
}

.promo-wrap img,
.desc-wrap img {
  width: inherit;
}

.english-subs .title,
.subs-promo__wrap .title-subs__promo {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  margin-bottom: 11px;
}

.subs-promo__wrap .title-subs__promo {
  color: #000 !important;
  margin-bottom: 8px !important;
}

.english-subs .title span,
.subs-promo__wrap .title-subs__promo span {
  color: #f0c02a;
}

.english-subs .desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
.english-subs .desc span {
  color: #f0c02a;
  font-weight: 700;
}

.english-subs .cta,
.promo-english .promo-cta button {
  background: #306ccc;
  border-radius: 9.97041px;
  padding: 14px 25px;
  font-weight: 600;
}

.title-testimoni {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
}

.title-testimoni span {
  color: #f87932;
}

.title-testimoni span.blue-text {
  color: #306ccc;
}

.total-course,
.other-course {
  font-size: 30px;
  line-height: 45px;
  color: #2d2c2a;
  margin-bottom: 32px;
  font-weight: 700;
}

.total-course span {
  font-weight: 700;
}

.dots-img {
  right: -60px;
  top: -200px;
  z-index: -1;
}

.promo-english {
  padding: 56px 0 42px;
  position: relative;
}

.img-circle {
  position: absolute;
  right: 0 !important;
  bottom: 100px;
  z-index: -10;
}

.promo-english .subs-img {
  width: inherit;
}

.promo-english .container {
  height: 100%;
}

@media (min-width: 992px) {
  .promo-english .container {
    height: calc(100vh - 124px);
  }
  .hero_course .hero_img {
    position: absolute;
    /* top: -38px;
    bottom: 0 !important; */
  }
}

.promo-english .promo-bg {
  position: absolute;
  left: -90%;
  top: -30%;
}

@media (min-width: 768px) {
  .promo-english .promo-bg {
    position: absolute;
    left: -20%;
    /* .promo-english .promo-bg    left: -110%; */
    top: -30%;
  }
}

.promo-english .promo-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #306cce;
  margin-bottom: 16px;
}
.promo-english .promo-title span {
  color: #f87932;
  font-weight: 700;
}
.promo-english .promo-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3f3e3d;
  margin-bottom: 10px;
}

.info_wrap {
  position: relative;
  display: flex;
  top: -24px;
  background: #fff;
  padding: 14px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 42px;
}

.info_wrap .icon img {
  margin-right: 8px;
}

.info_wrap .text .info_title,
.info_wrap .text .info_desc {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2d2c2a;
}

.info_wrap .text .info_desc {
  font-size: 14px;
  font-weight: 400;
}

.other-course {
  margin-top: 56px;
  margin-bottom: 32px;
}

.testimoni-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .testimoni-container {
    display: flex;
    flex-direction: row;
  }
}

.testimoni-container .testimoni-profile img,
.testimoni-container .testimoni-profile .img-testimoni {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 70px;
  margin-right: 40px;
}

.testimoni-container .testimoni-content .text {
  font-weight: 400 !important;
  font-size: 20px !important;
  font-style: italic;
  line-height: 30px !important;
  color: #2d2c2a !important;
  margin-bottom: 8px;
}

.testimoni-container .testimoni-content .info {
  display: flex;
}

.testimoni-container .testimoni-content .info .name {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #2d2c2a;
}
