.course_front__prereq__container {
  margin-bottom: 40px;
}
.course_front__prereq__warning_icon {
  fill: #fff;
  width: 24px;
  height: 24px;
}
.course_front__prerequisite_item,
.course_front__sertifikasi__item {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  background: #ffffff;
  /* Shadow-4dp */
  padding: 12px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.course_front__prerequisite_item {
  padding: 12px 42px;
}
.course_front__prerequisite_item:hover {
  text-decoration: none !important;
  /* background-color: #0f59c7 !important; */
}
.course_front__prerequisite_item:not(:last-child) {
  margin-bottom: 24px;
}
.course_front__prerequisite_img__wrapper {
  flex-shrink: 0;
}
.ghost-prepare__certificate {
  border: 1px solid #000000;
  border-radius: 8px;
  background-color: unset !important;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #2b2b2b;
  height: 70px;
  width: 100%;
  line-height: 60px;
}
.ghost-prepare__certificate:hover {
  border: 1px solid #000000;
  border-radius: 8px;
  background-color: unset !important;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #131313 !important;
  width: 100%;
  height: 70px;
}
.course_front__prerequisite__course {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #383838;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.course_front__prerequisite__course:hover {
  text-decoration: none !important;
}
.course_front__enroll_btn {
  border: 1px solid #383838;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #383838;
  background-color: transparent !important;
}
.course_front__enroll_btn:hover {
  background-color: #0f59c7 !important;
  border: 1px solid #0f59c7 !important;
}
.course_front__prerequisite_img__wrapper > img {
  border-radius: 24px;
  width: 48px;
  height: 48px;
  object-fit: cover;
}

@media (min-width: 768px) {
  .course_front__prerequisite_item,
  .course_front__sertifikasi__item {
    font-size: 21px;
    line-height: 32px;
  }
}
