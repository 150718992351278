.error_page__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.error_page__icon svg {
  fill: #0790fd;
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
}

.error_page__title {
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #000000;
  margin-bottom: 24px;
}

.error_page__subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 56px;
}

.error_page__btn_home {
  background-image: linear-gradient(93.09deg, #3776f4 0.51%, #0095ff 100%);
  box-shadow: 0px 18px 40px -12px rgba(21, 67, 185, 0.25);
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding: 18px 32px;
  display: inline-block;
  transition: 0.4s ease;
}

.error_page__btn_home:hover {
  opacity: 0.7;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.error_page__right_container {
  background-image: linear-gradient(93.09deg, #3776f4 0.51%, #0095ff 100%);
  box-shadow: 0px 18px 40px -12px rgba(21, 67, 185, 0.25);
  border-radius: 8px;
  color: #fff;
  text-align: center;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  order: 1;
}

.error_page__code {
  font-weight: bold;
  font-size: 168px;
  line-height: 252px;
  color: #ffffff;
}

.error_page__code_desc {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.error_page__left_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  order: 2;
}
@media (max-width: 768px) {
  .error_page__left_container {
    height: 100vh;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .error_page__container {
    min-height: calc(100vh - 66px);
  }

  .error_page__right_container {
    min-height: 100%;
    order: 2;
    display: flex;
  }
  .error_page__left_container {
    order: 1;
  }
}
