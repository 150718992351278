.lesson-container {
  margin-bottom: 0;
  padding: 0;
}

.dyntube-iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  background-color: #ababab;
  border-radius: 8px;
}
.dyntube-iframe-container.h5p {
  padding-top: 62%;
  background-color: transparent;
}
@media (min-width: 576px) {
  .dyntube-iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    background-color: #ababab;
    border-radius: 8px;
  }
  .dyntube-iframe-container.h5p {
    padding-top: 69%;
    background-color: transparent;
  }
}

.dyntube-iframe-container::before {
  content: "Memuat Video...";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dyntube-responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-loader-title {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
}

.next-button,
.previous-button {
  font-size: 16px;
  font-weight: 700;
  margin-top: 24px;
  cursor: pointer;
}
