/* webinar flyer start*/
.speaker-photo {
  text-align: center;
}

.speaker-photo img {
  width: 100%;
  margin-bottom: 24px;
}

.bg-korporasi {
  background-image: url("./../../images/home/section-1.webp");
  background-repeat: no-repeat;
  height: 50vh;
  background-position: center;
  background-size: contain;
}

.bg-individu {
  background-image: url("./../../images/home/section-2.webp");
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  background-size: contain;
}

.detail-speaker .detail-name__speaker {
  color: #f0c02a;
  text-align: center;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.8px;
}

.detail-speaker .detail-desc__speaker {
  color: #f2f2f2;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 146.182%;
  /* 29.236px */
  letter-spacing: 0.4px;
  margin-bottom: 42px;
}

.join-img-mobile {
  text-align: center;
  display: flex;
  justify-content: center;
}

.join-img img {
  margin-bottom: 16px;
  max-width: 50%;
}

.webinar-schelude {
  display: flex;
  margin-bottom: 22px;
}

.webinar-schelude .webinar-left {
  padding: 6px 12px;
  background: #f0c02a;
  color: #063682;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}

.webinar-schelude .webinar-right {
  padding: 6px 12px;
  background: #d7a815;
  color: #063682;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}

.webinar-title {
  color: #f2f2f2;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 5px;
}

.webinar-title._webinar {
  color: #383838;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 5px;
}

.webinar-description {
  color: #f2f2f2;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 29px;
}

.cta-wrap {
  display: flex;
  gap: 32px;
  align-items: center;
  margin-bottom: 32px;
}

.cta-wrap .cta-description {
  color: #bababa;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: none;
  line-height: normal;
}

.cta-description-mobile {
  display: block;
  color: #bababa;
  font-family: Poppins;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ribbon-free {
  position: absolute;
  top: 0;
  right: 5%;
  width: 60px;
}

@media (min-width: 991px) {
  .speaker-photo {
    text-align: center;
  }

  .speaker-photo img {
    width: unset;
    margin-bottom: 24px;
  }

  .detail-speaker {
  }

  .detail-speaker .detail-name__speaker {
    color: #f0c02a;
    text-align: center;
    font-family: Open Sans;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 0.8px;
  }

  .detail-speaker .detail-desc__speaker {
    color: #f2f2f2;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 146.182%;
    /* 29.236px */
    letter-spacing: 0.4px;
    margin-bottom: 0;
  }

  .join-img img {
    margin-bottom: 16px;
    max-width: 50%;
  }

  .webinar-schelude {
    display: flex;
    margin-bottom: 22px;
  }

  .webinar-schelude .webinar-left {
    padding: 6px 12px;
    background: #f0c02a;
    color: #063682;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
  }

  .webinar-schelude .webinar-right {
    padding: 6px 12px;
    background: #d7a815;
    color: #063682;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
  }

  .webinar-title {
    color: #f2f2f2;
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .webinar-title._webinar {
    color: #383838;
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .webinar-description {
    color: #f2f2f2;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 29px;
  }

  .cta-wrap {
    display: flex;
    gap: 32px;
    align-items: center;
  }

  .cta-wrap .cta-description {
    color: #bababa;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: block;
    line-height: normal;
  }

  .cta-description-mobile {
    display: none;
    color: #bababa;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ribbon-free {
    position: absolute;
    top: 0;
    right: 5%;
    width: 100px;
  }
}

@media (min-width: 1024px) {
  .speaker-photo {
    text-align: center;
  }

  .speaker-photo img {
    width: unset;
    margin-bottom: 24px;
  }

  .detail-speaker {
  }

  .detail-speaker .detail-name__speaker {
    color: #f0c02a;
    text-align: center;
    font-family: Open Sans;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0.8px;
  }

  .detail-speaker .detail-desc__speaker {
    color: #f2f2f2;
    text-align: center;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 146.182%;
    /* 29.236px */
    margin-bottom: 0;
    letter-spacing: 0.4px;
  }

  .join-img img {
    margin-bottom: 16px;
    max-width: 50%;
  }

  .webinar-schelude {
    display: flex;
    margin-bottom: 22px;
  }

  .webinar-schelude .webinar-left {
    padding: 6px 12px;
    background: #f0c02a;
    color: #063682;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
  }

  .webinar-schelude .webinar-right {
    padding: 6px 12px;
    background: #d7a815;
    color: #063682;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
  }

  .webinar-title {
    color: #f2f2f2;
    font-family: Open Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .webinar-title._webinar {
    color: #383838;
    font-family: Open Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .webinar-description {
    color: #f2f2f2;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 29px;
  }

  .cta-wrap {
    display: flex;
    gap: 32px;
  }

  .cta-wrap .cta-description {
    color: #bababa;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cta-description-mobile {
    display: none;
    color: #bababa;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ribbon-free {
    position: absolute;
    top: 0;
    right: 5%;
    width: 100px;
  }
}

/* webinar flyer end */

.new_banner_bg_home, .new_banner_bg_home2, .new_banner_bg_home3, .new_banner_bg_home4 {
  padding-top: 64px;
}

.new_banner_bg_home {
  background-image: url("./../../images/home/background-banner-brevet.webp");
  height: 95vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

.new_banner_bg_home2 {
  background-image: url("./../../images/home/background-anner-english.png");
  height: 95vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

.new_banner_bg_home3 {
  background-image: url("./../../images/home/background-banner-brevet2.png");
  height: 95vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

.new_banner_bg_home4 {
  background-image: url("./../../images/home/background-banner-webinar.png");
  height: 95vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}


@media (min-width: 1198px) {
  .new_banner_bg_home {
    height: 84vh;
  }

  #home {
    /* margin-top: 88px; */
  }

  .new_banner_bg_home2 {
    height: 84vh;
  }

  .new_banner_bg_home3 {
    height: 84vh;
  }

  .new_banner_bg_home4 {
    height: 84vh;
  }
}

.new_hero-shot1 {
  background-image: url("./../../images/home/banner_icon_home.png");
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

@media (max-width: 767px) {
  .new_hero-shot1 {
    background-image: url("./../../images/home/banner_icon_home.png");
    height: 70vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
  }

  .btn_new_home_banner {
    margin-top: 100px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .new_hero-shot1 {
    background-image: url("./../../images/home/banner_icon_home.png");
    height: 90vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
  }
}

.bg_img_setion_getpat {
  background-image: url("./../../images/home/bg-learning.webp");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

@media (min-width: 768px) {
  .container__section {
    padding-left: 50px;
    padding-right: 50px;
  }

  .container__section .row {
    padding-top: 50px;
  }
}

.text_home_banner {
  position: relative;
  z-index: -1;
}

.hastag_banner_home {
  color: #fff;
  font-family: Poppins;
  font-size: 19.646px;
  font-style: normal;
  font-weight: 400;
  line-height: 119.182%;
  /* 23.415px */
}

.hastag_banner_home span {
  color: #fff;
  font-size: 19.646px;
  font-style: normal;
  font-weight: 700;
  line-height: 119.182%;
  /* 23.415px */
}

.new_title-home__wrapper {
  color: #fff;
  font-family: Open Sans !important;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
}

.new_title-home__wrapper span {
  color: var(--Color, #ffd23f);
}

.new_subtile-home__wrapper {
  color: #ffd23f;
  font-family: Open Sans !important;
  font-size: 52px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.56px;
}

.new_subtile-home__wrapper span {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.2px;
}

.new_desc-home__subtitle {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}

.new_desc-home__subtitle span {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
}

.footer__desc-home {
  color: rgba(242, 242, 242, 0.7) !important;
}

#home .carousel-control-prev,
#home .carousel-control-next {
  width: unset;
  background-color: unset;
  opacity: 1;
  z-index: 9;
}

#home .carousel-control-prev .carousel-control-prev-icon {
  background-image: url("../../images/home/icon-left-arrow.png") !important;
  width: 40px;
  height: 40px;
}

#home .carousel-control-next .carousel-control-next-icon {
  background-image: url("../../images/home/icon-right-arrow.png") !important;
  width: 40px;
  height: 40px;
}

.new_wrapper-hero {
  z-index: 9;
}

.new_desc-home__footer {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.new_desc-home__footer a {
  color: #ffd23f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  text-decoration-line: underline;
}

.btn_courses_popular {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: unset;
  border: unset;
  position: relative;
  border-bottom: 3px solid transparent;
  border-radius: unset;
}

.btn_courses_popular_link {
  color: #0f59c7;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: unset;
  border: unset;
  position: relative;
  border-bottom: 3px solid transparent;
  border-radius: unset;
  text-decoration: underline;
}

.btn_courses_popular_link:hover {
  text-decoration: none;
}

#bnspMsdm.btn_courses_popular:hover,
#brevetPajak.btn_courses_popular:hover,
#bahasaInggris.btn_courses_popular:hover {
  background-color: unset !important;
  color: #0f59c7;
  border-bottom: 3px solid #0f59c7;
}

.btn_courses_popular.active-btn {
  color: #0f59c7;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 3px solid #0f59c7;
}

.btn-primary:focus {
  background-color: unset;
}

/* .btn__2:focus {
  color: #fff !important;
  background-color: #306ccc;
} */

.btn__1:focus {
  color: #306ccc !important;
}

.btn_new_home {
  color: #f2f2f2;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 12px;
  background: #0f59c7;
  display: inline-flex;
  padding: 12px 24px;
  align-items: flex-start;
}

.btn_new_home:focus {
  color: #0f59c7;
}

.btn_new_home:hover {
  display: inline-flex;
  color: #0f59c7;
  padding: 12px 24px;
  align-items: flex-start;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #0f59c7;
}

.btn_new_home_path {
  background: unset;
  display: inline-flex;
  padding: 12px 24px;
  align-items: flex-start;
  border-radius: 12px;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.desc_text_banner {
  color: #fff;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 11px;
}

.desc_text_banner_subtitle {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 50px;
}

.title_left_topik {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.desc_left_topik {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.link_topik_pelathan {
  display: inline-flex;
  color: #6897e1;
  padding: 4px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 38px;
  border: 2px solid #6897e1;
  margin-right: 5px;
  font-weight: 500;
  margin-bottom: 10px;
}

.link_topik_pelathan:hover {
  background: #6897e1;
  color: #fff;
  text-decoration: none;
}

.link_topik_pelathan_lainnya {
  color: #306ccc;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
}

.link_topik_pelathan_lainnya::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  background-image: url("../../images/icon-down-blue.png");
  width: 20px;
  height: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  .splide__track {
    padding-bottom: 30px;
  }
}

.home_page_client .splide__arrow--next {
  right: 1em !important;
}

.bg__half-circle {
  right: -100px;
  top: -173px;
  z-index: -1;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .bg__half-circle {
    right: -20px;
    top: -173px;
    z-index: -1;
  }
}

@media (min-width: 768px) {
  #home > .carousel-control-prev {
    left: 5px;
    width: 4%;
  }

  #home > .carousel-control-next {
    right: 5px;
    width: 4%;
  }
}

.btn-outline {
  border: 3px solid #0f59c7 !important;
  background: white;
  color: #0f59c7 !important;
  border-radius: 8px !important;
}
.btn-outline:hover {
  text-decoration: none;
  border: unset !important;
}

.read_more__button {
  color: #2d2c2a !important;
  border: unset !important;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: unset !important;
  padding: 12px 24px;
}

.read_more__button_english,
.read_more__button_sales {
  background: #0f59c7;
  color: #fff !important;
  border: unset !important;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: unset !important;
  padding: 12px 24px;
}

.read_more__button_outline {
  background: transparent;
  color: #fff !important;
  border: 3px solid #fff !important;
  text-align: center;
  border-radius: 8px !important;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: unset !important;
  padding: 12px 24px;
}

.read_more__button_outline:hover {
  background: #fff;
  color: #000 !important;
  border: 3px solid #fff !important;
  text-decoration: none !important;
  text-align: center;
  border-radius: 8px !important;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: unset !important;
  padding: 12px 24px;
}

.footer-wrapper .nib__footer-satukelas {
  color: #50504f;
  width: unset;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.5%;
  /* 24.08px */
}

.footer-wrapper .sosmed__satukelas-footer {
  color: #50504f;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.5%;
}

.footer-wrapper p#address_footer a:hover {
  color: #0f59c7;
}

.wrapp__benefit-home {
  width: 270px;
  height: 172px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

@media (min-width: 768px) {
  .wrapp__benefit-home {
    top: -144px;
  }
}

.title__benefit-home {
  color: #043f39;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.desc__benefit-home {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home_container__title-section {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.wrapp_terpopular_section::before {
  content: "";
  width: 100%;
  border-bottom: 2px solid #d1d1d1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 992px) {
  .btn__class-terpopuler-home {
    flex: unset;
    width: unset;
    max-width: unset;
  }
}

.wording__footer-satukelas {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
}

.address__footer-satukelas a {
  color: #50504f;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.5%;
  /* 24.08px */
}

@media (min-width: 1200px) {
  .new_desc-home__subtitle-1 {
    width: 825px;
    font-size: 20px !important;
  }

  .new_desc-home__subtitle-2 {
    width: 762px;
  }
}

#homeSertifikasi .splide__arrow {
  background-color: #e9f2ff !important;
}

#homeSertifikasi .splide__arrow.splide__arrow--prev {
  left: -1em !important;
}

#homeSertifikasi .splide__arrow.splide__arrow--next {
  right: -1em !important;
  margin-right: unset;
}



.call-whatsapp_container {
  /* width: 190px; */
  height: 56px;
  width: 56px;
  position: fixed;
  border: none;
  bottom: 12%;
  z-index: 1020;
  right: 27px;
  padding: 5px !important;
  background: #fff !important;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 40px;
}

.call-whatsapp_container:hover {
  /* width: 190px; */
  height: 56px;
  width: 56px;
  position: fixed;
  border: none;
  bottom: 70px;
  z-index: 2;
  background: #fff !important;

  /* background: #ececec !important; */
  /* box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2); */
}

@media (max-width: 768px) {
  .call-whatsapp_container {
    bottom: 70px;
    right: 16px;
    z-index: 1029;
  }
}

.call-whatsapp_wrapper {
  display: flex;
  /* padding: 8px; */
  justify-content: end;
  height: 100%;
  align-items: center;
}

.call-whatsapp_logo {
  width: 100%;
  display: flex;
}

.call-whatsapp_text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  flex-shrink: 0;
  color: #0f59c7;
}