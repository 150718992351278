.file-upload-wrapper {
  width: 100%;
}
.file-upload-inner {
  width: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.dropzone-style {
  display: flex;
  justify-content: center;
  height: 170px;
  align-items: center;
  border: 2px dotted rgba(0, 0, 0, 0.25);
}
.file-upload-btn {
  width: 100%;
  border-radius: 8px;
  padding: 12px 0;
  font-weight: 600;
}
.MuiDropzoneArea-textContainer > svg {
  fill: lightgray !important;
}
.MuiFab-label > svg {
  fill: red !important;
  width: 30px !important;
}
.title_upload {
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;
}
.subtitle_upload {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.label-font {
  font-weight: 700;
  font-size: 14px;
}
