.container-membership {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
}

button {
  border-radius: 8px;
  padding: 20px;
  /* height: 70px; */
  border: none;
  font-weight: 700;
  font-size: 16px;
}

button.blue-text {
  color: #0f59c7;
  background-color: #fff;
}

button.white-text {
  color: #f8f6f6;
  background-color: #0f59c7;
  margin-bottom: 32px;
  width: 100%;
}

.button-hero {
  margin-bottom: 24px;
}
.button-hero > button {
  width: 100%;
}
@media (min-width: 992px) {
  .button-hero > button {
    width: auto;
  }
}

.hero-background {
  background: radial-gradient(
    170.97% 1262.11% at 21.67% 50%,
    #0f59c7 0%,
    rgba(15, 89, 199, 0.991615) 11.79%,
    rgba(15, 89, 199, 0.967585) 21.38%,
    rgba(15, 89, 199, 0.9296) 29.12%,
    rgba(15, 89, 199, 0.879348) 35.34%,
    rgba(15, 89, 199, 0.818519) 40.37%,
    rgba(15, 89, 199, 0.7488) 44.56%,
    rgba(15, 89, 199, 0.671881) 48.24%,
    rgba(15, 89, 199, 0.589452) 51.76%,
    rgba(15, 89, 199, 0.5032) 55.44%,
    rgba(15, 89, 199, 0.414815) 59.63%,
    rgba(15, 89, 199, 0.325985) 64.66%,
    rgba(15, 89, 199, 0.2384) 70.88%,
    rgba(15, 89, 199, 0.153748) 78.62%,
    rgba(15, 89, 199, 0.0737185) 88.21%,
    rgba(15, 89, 199, 0) 100%
  );
}

.description-background {
  background: #1b3965;
  display: flex;
}

.testimoni-background {
  background: #f8f6f6;
}

.membership-heroshot {
  display: flex;
  flex-direction: column;
}

.hero-left__side {
  width: 100%;
  /* padding-bottom: 64px; */
  display: flex;
  flex-direction: column;
  align-self: center;
  order: 2;
}
.hero-right__side {
  width: 100%;
  text-align: center;
  order: 1;
  margin-bottom: 24px;
}
.hero-right__side > img {
  padding-top: 64px;
  width: 45%;
}

@media (min-width: 992px) {
  .hero-right__side > img {
    padding-top: 64px;
    width: 70%;
  }
  .hero-left__side {
    width: 50%;
    /* padding-bottom: 64px; */
    display: flex;
    flex-direction: column;
    align-self: center;
    order: 1;
  }
  .hero-right__side {
    width: 50%;
    text-align: end;
    order: 2;
  }
  .membership-heroshot {
    display: flex;
    flex-direction: row;
  }
}

.program-width {
  width: 100%;
}
.program-list {
  display: none;
}
.program-list__mobile {
  display: block;
  padding: 24px 0 48px;
}
@media (min-width: 992px) {
  .program-list {
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
  }
  .program-list__mobile {
    display: none;
  }
}

.program-item {
  margin-left: 8px;
  border-left: 8px solid #0f59c7;
  max-width: 265px;
  height: fit-content;
}

.program-title,
.pricelist-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.pricelist-text {
  margin-left: 0px !important;
}

.program-subtitle,
.pricelist-subtitle__card {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Work Sans";
  margin-bottom: 8px;
  margin-left: 8px;
}
.pricelist-subtitle__card {
  margin-left: 0px !important;
  margin-bottom: 24px !important;
}

.program-link.redirect {
  font-family: "Work Sans";
  cursor: pointer;
  text-decoration: underline;
  margin-left: 8px;
  font-weight: 700;
}

.description-image {
  width: 100%;
  clear: both;
  float: none;
  text-align: center;
}

.description-image > img {
  width: 45%;
  text-align: center;
  padding-top: 40px;
}

.description-wrapper {
  width: 100%;
  clear: both;
}

@media (min-width: 992px) {
  .description-image {
    width: 50%;
    float: left;
    clear: none;
  }

  .description-wrapper {
    width: 50%;
    float: right;
    clear: none;
  }
  .description-image > img {
    width: auto;
  }
}

.testimoni-wrapper {
  padding: 24px 0;
}

.testimoni-title {
  text-align: left;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 20px !important;
  line-height: 28px !important;
}
.testimoni-subtitle {
  font-size: 16px !important;
  text-align: left !important;
  margin-bottom: 40px;
}

.testimoni-desktop {
  display: none;
}
.testimoni-mobile {
  display: block;
  margin-bottom: 24px;
}
@media (min-width: 992px) {
  .testimoni-title {
    text-align: center;
    margin-bottom: 24px;
  }
  .testimoni-wrapper {
    padding: 60px 0;
  }
  .testimoni-desktop {
    display: block;
  }
  .testimoni-mobile {
    display: none;
  }
}

.testimoni-card {
  padding: 24px;
  border-left: 5px solid #ffc240;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 8px 0px;
  margin-bottom: 40px;
  background-color: #fff;
}

.testimoni-text {
  margin-bottom: 8px;
  font-style: italic;
}

.testimoni-user {
  font-weight: 700;
  color: #1b3965 !important;
  font-weight: 600 !important;
}

.pricelist-wrapper {
  padding: 24px 0;
}
.pricelist-card__wrapper {
  display: block;
}
.pricelist-card {
  padding: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0 16px 16px;
}
.pricelist-recommendation {
  background-color: #0f59c7 !important;
  color: #fff;
}
.button-recommendation {
  background: #ffc240 !important;
  color: #383838 !important;
}
.pricelist-recommendation__price {
  color: #ffc240 !important;
}
.pricelist-recommendation__price > span {
  color: #fff !important;
}
@media (min-width: 992px) {
  .pricelist-wrapper {
    padding: 40px 0;
    width: 100%;
  }
  .pricelist-card__wrapper {
    display: flex;
  }
  .pricelist-card {
    padding: 24px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin: 0 16px;
  }
}

.title-hero,
.description-title,
.faq-title__card,
.faq-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #f8f6f6;
  margin-bottom: 12px;
}
.description-wrapper {
  padding: 40px 0 32px;
}
@media (min-width: 992px) {
  .title-hero,
  .description-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #f8f6f6;
    margin-bottom: 24px;
  }
  .description-wrapper {
    padding: 40px 0 0px;
  }
}

.subtitle-hero {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #f8f6f6;
  margin-bottom: 16px;
}
.pricelist-title {
  font-weight: 700;
  font-size: 20px !important;
  text-align: left;
  line-height: 28px;
  margin-bottom: 12px;
}
.pricelist-subtitle {
  text-align: left;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .subtitle-hero {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #f8f6f6;
    margin-bottom: 24px;
  }
}
@media (min-width: 992px) {
  .testimoni-title,
  .pricelist-title,
  .faq-title {
    font-weight: 700;
    font-size: 30px !important;
    line-height: 40px;
    color: #383838;
  }
  .pricelist-title {
    text-align: center;
    margin-bottom: 24px;
  }
  .testimoni-subtitle,
  .pricelist-subtitle,
  .testimoni-text,
  .description-subtitle,
  .program-link,
  .testimoni-user,
  .faq-desc__card {
    font-weight: 400;
    font-size: 18px !important;
    font-family: "Work Sans";
    line-height: 28px;
  }
  .pricelist-subtitle {
    text-align: center;
    margin-bottom: 42px;
  }
  .testimoni-subtitle {
    text-align: center !important;
    margin-bottom: 40px !important;
  }
}
.faq-text {
  color: #0f59c7 !important;
  margin-bottom: 24px;
}

.faq-title {
  margin-bottom: 24px;
}
.faq-card {
  padding: 20px 24px;
  width: 100%;
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none !important;
  margin-bottom: 24px;
}

.faq-card:hover,
.faq-toggle:hover {
  background: #fff !important;
}

.faq-toggle {
  background-color: #fff;
  padding: 0 !important;
}

.faq-title__card {
  color: #383838 !important;
  margin-bottom: 0;
}
.faq-desc__card {
  margin-top: 24px;
}
.pricelist-price {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #0f59c7;
  margin-bottom: 16px;
}
.pricelist-info {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  /* .pricelist-price {
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
    color: #0f59c7;
    margin-bottom: 16px;
  } */
  .pricelist-info {
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }
}

.pricelist-price > span {
  font-weight: 400;
  font-size: 12px;
  color: #000;
}

.description-subtitle {
  font-style: italic;
  color: #f8f6f6;
  font-family: "Work Sans";
}

.description-subtitle > span {
  font-weight: 700;
}

.description-person {
  font-weight: 700;
  color: #f8f6f6;
  font-family: "Work Sans";
}
ul#membership {
  padding-left: 16px !important;
}
ul#membership > li {
  list-style: disc !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #383838;
  margin-bottom: 24px;
}
.list-recommendation > li {
  color: #fff !important;
}
@media (min-width: 992px) {
  ul#membership > li {
    list-style: disc !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #383838;
    margin-bottom: 24px;
  }
}
