@charset "UTF-8";

.app-footer {
  display: none;
}

.logo_width {
  width: 170px;
}

.help_button .icon-question_outline {
  font-size: 16px;
}

.help_button {
  display: none;
}

.class__nav-click {
  display: block !important;
  visibility: visible !important;
}

.header_left__item:hover .mega-menu__container {
  visibility: visible;
  display: block;
}

.mega-menu__container {
  position: absolute;
  left: 0;
  right: 0;
  top: 106px;
  z-index: 9999;
  width: max-content;
  visibility: hidden;
  display: none;
  transition: all 0.4s ease;
}

.second.header_left__item:hover .menu__container,
.third.header_left__item:hover .menu__container {
  visibility: visible;
  opacity: 1;
}

.menu__container {
  width: 300px !important;
  position: absolute;
  border-radius: 8px;
  margin-left: -18px;
  top: 106px;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  background-color: #0f59c7;
}

.menu-second {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
}

.second.catalogue-course:hover .menu-second {
  visibility: visible;
  opacity: 1;
}

.blue-footer {
  font-weight: 700;
  color: #0f59c7;
}

.catalogue_megamenu {
  font-weight: 700;
}

.close_mega {
  position: absolute;
  top: 10px;
  /* bottom: 0; */
  right: 20px;
  font-weight: 700;
  z-index: 9;
  color: white;
}

.close_mega:hover {
  position: absolute;
  top: 10px;
  /* bottom: 0; */
  right: 20px;
  font-weight: 700;
  z-index: 9;
  color: white;
  cursor: pointer;
}

@media (min-width: 576px) {
  .help_button {
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 1009;
    bottom: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0px 18px 40px -12px rgb(196 196 196 / 25%);
    transition: 0.1s all ease-in;
    align-items: center;
  }

  .help_button:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1009;
    bottom: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #fff;
    transform: scale(1.1);
    box-shadow: 0px 18px 40px -12px rgb(196 196 196 / 25%);
  }
}

.button_dashboard__link {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #008bff;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
}

.button_dashboard__link > span.icon-grid1 {
  color: #fff;
}

.notification_container img {
  fill: red;
}

#size.icon-notification {
  font-size: 20px;
}

#navigate .icon-book {
  color: #008bff !important;
}

#navigate .icon-user {
  color: #008bff !important;
}

.form-group.has-error .messages .error {
  color: #d63030;
  font-size: 12px;
}

.form-group.has-error input,
.form-group.has-error textarea {
  border: 1px solid #d63030;
}

.logo_satukelas {
  background-image: url("./../../images/logo/satukelas.png");
  height: 4.5vh;
  background-size: contain;
  background-repeat: no-repeat;
}

.dropdown-item {
  font-family: "Poppins";
  font-size: 14px;
}

a.nav-content-bttn {
  text-decoration: none !important;
}

a.nav-content-bttn:hover {
  color: #007bff !important;
}

#navigate .dropdown-toggle {
  padding: 0;
  width: fit-content;
}

.search-input__course {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-wrapper {
  margin-top: 100px;
  padding: 80px 0 10px;
  background-color: #fff;
}

.footer-wrapper.bg-dark h5 {
  color: #fff !important;
}

.footer-wrapper ul li a {
  color: #2d2c2a;
  font-size: 16px;
  line-height: 34px;
  font-weight: 400;
}

.footer-wrapper ul {
  margin-bottom: 0;
  margin-top: 20px;
  padding: 0;
  font-size: 16px;
}

.footer-wrapper p {
  color: #636262;
  width: 60%;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.footer-wrapper p#title_footer {
  color: #636262 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.footer-wrapper p#address_footer {
  color: #636262 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.headline-wrap {
  background: #d6e2f5;
  padding: 20px 0;
  display: flex;
  gap: 40px;
  align-items: center;
}

.headline-more {
  color: var(--primary-brand-color-main, #306ccc);
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  flex-shrink: 0;
  cursor: pointer;
}

.headline-claim {
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 8px;
  background: var(--primary-brand-color-main, #306ccc);
  color: var(--Greyscale-10, #f2f2f2);
  text-align: center;

  /* Bodytext M/Bold */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.128px;
  padding: 8px 12px;
}

._dashboard {
  justify-content: space-between;
}
.dashboard-headline-inner {
  flex-direction: row;
}

.headline-text {
  color: var(--Greyscale-100, #1e1e1e);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.headline-text._dashboard {
  color: var(--Greyscale-100, #1e1e1e);
  text-align: justify;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
}

.headline-text span {
  font-weight: 700;
}

.header-modal_detail {
  display: flex;
  gap: 28px;
  margin-bottom: 36px;
}

.header-modal_detail .header-modal_sub {
  color: var(--Greyscale-60, #a6a6a6);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.header-modal_detail .header-modal_title {
  color: var(--Blue-Darkcobalt, #1e4380);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

.header-modal_content,
.headline-desc {
  color: var(--Text-Color, #3f3e3d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-bottom: 12px;
}

.header-modal_content span,
.headline-desc span {
  font-weight: 700;
}

.modal-detail_info .modal-content {
  padding: 40px;
}

.header-modal_info__wrap {
  border-radius: 8px;
  padding: 7px 12px;
  background: var(--primary-brand-color-surface, #d6e2f5);
  color: var(--Blue-Cobalt, #245199);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-bottom: 16px;
}

.header-modal_info__wrap span {
  font-weight: 700;
}

.header-mdoal_claim {
  text-align: center;
}

.header-mdoal_claim button {
  border-radius: 8px;
  background: var(--primary-brand-color-main, #306ccc);
  color: var(--Greyscale-10, #f2f2f2);
  text-align: center;

  /* Bodytext L/Bold */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 16px 60px;
}

.modal-detail_info .modal-content {
  overflow-x: hidden;
}

.circle-1_headline {
  position: absolute;
  background: var(--Blue-Lavender, #cbdaf3);
  opacity: 0.3;
  width: 232px;
  height: 232px;
  border-radius: 120px;
  flex-shrink: 0;
  top: -24%;
  left: -10%;
}

.circle-2_headline {
  position: absolute;
  background: var(--Blue-Lavender, #cbdaf3);
  opacity: 0.3;
  width: 146px;
  height: 146px;
  border-radius: 120px;
  flex-shrink: 0;
  bottom: -60px;
  right: -40px;
}

.circle-3_headline {
  position: absolute;
  background: var(--Blue-Lavender, #cbdaf3);
  opacity: 0.3;
  width: 28px;
  height: 28px;
  border-radius: 120px;
  flex-shrink: 0;
  bottom: 11%;
  right: 9%;
}

@media (min-width: 1100px) {
  .header__nav {
    position: fixed !important;
  }
}

@media (max-width: 901px) {
  .headline-wrap,
  .headline-wrap._dashboard,
  .dashboard-headline-inner {
    flex-direction: column;
  }
}

@media (max-width: 678px) {
  .headline-wrap,
  .headline-wrap._dashboard {
    gap: 10px;
  }
  .headline-text,
  .headline-desc {
    font-size: 12px;
  }
  .headline-more {
    font-size: 12px;
  }
  .headline-claim {
    font-size: 12px;
  }
  .header-modal_detail {
    flex-direction: column;
  }
  .header-modal_sub {
    font-size: 12px !important;
  }
  .header-modal_title {
    font-size: 20px !important;
  }
  .header-modal_content {
    font-size: 10px;
  }
  .modal-content {
    padding: 20px;
  }
  .header-modal_info__wrap {
    font-size: 12px;
  }
  .header-mdoal_claim button {
    font-size: 12px;
  }
}

@media (max-width: 1199px) {
  .app-footer {
    min-height: 56px;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #fff;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .app-footer .cart-count {
    position: absolute;
    top: 2px;
    right: 10px;
    border-radius: 15px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
  }

  .app-footer .dot-count {
    position: absolute;
    top: 5px;
    right: 15px;
    border-radius: 5px;
    width: 7px;
    height: 7px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
  }

  .app-footer a {
    padding: 6px 20px;
  }

  .app-footer a i {
    color: var(--theme-color);
    font-size: 25px;
    top: 3px;
    position: relative;
  }

  .app-footer a span {
    color: var(--theme-color);
  }
}

.swal2-popup {
  width: 512px;
  border-radius: 24px;
  padding: 32px;
}

.swal2-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.swal2-close:focus {
  box-shadow: none;
}

.header__nav {
  position: relative;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header__brevet {
  position: relative;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tutorial_modal_footer:hover {
  cursor: pointer;
  text-decoration: underline;
}
