.related_file__wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px 32px;
}
.size.icon-file:before {
  font-size: 32px;
  color: #3776f4;
}
.related_file__item_wrapper:hover {
  cursor: pointer;
}
.text_none__data {
  font-family: "Nunito";
}
.related_file__icon_wrapper {
  width: 90px;
  height: 90px;
  background: #ffffff;
  border: none !important;
  border-radius: 16px;
  filter: drop-shadow(0px 9px 30px rgba(195, 195, 195, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.related_file__icon {
  height: 50px;
  width: 35px;
  fill: #ffdb96;
}
.related_material__desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  margin-bottom: 32px;
}
.related_file__filename {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0f59c7;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

@media (min-width: 768px) {
  .scroll_related__file {
    flex-wrap: nowrap;
    width: auto;
    overflow: auto;
  }
  .related_file__icon_wrapper {
    filter: none;
  }
}
