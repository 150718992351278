.min-height {
  min-height: 300px;
}
.tutorial-modal {
  font-weight: 600;
}
.placeholder_tutorial {
  width: 400px;
  height: 250px;
  border-radius: 16px;
  background-color: lightgrey;
  box-shadow: 0px 18px 40px -12px rgb(196 196 196 / 25%);
}
.button_visit__page {
  border-radius: 8px !important;
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 8px !important;
  height: 8px !important;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0069d9;
  background-clip: padding-box;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  border-radius: 8px;
}
.carousel-indicators .active {
  opacity: 1;
  width: 24px;
  height: 8px;
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  background-color: lightgray;
  top: unset;
  bottom: 115px;
  padding: 10px;
  border-radius: 8px;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 10%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
#home > .carousel-control-next,
#home > .carousel-control-prev {
  margin: auto;
  position: absolute;
  background-color: lightgray;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 8px;
  z-index: 1;
  width: 8%;
  height: 15%;
  color: #fff;
  opacity: 0.3;
  transition: opacity 0.15s ease;
}
.btn-redirect__lp {
  border: 1px solid #0f59c7;
  border-radius: 8px;
  background-color: unset !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 60px;
  color: #0f59c7;
  padding: 0 24px;
}
#home > .carousel-control-prev {
  left: 0px;
}
#home > .carousel-control-next {
  right: 0px;
}
@media (min-width: 768px) {
  #home > .carousel-control-prev {
    left: 36px;
    width: 4%;
  }
  #home > .carousel-control-next {
    right: 36px;
    width: 4%;
  }
}
.carousel-inner {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -35px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
