@import "../../../../node_modules/react-pro-sidebar/dist/css/styles.css";

#root {
  height: 100%;
}
.size-profile {
  font-size: 16px !important;
}
.app {
  height: 100%;
  display: flex;
  position: relative;
}
.toggle_button {
  cursor: pointer;
}
.pointer-cursor {
  cursor: pointer;
}
.app .btn-toggle {
  cursor: pointer;
  color: #353535;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 15px;
  display: none;
  margin: 8px 16px;
}
.app .sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .sidebar-btn-wrapper .sidebar-btn {
  padding: 1px 15px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: #adadad;
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.app .sidebar-btn-wrapper .sidebar-btn span {
  margin-left: 5px;
  font-size: 13px;
}
.app .sidebar-btn-wrapper .sidebar-btn:hover {
  color: #d8d8d8;
}
.app .collapsed .sidebar-btn {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 40px;
  padding: 0;
}
.app main {
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.app main header h1 {
  display: flex;
  align-items: center;
  transform: translateX(-20px);
}
.app main footer {
  margin-top: auto;
  color: #888;
  text-align: center;
  background-color: #fff;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app main footer .satukelas_copyright {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(44, 44, 44, 0.5);
}
.app main footer .satukelas_copyright .black {
  color: #000;
}
.app main footer .satukelas_copyright .black svg {
  fill: #000;
}

.app main footer a {
  text-decoration: none;
  color: #888;
}
.app main footer a:hover {
  color: #000;
}
.app .social-bagdes {
  margin-top: 10px;
}
.app .social-bagdes img {
  margin: 5px;
}
.app .block {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  color: #545454;
}
.app .block > span {
  margin-left: 10px;
}
.app.rtl {
  direction: rtl;
  text-align: right;
}
.app.rtl header h1 {
  transform: translateX(20px);
}
.app.rtl .block {
  direction: rtl;
}
.app.rtl .block > span {
  margin-left: 0;
  margin-right: 10px;
}
.app.rtl .sidebar-btn-wrapper .sidebar-btn span {
  margin-left: 0;
  margin-right: 5px;
}
@media (max-width: 1199px) {
  .app .btn-toggle {
    display: flex;
  }
  .app.rtl .btn-toggle {
    margin-left: auto;
  }
  .app {
    overflow-x: hidden;
  }
  .app header h1 {
    font-size: 24px;
  }
}
.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
}
.badge.red {
  color: #fff;
  background: #d63030;
}
.badge.gray {
  color: #fff;
  background: #777;
}
.badge.yellow {
  color: #000;
  background: #ffd331;
}

.middle-sidebar-bottom {
  padding: 0 8px;
}
@media (min-width: 576px) {
  .middle-sidebar-bottom {
    padding: 0 32px;
  }
}
.fill-success {
  fill: #6cbf7f;
}

/* default font size */
h1.h1 {
  font-size: 3rem;
  line-height: 62px;
  letter-spacing: 2px;
}
h2.h2 {
  font-size: 2rem;
  line-height: 52px;
  letter-spacing: 0.5px;
}
h3.h3 {
  font-size: 1.5rem;
}
h4.h4 {
  font-size: 1.125rem;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  h1.h1 {
    font-size: 2rem;
    line-height: 40px;
    letter-spacing: 1px;
    text-align: left;
  }
  h2.h2 {
    font-size: 1.5rem;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  h3.h3 {
    font-size: 1.25rem;
  }
  h4.h4 {
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
  .home_container__subtitle {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    color: gray;
    text-align: left;
  }
  .badge-home {
    letter-spacing: 0.6px;
    font-size: 12px;
    text-transform: uppercase !important;
    font-weight: 700;
    border-radius: 12px;
  }
}
.separate-size {
  font-size: 21px;
  padding-right: 30px;
  color: #3776f4 !important;
}
.separate-size:hover {
  font-size: 21px;
  padding-right: 30px;
  text-decoration: none;
  opacity: 0.5;
  color: #3776f4 !important;
  cursor: pointer;
}
