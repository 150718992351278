.LoadingWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.6);
}

.LoadingFont {
  font-size: 2rem;
  font-weight: bold;
  color: rgb(75, 75, 75);
}
