.course_front__benefit_container,
.course_front__prereq__container {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px 32px;
}
.course_front__kurikulum_title {
  margin-bottom: 8px;
}
.course_front__kurikulum_subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 20px;
}
.course_front__benefit_item__wrapper {
  margin-bottom: 8px;
  padding: 0;
}
.text_none__data {
  font-family: "Nunito";
}
.course_front__benefit_item {
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  font-family: "Nunito", sans-serif;
}
.course_front__benefit_icon {
  margin-top: 8px;
  flex-shrink: 0;
}
.green-check {
  color: green;
  display: flex;
  padding-top: 12px;
  margin-right: 8px;
}
