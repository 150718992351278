/* utils */
section.mitra {
    padding: 40px 0;
    font-family: "Poppins", sans-serif;
}

section.blue {
    background: #0F59C7;
    color: #fff;
}

/* typography */
.mitra h1,
.mitra .heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #383838;
    margin-bottom: 24px;
}

.mitra .heading.blue {
    color: #0F59C7;
    background-color: unset;
}

.mitra .heading-light {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 24px;
    color: #fff;
}

.mitra .heading2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #383838;
}

.mitra .heading2-light {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #F8F6F6;
}

.mitra .subheading2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #383838;
}

.mitra .subheading2-light {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #F8F6F6;
}

.mitra h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
}

.mitra h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.mitra p {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    font-family: "Poppins", sans-serif !important;
}

.mitra p.blue {
    color: #0F59C7;
    background: none;
}

.mitra .p-md {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFC240;
}

.mitra .p-sm {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #383838;
}

.mitra .copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #F8F6F6;
    margin-bottom: 8px;
}

.mitra .light {
    color: #fff;
}

/* utils */
.mitra .cta {
    padding: 0 20px;
    display: inline-block;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 60px;
    background-color: #fff;
}

.mitra img {
    max-width: 100%;
}

.mitra .cta.blue {
    padding: 0 20px;
    display: inline-block;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 60px;
    color: #fff;
    background: #0F59C7;
}

.mitra .cta:hover {
    opacity: .7;
}

.mitra.blue:hover {
    background: #0F59C7;
}

.mitra a {
    text-decoration: none;
}

section.mitra .d-desktop {
    display: none;
}

@media (min-width:768px) {
    section.mitra .d-desktop {
        display: block;
    }
}

/* cards */
.mitra .container.flex {
    display: flex;
    align-items: center;
}

.mitra .container.flex .wording {
    width: 100%;
}

@media (min-width:768px) {
    .mitra .container.flex .wording {
        width: 50%;
    }
}

.mitra .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mitra .items-item {
    width: 100%;
    margin-bottom: 24px;
}

@media (min-width:768px) {
    .mitra .items-item {
        width: 32%;
        margin-bottom: 0;
    }
}

.mitra .items-item .icon-wrapper {
    width: 80px;
    height: 80px;
    background: rgba(15, 89, 199, 0.1);
    border-radius: 200px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mitra .items-item .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
}

/* section hero begins */
.mitra.hero {
    padding-bottom: 60px;
}

.mitra.hero .container {
    position: relative;

}

.mitra.hero .wording {
    width: 100%;
}

@media (min-width:768px) {
    .mitra.hero .wording {
        width: 50%;
    }
}

.mitra h1 span {
    color: #FFC240;
}

.mitra.hero .wording>* {
    margin-bottom: 24px;
}

.mitra.hero .hero-wrapper {
    position: absolute;
    width: 480px;
    right: 0;
    bottom: -60px;
}


/* section benefit begins*/
.mitra.benefit {
    box-shadow: 0px 80px 100px -35px rgba(0, 0, 0, 0.06);
}

.mitra.benefit h2 {
    padding: 10px;
    background: #0F59C7;
    color: #fff;
    text-align: center;
    margin-bottom: 24px;
}

.mitra.benefit .items-item {
    box-shadow: 0px 50px 100px -20px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    padding: 40px 24px;
    margin-bottom: 24px;
}

@media (min-width:768px) {
    .mitra.benefit .items-item {
        width: 32%;
        margin-bottom: 0;
    }
}

.mitra.benefit .items-item .info {
    padding: 0 8px;
}

.mitra.benefit .items-item .title {
    min-height: 60px;
}

.mitra.benefit .items-item .description {
    min-height: 95px;
}

/* section benefit ends*/

/* section pengertian begins*/
.mitra.definition .container {
    min-height: 600px;
}

.mitra.definition .hero-wrapper {
    margin-right: 90px;
}

.mitra.definition .wording {
    max-width: 615px;
}

.mitra.definition .wording h2 {
    margin-bottom: 24px;
}

.mitra.definition .wording p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #383838;
}

/* section pengertian ends*/

/* section produk begins */
.mitra.produk .heading {
    text-align: center;
    margin-bottom: 40px;
}

.mitra.produk .item-produk.flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 48px;
    width: 100%;
}

@media (min-width:768px) {
    .mitra.produk .item-produk.flex {
        /* justify-content: space-between; */
        width: 50%;
    }
}

.mitra.produk .table-wrapper {
    width: 218px;
    height: 206px;
    background: #174385;
    box-shadow: 0px 80px 100px -35px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 12px 0;
    margin-bottom: 40px;
}

.mitra.produk .table-wrapper table {
    text-align: center;
    width: 100%;
}

.mitra.produk .table-wrapper table th {
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: #F8F6F6;
    padding: 8px 0;
    width: 50%;
}

.mitra.produk .table-wrapper table td {
    background-color: transparent;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #FFC240;
    padding: 8px 0;
    width: 50%;
}

.mitra.produk .item-produk .graph {
    position: relative;
    margin-right: 24px;
    padding-left: 50px;
}

.mitra.produk .item-produk .graph .circle {
    width: 216px;
    height: 216px;
    background: #FFC240;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 32px;
}

.mitra.produk .item-produk .graph .square {
    width: 216px;
    height: 216px;
    background: #FF685D;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 32px;
    border-top: 108px solid #FFC240;
    border-right: 108px solid #FFC240;
    border-bottom: 108px solid transparent;
    border-left: 108px solid transparent;
}

.mitra.produk .item-produk .info span {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #174385;
}

@media (min-width:768px) {
    .mitra.produk .item-produk .info {
        padding-top: 32px;
        max-width: 254px;
    }

    .mitra.produk .item-produk>* {
        margin-bottom: 16px;
    }
}

.mitra.produk .item-produk ul {
    padding-inline-start: 0;
}

.mitra.produk .item-produk ul li {
    padding-left: 20px;
    position: relative;
}

.mitra.produk .item-produk ul li:before {
    content: '✓';
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: 1em;
}

/* section produk ends */


/* section income begins*/
.mitra.income {
    text-align: center;
    background: #F8F6F6;
}

.mitra.income h2 {
    margin-bottom: 80px;
}

.mitra .slider {
    margin-bottom: 100px;
    position: relative;
}

.mitra.income ._slider {
    -webkit-appearance: none;
    width: 80%;
    height: 16px;
    background: transparent;
    border-radius: 8px;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    position: relative;
    cursor: pointer;
}

.mitra.income ._slider::before {
    content: '';
    height: 16px;
    width: 120%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    background: rgba(15, 89, 199, 0.2);
}

._slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: radial-gradient(circle, transparent 25%, #0F59C7 25%);
    position: relative;
}

._slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: radial-gradient(circle, transparent 25%, #0F59C7 25%);
}

.mitra.income .mobile-description {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #838282;
}

.mitra.income .description {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #838282;
    position: absolute;
    display: none;
    top: 40px;
}

.mitra .nav-tabs {
    justify-content: center;
    border: none;
}

.mitra .nav-tabs .nav-link {
    background-color: transparent;
    border: none;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 40px !important;
    text-align: center;
    color: #9BB7E3;
}

.mitra .nav-tabs .nav-link:hover {
    border: none;
}

.mitra .nav-tabs .nav-link.active {
    color: #0F59C7;
    min-width: unset;
    border: none;
    background-color: transparent !important;
    text-decoration: underline;
}

@media (min-width:992px) {
    .mitra.income .description {
        display: block;
    }

    .mitra.income .mobile-description {
        display: none;
    }
}

.mitra.income .description:nth-of-type(1) {
    left: 5%;
}

.mitra.income .description:nth-of-type(2) {
    left: 30%;
}

.mitra.income .description:nth-of-type(3) {
    right: 30%;
}

.mitra.income .description:nth-of-type(4) {
    right: 5%;
}

.mitra.income .info {
    margin-bottom: 40px;
    max-width: 563px;
    margin-left: auto;
    margin-right: auto;
}

.mitra.income .info>* {
    margin-bottom: 8px;
}

/* section kemudahan */
.mitra.kemudahan .heading {
    margin-bottom: 80px;
    text-align: center;
}

.mitra.kemudahan .step-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;

}

@media (min-width:768px) {
    .mitra.kemudahan .step-container {
        height: 600px;
    }
}

.mitra.kemudahan .step-item {
    max-width: 438px;
    padding-left: 86px;
    position: relative;
}

.mitra.kemudahan .step-item .graph {
    width: 56px;
    height: 56px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(29, 51, 84, 0.08);
    border-radius: 72px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mitra.kemudahan .step-container:not(:last-of-type) .graph::after {
    width: 2px;
    height: 280px;
    border-left: 1.5px dashed #E3E1E1;
    position: absolute;
    top: 56px;
    left: 28px;
    content: "";
}

@media (min-width:768px) {
    .mitra.kemudahan .step-container:not(:last-of-type) .graph::after {
        height: 600px;
    }
}

.mitra.kemudahan .step-item .step {
    margin-bottom: 6px;
}

.mitra.kemudahan .step-item .title {
    margin-bottom: 16px;
}

/* section what is */
.mitra.affiliate .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.mitra.affiliate .container>* {
    margin-bottom: 24px;
}

.mitra.affiliate .container .video-wrapper {
    width: 100%;
}

@media (min-width:768px) {
    .mitra.affiliate .container .video-wrapper {
        width: calc(100%/2 - 90px);
    }
}

.mitra.affiliate .wording {
    text-transform: capitalize;
}

/* section alasan begins*/
.mitra.alasan {
    padding-bottom: 80px;
}

.mitra.alasan .heading2 {
    margin-bottom: 80px;
    border-left: 8px solid #0F59C7;
    padding-left: 16px;
}

.mitra.alasan .items-container {
    text-align: center;
}

.mitra.alasan .items-item .icon-wrapper {
    margin-left: auto;
    margin-right: auto;
}

/* footer */
.mitra.footer {
    padding-top: 46px;
    padding-bottom: 0;
}

.mitra.footer .container {
    position: relative;
    /* min-height: 500px; */
}

.mitra.footer .hero-wrapper {
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 370px;
}

.mitra.footer .wording {
    width: 100%;
}

@media (min-width:768px) {
    .mitra.footer .wording {
        width: 60%;
        margin-left: auto;
    }
}

.mitra.footer .cta {
    margin-bottom: 48px;
}

.mitra.footer .socials {
    display: flex;
    margin-bottom: 32px;
}

.mitra.footer .socmed-container {
    margin-right: 60px;
}

.mitra.footer .followus {
    margin-bottom: 16px;
}

.mitra.footer .socmed-wrapper {
    width: 24px;
    height: 24px;
    background: #0A66C2;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.mitra.footer .gplay-wrapper {
    width: 240px;
}

.mitra.req .container {
    position: relative;
}

.mitra.req .heading2 {
    text-align: center;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 100px;
    z-index: 1;
}

.mitra.req .kriteria-item {
    z-index: 1;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 80px 100px -35px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 40px;
    width: 100%;
}

@media (min-width:768px) {
    .mitra.req .kriteria-item {
        margin-left: 100px;
        padding: 32px 24px;
        max-width: 481px;
    }

    .mitra.req .kriteria-item.right {
        margin-left: auto;
        margin-right: 100px;
    }
}

.mitra.req .kriteria-item .icon {
    width: 87px;
    height: 87px;
    background: #006649;
    box-shadow: 0px 4px 70px rgba(91, 91, 91, 0.05);
    border-radius: 50%;
    position: absolute;
    top: 32px;
    left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mitra.req .kriteria-item .info {
    margin-left: 100px;
}

.mitra.req .kriteria-item .title {
    margin-bottom: 10px;
    font-weight: 700;
}

.mitra.req .kriteria-item .summary {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #383838;
}

.mitra.req .graph {
    display: none;
}

@media (min-width:768px) {
    .mitra.req .graph {
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        z-index: -1;
        display: block;
    }

    .mitra.req .container {
        margin-bottom: 278px;
    }

}

.circle-orange {
    width: 340px;
    height: 340px;
    border-radius: 50%;
    background: rgba(255, 194, 64, 0.4);
    box-shadow: 0px 50px 100px -20px rgba(0, 0, 0, 0.06);
    position: relative;
}

.circle-blue {
    width: 380px;
    height: 380px;
    border-radius: 50%;
    background: rgba(15, 89, 199, 0.25);
    box-shadow: 0px 50px 100px -20px rgba(0, 0, 0, 0.06);
    margin-left: auto;
    margin-bottom: 180px;
    margin-top: -110px;
    position: relative;
}

.circle-pink {
    width: 267px;
    height: 267px;
    border-radius: 50%;
    background: #F9DAD7;
    box-shadow: 0px 50px 100px -20px rgba(0, 0, 0, 0.06);
    margin-left: 33%;
    transform: translateX(-50%);
}

.mitra.req .arrowright{
    position: absolute;
    content: '';
    bottom: -120px;
    right: -160px;
}

.mitra.req .arrowleft{
    position: absolute;
    content: '';
    bottom: -120px;
    left: -140px;
}