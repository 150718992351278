.lesson-pdf-main-div {
  text-align: center;
}
.react-pdf__Document {
  background-color: #737373;
  padding: 16px 0;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.react-pdf__Page__canvas {
  margin: auto;
}

.lesson-pdf-number {
  font-weight: 600;
  font-size: 16px;
  line-height: 31px;
  color: #000000;
  float: right;
}

.lesson-pdf-btn {
  background: #dfdfdf !important;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: none !important;
  color: #9e9e9e;
}

.lesson-pdf-btn.next {
  background: #0f59c7 !important;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #f8f6f6;
}

.lesson-pdf-btn:hover {
  opacity: 0.7;
}

.lesson-pdf-control__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
