@media (min-width: 768px) {
  .pro-sidebar.satukelas_sidebar__container {
    width: 270px;
    min-width: 270px;
  }

  section .pro-sidebar.satukelas_sidebar__container {
    border-style: none;
  }
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.active
  > .pro-inner-item
  > .pro-icon-wrapper
  .pro-icon
  svg {
  fill: #000 !important;
}
.satukelas_sidebar__container div.pro-sidebar-inner {
  background-color: #008bff;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.active
  > .pro-inner-item
  > .pro-item-content
  svg {
  fill: #000 !important;
  margin-right: 8px;
}
.pro-inner-list-item div ul b:nth-child(1) {
  top: -27px;
  position: absolute;
  height: 19px;
  background-color: #fff;
  width: 100%;
  display: none;
}
.pro-inner-list-item div ul b:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  background-color: #008bff;
}
.pro-inner-list-item div ul b:nth-child(2) {
  bottom: -27px;
  position: absolute;
  height: 19px;
  background-color: #fff;
  width: 100%;
  display: none;
}
.pro-inner-list-item div ul b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  background-color: #008bff;
}
.pro-inner-list-item div ul li.pro-menu-item.active b:nth-child(1),
.pro-inner-list-item div ul li.pro-menu-item.active b:nth-child(2) {
  display: block;
}
.satukelas_sidebar__container.pro-sidebar
  .pro-menu
  > ul
  > .pro-sub-menu
  > div.pro-inner-list-item {
  background-color: #008bff;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.active
  > .pro-inner-item
  > .pro-item-content
  svg {
  fill: #000 !important;
  margin-right: 8px;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.active
  > .pro-inner-item
  > .pro-item-content {
  color: #000;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #000;
  background: #fff !important;
  padding: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.pro-sidebar .pro-menu .pro-menu-item.non-active {
  color: #000;
}
.greeting_msg {
  font-size: 16px;
  padding: 32px 0 24px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0 16px;
}
.satukelas_sidebar__container.pro-sidebar > div.pro-sidebar-inner {
  font-weight: normal;
}

.satukelas_sidebar__container .pro-sidebar-inner {
  color: rgba(255, 255, 255, 0.75);
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #fff !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper
  .pro-icon
  svg {
  fill: rgba(255, 255, 255, 0.75) !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item:hover
  > .pro-icon-wrapper
  .pro-icon
  svg {
  fill: #fff !important;
}

.satukelas_sidebar__container
  .pro-sidebar
  .pro-menu
  > ul
  > .pro-sub-menu
  > div.pro-inner-list-item {
  background-color: #f8f8f8;
}
.pro-menu-item {
  font-size: 16px !important;
  margin-bottom: 8px;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  span.pro-arrow {
  border-color: rgba(255, 255, 255, 0.75);
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item:hover
  > .pro-arrow-wrapper
  span.pro-arrow {
  border-color: #fff;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout div.pro-sidebar-header {
  border: none;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  overflow-y: overlay !important;
  /* overflow-x: hidden; */
}

.pro-sidebar > .pro-sidebar-inner ::-webkit-scrollbar-thumb {
  display: none !important;
}

.satukelas_sidebar__container.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: "";
  display: none;
}
.pro-item-content {
  font-weight: 700;
  letter-spacing: 0.025em;
  color: #fff;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-item-content
  svg {
  fill: rgba(255, 255, 255, 0.75) !important;
  margin-right: 8px;
}

@media (max-width: 992px) {
  .satukelas_sidebar__container.pro-sidebar.lg {
    position: fixed;
  }
}
