.right {
  text-align: right;
}

.pagination {
  display: inline-block;
}

.pagination div:first-child,
.pagination div:last-child {
  color: #bdbdbd;
  float: left;
  line-height: 24px;
  padding: 8px 12px;
  text-decoration: none;
  transition: background-color 0.4s;
  border-radius: 8px;
}

.pagination div {
  color: #666666;
  float: left;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 12px;
  text-decoration: none;
  transition: background-color 0.4s;
  /* border: 1px solid #ddd; */
  /* margin: 0 4px; */
  border-radius: 8px;
  margin: 0 4px;
}

.pagination div.active {
  background-color: #0f59c7;
  color: white;
  border: none !important;
  border-radius: 8px;
  font-weight: 600;
  transform: scale(1.025);
  margin: 0 4px;
}

.pagination div:hover {
  cursor: pointer;
  background-color: #0f59c7;
  color: white;
  /* border: 1px solid #0f59c7; */
  transform: scale(1.025);
  border-radius: 8px;
  margin: 0 4px;
}

.dropbtn {
  background-color: #3498db00 !important;
  color: #000 !important;
  padding: 0px;
  border: none;
  cursor: pointer;
  padding: 7px 10px;
  margin: 0;
}

.dropbtn:hover {
  color: white !important;
  background-color: #3498db00 !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding: 0px 0px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content:hover {
  background-color: #f1f1f1 !important;
  border: 1px solid #f1f1f1 !important;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  /* background-color: #ddd; */
}

.show {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
}
