.table thead th {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  vertical-align: middle;
}
td {
  font-size: 12px;
}
.btn-close__pembahasan {
  cursor: pointer;
  background-color: #fff;
  padding: 4px 8px;
  color: #000;
  display: flex;
  position: absolute;
  z-index: 9999;
  top: 6px;
  right: 14px;
  border-radius: 32px;
  font-size: 14px;
}
.btn-close__pembahasan > span {
  font-size: 20px;
  line-height: 24px;
  margin-right: 4px;
}
#quiz-result.course_card__wrapper {
  width: 100% !important;
}
.redirected_mou {
  color: white !important;
  font-weight: 700;
  text-decoration: none !important;
}
.redirected_mou:hover {
  color: white !important;
  opacity: 0.5;
  font-weight: 700;
  text-decoration: none !important;
}

.modal-transparent .modal-content {
  background: transparent;
  border: transparent;
  height: 100%;
}
.modal-transparent .iframe-transparent {
  width: 100%;
  height: 80vh;
}
.modal-transparent .modal-body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-transparent .modal-dialog {
  height: 100%;
}
@media (max-width: 992px) {
  .modal-transparent .iframe-transparent {
    width: 100%;
    height: calc(100vw / 2);
  }
}
