@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?ww0alx");
  src: url("fonts/icomoon.eot?ww0alx#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?ww0alx") format("truetype"),
    url("fonts/icomoon.woff?ww0alx") format("woff"),
    url("fonts/icomoon.svg?ww0alx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-question_outline:before {
  content: "\e92e";
}
.icon-image:before {
  content: "\e92d";
}
.icon-T:before {
  content: "\e92b";
}
.icon-stop:before {
  content: "\e923";
}
.icon-circle:before {
  content: "\e920";
}
.icon-grid1:before {
  content: "\e921";
}
.icon-brush:before {
  content: "\e91f";
}
.icon-minus1:before {
  content: "\e922";
}
.icon-redo:before {
  content: "\e924";
}
.icon-save:before {
  content: "\e925";
}
.icon-search:before {
  content: "\e926";
}
.icon-search__large:before {
  content: "\e926";
  font-size: 32px;
}
.icon-search__white:before {
  content: "\e926";
  font-size: 24px;
  color: #0f59c7 !important;
}
.icon-undo:before {
  content: "\e927";
}
.icon-Vector:before {
  content: "\e928";
}
.icon-Vector1:before {
  content: "\e929";
}
.icon-Vector2:before {
  content: "\e92a";
}
.icon-zoom:before {
  content: "\e92c";
}
.icon-eye:before {
  content: "\e91d";
}
.icon-eye-coret:before {
  content: "\e91e";
}
.icon-file:before {
  content: "\e91c";
}
.icon-build:before {
  content: "\e919";
  color: black;
}
.icon-logout:before {
  content: "\e91a";
}
.icon-cog:before {
  content: "\e91b";
  color: black;
}
.icon-star:before {
  content: "\e918";
}
.icon-box:before {
  content: "\e915";
}
.icon-home:before {
  content: "\e916";
}
.icon-user:before {
  content: "\e917";
}
.icon-book:before {
  content: "\e913";
}
.icon-book.red-bookmark {
  color: red;
}
.icon-tag:before {
  content: "\e914";
}
.icon-calendar:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e912";
}
.icon-minus:before {
  content: "\e910";
}
.icon-bars:before {
  content: "\e900";
}
.icon-bookmark:before {
  content: "\e901";
}
.icon-chevrondown:before {
  content: "\e902";
  color: #383838 !important;
}
.icon-chevronup:before {
  content: "\e903";
  color: #383838 !important;
}
.icon-clock:before {
  content: "\e904";
}
.icon-clock.white-icon {
  color: #fff;
}
.icon-facebook:before {
  content: "\e905";
}
.icon-grid:before {
  content: "\e906";
}
.icon-info:before {
  content: "\e907";
}
.icon-instagram:before {
  content: "\e908";
}
.icon-linkedid:before {
  content: "\e909";
}
.icon-list:before {
  content: "\e90a";
}
.icon-notification:before {
  content: "\e90b";
  font-size: 32px;
}
#size.icon-notification:before {
  content: "\e90b";
  font-size: 22px;
}
.icon-plus:before {
  content: "\e90c";
}
.icon-share:before {
  content: "\e90d";
}
.icon-trash:before {
  content: "\e90e";
}
.icon-trash-smaller:before {
  content: "\e90e";
  font-size: 12px;
}
.icon-twitter:before {
  content: "\e90f";
}
.icon-cross:before {
  content: "\ea0f";
}
