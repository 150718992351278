.height-full {
  height: 100vh;
}
.height_video {
  height: 70vh;
}
.height-inherit {
  height: inherit;
}
.title_section__paragraph {
  font-weight: 700;
}
.register_button__container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.order-layout__skip {
  order: 2;
}
.order-layout__next {
  order: 1;
}
@media (min-width: 576px) {
  .register_button__container {
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  .order-layout__skip {
    order: 1;
  }
  .height_video {
    height: 70vh;
  }
  .order-layout__next {
    order: 2;
  }
}
ol.list#blog > li {
  list-style: disc !important;
}
.button_small__drop > button {
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
}
.subtitle_regist__container {
  font-size: 17px;
  margin-bottom: 32px;
  color: #595959ff;
}
.password_input {
  position: relative;
}
.position_eye {
  position: absolute;
  top: 18px;
  right: 16px;
}
.position_eye:hover {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 16px;
}
.course_digital__marketing {
  background: linear-gradient(98.41deg, #0059ff 0.47%, #0095ff 100%);
  border-radius: 24px;
  padding: 24px 32px;
  box-shadow: 0px 18px 40px -12px rgb(196 196 196 / 25%);
}
.course_digital__marketing h2,
.course_digital__marketing h4 {
  color: #fff !important;
}
.course_digital__marketing_list {
  font-weight: 700;
}
.note_user {
  font-size: 12px;
  margin-left: 16px;
  font-weight: 600;
  margin-left: 20px;
}
.frame-regist__bsnp {
  width: 100%;
  height: 100vh;
  border: none;
  overflow-x: hidden;
}
.alert_container {
  border-radius: 8px !important;
  font-size: 12px;
  padding: 0;
  color: rgba(0, 0, 0, 0.5) !important;
}
.label-regist {
  font-weight: 600;
}
.height_scrolling {
  height: 100vh;
  overflow: auto;
  padding: 32px 16px;
}
.padding_regist {
  padding: 32px 16px;
}
.error-self {
  color: red;
}
.email-text {
  font-size: 12px;
}

.register-select {
  font-size: 16px;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 50px !important;
  margin-bottom: 16px !important;
  padding: 0 30px 0 54px !important;
  background: #f8f8f8 !important;
  box-shadow: 0px 18px 40px -12px rgb(196 196 196 / 25%);
  border-radius: 12px !important;
}
.card-padding__register {
  padding: 0;
}
.height-register {
  height: 100vh;
  /* max-height: 100vh; */
  overflow-y: auto;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
}
@media (min-width: 576px) {
  .card-padding__register {
    padding: 0 32px;
  }
  .height-register {
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
  }
}
@media (min-width: 768px) {
  .height-register {
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
  }
  .card-padding__register {
    padding: 0 16px;
  }
  .wrapper-register {
    padding: 0;
  }
  .register-inherit {
    padding: 0 !important;
  }
}
.card-padding__login {
  padding: 40px;
}
#catalogue_course .dropdown-menu.show {
  padding: 0;
  display: block;
}
.catalogue-course > .dropdown-menu.show {
  padding: 16px;
  display: block;
  width: 300px;
  transform: translate(0px, 85px) !important;
}
@media (max-width: 576px) {
  .dropdown-menu.show {
    padding: 16px;
    display: block;
    width: unset;
  }
}
ul#success {
  padding-left: 16px !important;
}
ul#success > li {
  list-style: auto !important;
}
.form-name {
  height: 50px !important;
  /* margin-bottom: 16px !important; */
  /* padding: 0 30px 0 54px !important; */
  background: #fff !important;
  border-radius: 8px !important;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
}
.form-control.auth {
  color: #495057;
}
#dropdown-custom {
  background: transparent;
  border: none;
  color: #007bff;
  margin: 0;
  padding: 0;
}
#last-type__list {
  color: #000 !important;
}
.dropdown-toggle {
  white-space: nowrap;
  /* margin-right: 24px !important; */
}
.register-step__first-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 32px;
  color: #000000;
}
.register-alert__msg {
  font-size: 12px;
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
}
.red_star {
  color: red;
}
.register-step__first-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  color: #000000;
}

.position_text__button {
  display: flex;
  align-items: center;
}
.text_account__have {
  margin-bottom: 24px;
}
.bottom-button__register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  flex-direction: column;
}
.register-next__button {
  height: 48px;
  border-radius: 8px !important;
  font-weight: 600;
  margin-right: 0 !important;
  font-size: 14px;
  line-height: 21px;
  padding: 0 24px;
  color: #ffffff;
  width: 100%;
  justify-content: center;
}
.register-next__button:active,
.register-next__button:focus {
  color: #0059ff;
}

@media (min-width: 576px) {
  .bottom-button__register {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    flex-direction: row;
  }
  .text_account__have {
    margin-bottom: 12px;
  }
  .register-next__button {
    height: 48px;
    border-radius: 8px !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    padding: 0 24px;
    color: #ffffff;
    width: auto;
  }
}
.link_login {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #005cff;
}
.login-register-text {
  display: flex;
  align-items: center;
  margin-top: 56px;
  font-size: 16px;
  line-height: 24px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .login-register-text {
    display: flex;
    align-items: center;
    margin-top: 56px;
    font-size: 16px;
    line-height: 24px;
    flex-direction: row;
  }
}
.register-prev__button {
  color: black !important;
  font-weight: 600;
  background-color: transparent !important;
  border: none !important;
  height: 48px;
}

.register-prev__button {
  color: black !important;
  font-weight: 600;
  background-color: transparent !important;
  border: none !important;
  height: 48px;
  padding-right: 0 !important;
}
@media (min-width: 576px) {
  .register-prev__button:hover {
    color: black !important;
    font-weight: 600;
    opacity: 0.5 !important;
  }
}
.btn-outline-primary {
  color: #2d8cff;
}
.register-logo {
  margin-bottom: 40px;
}
.register-inherit {
  padding: 12px 0;
  height: inherit;
  /* overflow: hidden; */
}
@media (min-width: 576px) {
  .register-inherit {
    height: inherit;
    /* overflow: hidden; */
    padding-left: 15px;
    padding-right: 15px;
  }
}
.arrow-register {
  margin-left: 16px;
}
.login-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 24px;
}
.login-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
.login-sublabel {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}
.card {
  border-radius: 24px;
}
.bg-white {
  background-color: #ffffff;
}
.search-course__title {
  font-weight: 500;
}
.search-box__inside {
  font-weight: 600;
}
.subtitle_explorer {
  font-size: 10px;
}
.login-register-text__span {
  text-decoration: underline !important;
  font-weight: 700 !important;
  color: #0890fd !important;
  text-transform: capitalize;
}
.p-6 {
  padding: 2rem;
}
.input100 {
  font-size: 16px;
  line-height: 1.5;
  display: block;
  width: 100%;
  margin-bottom: 16px !important;
  background: #fff !important;
  /* box-shadow: 0px 18px 40px -12px rgb(196 196 196 / 25%); */
  border-radius: 8px !important;
}
.card-padding {
  padding: 40px;
}
.btn-primary.login-button {
  background: var(--blue-gradient);
  box-shadow: 0px 18px 40px -12px rgba(21, 67, 185, 0.25);
  border-radius: 12px;
  height: 50px;
  margin-bottom: 24px;
  transition: var(--transition);
}
.login-button:hover {
  opacity: 0.7;
}
.login-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.login-forgot-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000 !important;
}
svg {
  width: 18px;
  height: 18px;
}
body *::-webkit-scrollbar-thumb {
  background: #ced4da;
  border-radius: 8px;
}
body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s background;
}

.blog_container {
  margin: 80px 0;
  justify-content: center;
}
.blog_subtitle__container {
}
.blog_subtitle {
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 24px !important;
  opacity: 0.8;
  width: fit-content;
  padding: 8px 12px;
  display: flex;
  background-color: #007bff;
  margin: 0 auto;
  color: #fff;
  border-radius: 8px;
}
.blog_title__container {
  text-align: center;
}
.blog_title,
.more_article__title {
  font-weight: 700;
}
.more_article__title {
  font-size: 28px;
  margin-bottom: 24px;
}
.blog_info__container {
  display: flex;
  justify-content: space-evenly;
  margin: 16px 0 40px;
}
.blog_info__container div,
.src_img {
  font-family: "Nunito";
}
.src_img {
  font-weight: 700;
}
.blog_article__container {
  font-family: "Nunito";
  line-height: 36px;
  text-align: justify;
}
.article_wrapper {
  margin-bottom: 24px;
}
.article_container {
  box-shadow: 0px 18px 40px -12px rgb(196 196 196 / 25%);
  padding: 16px;
}
.link_blog {
  color: #000;
}
.link_blog:hover {
  text-decoration: none !important;
  color: #000 !important;
  opacity: 0.5;
}
.blog_container__title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
}
.blog_container__subtitle {
  font-size: 16px;
  font-family: "Nunito";
  margin-bottom: 24px;
}
.blog_container__more_article {
  display: flex;
  padding: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.blog_image__size {
  width: 110px;
  margin-right: 16px;
}
.blog_container__title_more {
  font-size: 16px;
  font-weight: 600;
}
.more_article__list {
  display: flex;
  color: #000;
}
.more_article__list:hover {
  display: flex;
  text-decoration: none;
  opacity: 0.7;
  color: #000;
}
.blog_title__more {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 16px;
}
.blog_container__headline {
  color: #000;
  text-decoration: none !important;
}
.blog_container__headline:hover {
  color: #000;
  opacity: 0.5;
  text-decoration: none !important;
}
.blog_container__headline:hover > .blog_container__title {
  text-decoration: none !important;
}
