
.thumbnail_container,
.detail_wrapper__certification {
  padding: 24px 0;
}
.thumbnail_image {
  object-fit: cover;
  width: 250px;
  height: 250px;
  border-radius: 125px;
  background-color: lightgray;
}
.detail_container {
  padding: 24px 0;
}
.detail_text.type,
.detail_text.level,
.detail_text.detail {
  font-weight: 700;
  margin-bottom: 24px;
  letter-spacing: 0.5px;
}

.detail_text span.type_detail,
.detail_text span.level_detail,
.detail_text span.detail_detail {
  font-weight: 400;
  margin-bottom: 24px;
  letter-spacing: 0.25px;
}

.title_section {
  font-weight: 700;
}
.subtitle_section {
  margin: 16px 0 20px;
  font-weight: 500;
}
.text_section {
  margin-bottom: 48px;
  letter-spacing: 0.35px;
  line-height: 28px;
}
.skill_title__text,
.criteria_title__text {
  font-weight: 600;
  margin-bottom: 16px;
}

.skill_list__container {
  display: flex;
  margin-bottom: 24px;
}

.skill_list__wrapper {
  border: 3px solid rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
  border-radius: 4px;
  width: max-content;
  margin-bottom: 16px;
  margin-right: 16px;
}

.skill_list__wrapper:hover {
  border: 3px solid rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  border-radius: 4px;
  width: max-content;
  margin-bottom: 16px;
  margin-right: 16px;
  cursor: pointer;
}

.skill_list__title {
  font-weight: 600;
  font-size: 12px;
}
.criteria_list__container > ul {
  padding-left: 16px;
}
.criteria_list__container li {
  list-style: disc !important;
  letter-spacing: 0.5px;
  line-height: 28px;
}

.certification_preview__image > img {
  width: 100%;
}
