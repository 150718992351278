.certification_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.certification_section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-bottom: 24px;
}
.fit-content {
  width: 265px;
}
@media (min-width: 576px) {
  .certification_section {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
}
.nav-tabs {
  border-bottom: unset;
}
#remove_border .nav-tabs > .nav-link.active {
  background-color: #0f59c7 !important;
  font-weight: 700;
  border: none;
  color: #fff;
  border-radius: 8px;
  font-size: 14px;
  padding: 16px !important;
  text-align: center;
  border-bottom: 4px solid #1d52bc;
  letter-spacing: 1px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #fff !important;
  font-weight: 700;
  border: none;
  color: #1d52bc;
  border-radius: 12px 12px 0 0;
  font-size: 14px;
  padding: 8px;
  text-align: center;
  border-bottom: 4px solid #1d52bc !important;
  letter-spacing: 1px;
}

.nav-tabs .nav-link {
  padding: 8px !important;
  font-size: 16px !important;
  letter-spacing: 1px;
  color: black;
  display: flex;
  align-items: center;
}
.nav-tabs {
  margin-bottom: 42px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.nav-tabs {
  display: flex;
  width: 100%;
  flex-flow: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.nav-tabs a {
  white-space: nowrap;
}
@media (min-width: 576px) {
  .nav-tabs {
    width: unset;
  }
}
.card_height {
  height: auto !important;
}
svg {
  cursor: pointer;
  fill: rgba(0, 0, 0, 0.5);
}
.certification__subtitle {
  font-size: 14px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.25);
}
.tab-style {
  background-color: white;
  border-radius: 0 24px 24px 24px;
}
.download-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.sticky-cart {
  display: block;
  position: fixed;
  height: 30vh;
  width: 30%;
  bottom: 4px;
  right: 4px;
  background-color: #ddd;
  padding: 16px;
  font-weight: bold;
  z-index: 1010;
  border-radius: 16px;
}
.sticky-cart-max {
  display: block;
  position: fixed;
  height: 90%;
  width: 90%;
  bottom: 5%;
  left: 5%;
  background-color: #ddd;
  padding: 16px;
  font-weight: bold;
  z-index: 1010;
  border-radius: 16px;
}
.minimize__wrapper__cart_ {
  margin-top: 4px;
  overflow: auto;
  height: 20vh;
}
.maximize__wrapper__cart_ {
  overflow: auto;
  height: 75vh;
}
.header__border_bottom {
  border-style: solid;
  border-width: 0 0 0.5px 0;
  padding: 8px;
  border-color: #111;
}
.cart-minimize__title {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.cart-minimize__wrapper {
  border-style: solid;
  border-width: 0 0 0.5px 0;
  padding: 4px;
  margin: 4px;
}
.cart-minimize__wrapper:hover {
  cursor: pointer;
  background-color: #0391fd;
}
.cart-minimize__wrapper_active__ {
  background-color: #0079d6;
  color: #fff;
}
.balance__cart_minimize {
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  margin: 0;
}
.balance__cart_maximize {
  font-size: 1.1rem;
  font-weight: bold;
}
@media (max-width: 768px) {
  .fit-content {
    width: -webkit-fill-available;
  }
  .sticky-cart {
    width: 70%;
    bottom: 7.5vh;
  }
  .sticky-cart-max {
    overflow: auto;
  }
}

.icon-list:hover,
.icon-grid:hover {
  cursor: pointer;
}
