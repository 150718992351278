.padding-card {
  padding: 24px;
}
@media (min-width: 576px) {
  .padding-card {
    padding: 24px 32px;
  }
}
.tutor_description {
  font-size: 16px;
  margin-bottom: 16px;
}
.instructor-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #383838;
}
.instructor-container {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}
.instructor-card {
  background: #ffffff;
  padding: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: sticky;
  top: 42px;
}
.instructor-division {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #636262;
}
.instructor-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #383838;
  margin-bottom: 24px;
  font-family: "Work Sans", sans-serif;
}
