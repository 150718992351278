.img_certification {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.wrapp_content {
  top: 50%;
  left: 50%;
  right: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.content_two {
  /* margin-left: -8px; */
  gap: 15%;
}

.child_two {
  gap: 10px;
}

.highlight_text {
  color: #306ccc;
}

section,
p h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}

.btn__1 {
  background-color: #306ccc;
  color: #fff;
  font-weight: 700;
  padding: 26px 13px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #306ccc;
  transition: all ease 500ms;
}

.btn__1:hover {
  background-color: #fff !important;
  color: #306ccc;
}

.btn__2:hover:disabled {
  color: #6c757d;
}

.btn__2:hover {
  color: #306ccc;
  opacity: 0.75;
}

.btn__2 {
  color: #306ccc;
  /* border: 2px solid #306ccc; */
  border-radius: 10px;
  transition: all ease 500ms;
}

.lesson-container {
  max-width: 100% !important;
}

._magang-page .text-white {
  color: #3f3e3d !important;
}

._magang-page .initial-name {
  width: 50px;
  font-size: 28px;
  height: 50px;
  border-radius: 32px;
  background-color: lightgray;
  display: flex;
  justify-content: center;
}
._magang-page .logo-satukelas__course {
  background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/satukelas-logo-1.webp");
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  object-fit: cover;
}

._magang-page .bg__tryout {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

._magang-page .bg__contact .text-white,
._magang-page .bg__tryout .text-white {
  color: #fff !important;
}

@media (min-width: 1200px) {
  .wrap__content {
    position: absolute;
    top: 10px;
    right: 35px;
    background-color: #fff;
    z-index: 9;
    border-radius: 15px;
  }
}

.img__icon-wrap {
  object-fit: contain;
}

.img__client {
  object-fit: contain;
  width: 100%;
  height: 80px;
}

.splide__list {
  align-items: center;
}

.splide__pagination {
  bottom: -2em !important;
}

.splide__arrow {
  background: #e9f2ff !important;
}

.splide__arrow svg {
  fill: #0f59c7 !important;
}

@media (max-width: 768px) {
  .splide__arrow--prev {
    left: -2em;
  }

  .splide__arrow--next {
    right: -2.5em;
  }
}

.icon-chevrondown:before {
  color: rgba(48, 108, 204, 1);
}

table tbody tr td {
  font-size: 15px;
}

.bg__tryout {
  background: linear-gradient(
    90deg,
    #306cce 49.35%,
    rgba(48, 108, 206, 0) 135.53%
  );
}

.bg__ujian {
  background: linear-gradient(
    90deg,
    #55aa73 39.53%,
    rgba(48, 206, 178, 0) 135.53%
  );
}

#testimoni .splide__arrow--next,
#testimoni .splide__arrow--prev {
  display: block;
}

@media (min-width: 768px) {
  #testimoni .splide__arrow--prev {
    left: -3em !important;
  }

  #testimoni .splide__arrow--next {
    right: -3em !important;
  }
}

#testimoni .splide__arrow {
  background: rgb(118, 163, 224) !important;
  width: 2em;
  height: 2em;
  padding: 5px;
  border-radius: 50%;
}

#testimoni .splide__arrow svg {
  fill: #015feb !important;
}

#testimoni .splide__list {
  align-items: unset;
}

#testimoni .splide__slide {
  padding: 10px;
}

#other .splide__arrow svg {
  fill: #015feb !important;
}

#other .splide__list {
  align-items: unset;
}

#other .splide__slide {
  padding: 4px;
}

.wrap__testi {
  border-radius: 15px;
}

@media (max-width: 767px) {
  .bg__contact {
    display: flex;
    flex-direction: column;
  }
}

.bg__contact {
  background: linear-gradient(
    90deg,
    #55aa73 49.35%,
    rgba(85, 170, 115, 0.38) 135.53%
  );
  display: flex;
  padding: 20px 20px 0 20px;
  align-items: center;
  border-radius: 5px;
}

.btn__contact {
  color: rgba(85, 170, 115, 1);
}

.heading__acordion {
  background: linear-gradient(0deg, #f7f9fa, #f7f9fa);
  border: 1px solid rgba(223, 223, 223, 1);
}

.heading__acordion button {
  background: linear-gradient(0deg, #f7f9fa, #f7f9fa);
}

.desc__prereq {
  color: #154a9e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.wrap__progress {
  display: flex;
  width: 100%;
  height: 1px;
  padding-bottom: 0px;
  align-items: center;
  background-color: #c2c2c2;
}

.progress__bar-materi {
  height: 6px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #154a9e;
}

.paremt__btn-detail:hover {
  text-decoration: none;
  color: #fff;
}

.parent__btn-pembahasan:hover {
  text-decoration: none;
}

.btn__start {
  display: flex;
  padding: 4px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #55aa73;
  color: #fff !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn__detail:hover {
  color: #fff;
  background-color: #306ccc;
}

.btn__detail {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #306ccc;
  color: #306ccc;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: unset;
  transition: all ease-in-out 0.2s;
}

.btn__continue {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: #306ccc;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.parent__check {
  display: block;
  position: relative;
  width: 40px;
  /* height: 40px; */
  padding-left: 26px;
  /* margin-bottom: 18px; */
  font-size: 32px;
  user-select: none;
}

button.checkmark__done:disabled {
  /* cursor: not-allowed; */
}

.checkmark__done {
  border-radius: 4px;
  /* cursor: pointer; */
  position: absolute;
  top: 4px;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: #dedbdb;
}

.parent__check .my__checkbox:checked ~ .checkmark__done {
  background-color: #c5c5c5;
}

.parent__check .check__done:checked ~ .checkmark__done {
  background-color: #00ab70;
}

.parent__check .checkmark__done:after {
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 10px;
  color: white;
}

.parent__check input:checked ~ .checkmark__done:after {
  display: block;
}

.checkmark__done:after {
  content: "✓";
  font-size: 20px;
  position: absolute;
  display: none;
}

.bg__subscribe {
  background: rgba(197, 219, 255, 1);
  color: rgba(48, 108, 204, 1);
}

.list__item {
  list-style: disc !important;
}

@media (min-width: 1200px) {
  .sticky-parent__benefit {
    position: fixed;
    top: -50px;
    width: 100%;
    z-index: 99;
    -webkit-animation: fadein 1.5s;
    -moz-animation: fadein 1.5s;
    -ms-animation: fadein 1.5s;
    -o-animation: fadein 1.5s;
    animation: fadein 1.5s;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .sticky-benefit__img {
    display: none;
  }
}

.app {
  height: unset;
}

.title__certicate-atpi {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer__benefit-chro {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desc__benefit-sticky {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.purpose__content p {
  color: #3f3e3d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  width: 300px;
  display: contents;
}

.subscribe__chro-benefit {
  width: 352px;
  flex-shrink: 0;
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

@media (min-width: 1200px) {
  .benefit__header-chro-nosub {
    width: 352.244px;
  }
}

.benefit__header-chro-nosub {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn__flow-test {
  border-radius: 8px;
  border: 1px solid #306ccc;
  display: flex;
  width: 352px;
  height: 52px;
  padding: 11px 62px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #306ccc;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.btn__flow-test:hover {
  background: #306ccc;
  color: #fff;
}

.btn__continue-test {
  display: flex;
  width: 353px;
  height: 54px;
  padding: 12px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #306ccc;
  color: var(--White, #f2f2f2);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: var(--Main-Blue, #306ccc);
}

.btn__continue-test:hover {
  color: #306ccc;
  background: #fff;
}

.bt__mulai-ujian {
  color: #306ccc;
  border: 2px solid #306ccc;
  border-radius: 8px;
}

.bt__mulai-ujian:hover {
  background: #306ccc;
}

.percentage__progress-acordion {
  display: flex;
  width: 60px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #154a9e;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

._toast {
  position: absolute;
  z-index: 9;
  right: 10px;
  top: 10px;
  width: 250px !important;
}

.toast-header {
  display: flex !important;
}

.toast-header > button {
  margin: 0 !important;
}

.toast__checked {
  position: absolute;
  top: 4px;
  z-index: 99;
  right: 60px;
  z-index: 99;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background-color: #ffff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.toast__checked::after {
  content: "";
  position: absolute;
  transform: rotate(134deg);
  background-color: #fff;
  padding: 8px;
  z-index: 1;
  top: 10px;
  right: -6px;
  box-shadow: -1px -1px 3px -1px rgba(0, 0, 0, 0.25);
}

.toast__checked .toast {
  opacity: 1;
  max-width: 100%;
  width: 238px;
  border-radius: 4px;
}

.toast__checked .toast .toast-header {
  border-radius: 4px;
  border-bottom: unset;
}

.desc__toast {
  color: var(--Text-Color, #3f3e3d);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn__close-toast {
  color: var(--Main-Blue, #306ccc);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  padding: 7px 43px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0 0 4px 0;
}

.btn__skip-enrol:focus {
  color: #306ccc;
}

.btn__skip-enrol {
  display: flex;
  width: 216px;
  padding: 5px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 4px !important;
  background: var(--Main-Blue, #306ccc);
  color: var(--White, #f2f2f2);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.group__hide-toast {
  gap: 10px;
}

.group__hide-toast label {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
