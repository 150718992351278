.wrapper-padding {
  padding: 32px;
}
.wrapper-image-text {
  display: flex;
  justify-content: space-between;
}
.wrapper-info {
  font-size: 16px;
}
.wrapper-subtitle {
  font-size: 16px;
  font-weight: 500;
}
.detail_notification {
  font-size: 14px;
  font-weight: 600;
}
.notification_content__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.notification_content {
  max-width: 100%;
}
@media (min-width: 576px) {
  .notification_content__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .notification_content {
    max-width: 80%;
  }
}
.notification_card {
  background-color: #f4f4f4;
}
.wrapper-subtitle__2 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
.img-thumb {
  font-size: 21px;
  width: 60px;
  height: 60px;
  background-color: gray;
}
.deadline-subtitle {
  font-size: 12px;
}
