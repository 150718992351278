
.style1-input {
  padding-left: 16px;
  height: 45px !important;
  line-height: 45px !important;
  border-radius: 6px !important;
  display: block;
  padding-right: 16px;
  border:none !important;
}
.dashboard__section_subtitle {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.chart_display {
  display: block !important;
}
.progress_course {
  background-color: #5470c7;
  width: 30px;
  height: 15px;
  border-radius: 4px;
}
.finish_course {
  background-color: #aed999;
  width: 30px;
  height: 15px;
  border-radius: 4px;
}
