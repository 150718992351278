
.course-slider {
  height: 410px;
  background-color: #000;
}
.illustration {
  position: relative;
}
.button_register {
  width: fit-content;
}
.full-height {
  height: 100vh;
}
.hero-shot__title {
  font-size: 24px;
  letter-spacing: 0.75px;
  line-height: 40px;
}
.hero-shot__subtitle {
  font-size: 16px;
  line-height: 28px;
}
.membership_title__container {
  font-size: 32px;
  font-weight: 700;
  width: max-content;
}
.column-form {
  padding: 0;
  margin-bottom: 48px;
}
@media (min-width: 576px) {
  .hero-shot__title {
    font-size: 32px;
    letter-spacing: 0.75px;
    line-height: 48px;
  }
  .hero-shot__subtitle {
    font-size: 16px;
    line-height: 32px;
  }
  .column-form {
    padding: 24px 15px;
  }
}
.icon-file.nav-sticky {
  font-size: 20px;
}
.icon-logout.sticky {
  color: red;
}
.btn-primary:hover .size.icon-file:before {
  font-size: 32px;
  color: #fff;
}
.illustration_text {
  font-family: "Nunito";
  font-weight: 800;
  color: #142b72;
}
.subtitle_membership {
  font-size: 16px;
  font-weight: 600;
  color: gray;
  margin-top: 16px;
  font-family: "Nunito";
}
.illustration_subtext {
  font-size: 16px;
  font-family: "Nunito";
  line-height: 32px;
}

.img_width__illustration {
  width: 330px;
  right: 16px;
  top: -25px;
  position: absolute;
}
.background_bnsp {
  background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/background_bnsp-01.png");
  background-repeat: no-repeat;
  background-size: inherit;
}
.background_bnsp__open {
  background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/background_bnsp-01.png");
  background-repeat: no-repeat;
  background-size: inherit;
}
.card_register__bnsp.illustration {
  min-height: 350px;
}
.text_illustration {
  position: absolute;
  bottom: 30px;
}
@media (min-width: 576px) {
  .img_width__illustration {
    width: 370px;
    right: -15px;
    top: -25px;
    position: absolute;
  }
  .background_bnsp {
    background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/background_bnsp-01.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .background_bnsp__open {
    background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/background_bnsp-01.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .card_register__bnsp.illustration {
    border: none !important;
  }
  .text_illustration {
    position: absolute;
    bottom: unset;
  }
  .card_register__bnsp.illustration {
    min-height: 230px;
  }
}
@media (min-width: 768px) {
  .card_register__bnsp.illustration {
    min-height: 290px;
  }
}
.contact_person__text {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}
.header_list {
  display: flex;
}
.color-inside__text {
  color: #f5d3d4;
}
.button_wrapper {
  display: flex;
  background-color: rgb(14, 40, 207);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
}
.button_wrapper:hover {
  background-color: #adefef;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
  cursor: pointer;
}
.button_wrapper:hover > .mint.icon-build:before {
  color: rgb(14, 40, 207);
}
.button_wrapper:hover > .mint.icon-user:before {
  color: rgb(14, 40, 207);
}
.subtitle_text__wrapper {
  font-family: "Nunito";
}

.text_wrapper {
  color: #f5d3d4;
  font-weight: 800;
  font-family: "Nunito";
  font-size: 16px;
}
.subtitle_text__wrapper {
  font-size: 12px;
  color: #fff;
}
.text_title {
  font-weight: 700 !important;
  color: #fff !important;
  font-size: 20px;
  font-family: "Nunito";
  margin-bottom: 24px;
}
.content_list {
  background-color: #007bff;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
}
.content_list:last-child {
  margin-bottom: 0;
}
.content_detail {
  display: flex;
}
.mitra_text {
  display: flex;
  align-items: center;
  font-family: "Nunito";
  font-weight: 700;
}
.wrapper-header__detail {
  padding: 108px 0;
  background-color: #007bff;
}
.wrapper-detail__program {
  position: relative;
  top: -32px;
  background-color: white;
  border-radius: 32px 32px 0 0;
}
.detail_program__card {
  padding: 88px 0;
}
.card_detail__program {
  background: #f4f4f4;
  padding: 32px;
  border-radius: 24px;
}
.title_detail {
  color: #fff;
}
.title_landing.detail-program {
  color: #fff;
}
.section_last__container {
  background-color: rgb(0, 105, 217);
}
.card_benefit__list {
  position: relative;
  display: flex;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
  color: #fff;
  min-height: 260px;
}
.card_benefit__text {
  font-family: "Nunito";
  line-height: 32px;
  display: flex;
  color: #000;
}
.container_radius__top {
  margin-top: 40px;
  position: relative;
  top: -38px;
  background: white;
  border-radius: 40px 40px 0 0;
}
.image_register {
  height: 87vh !important;
}
.course-slider .card-body {
  width: 60%;
  padding: 0px 0 0 70px;
  z-index: 3;
}
.title_border__bottom {
  border-bottom: 4px solid #ffaa47;
  width: fit-content;
}
.card_condition {
  padding: 32px;
  background-color: #f4f4f4d6;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}
.card_condition ul,
.detail_program__card ul {
  padding-left: 16px !important;
}
.card_condition ul > li,
.detail_program__card ul li {
  list-style: disc !important;
  font-family: "Nunito";
  line-height: 32px;
  margin-bottom: 24px;
}
.card_download__mou {
  background-color: #fff;
  border-radius: 8px;
}
.button_regist__bsnp {
  border-radius: 24px !important;
  height: 45px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Nunito";
}
.benefit_container__bnsp {
  background: rgb(0, 105, 217);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding: 64px 0;
}
.container_check {
  position: absolute;
  top: -20px;
}
.wrapper_check {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: lightgreen;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_detail__teknis {
  background-color: #f4f4f4;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 24px;
  min-height: 190px;
}
.wrapper_detail__teknik {
  background-color: #fff;
  color: #000;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-family: "Nunito";
}
.title_detail__teknik {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 700;
}
.subtitle_detail__teknik {
  font-family: "Nunito";
  line-height: 32px;
}
.button_regist__bsnp:hover {
  border-radius: 24px !important;
  height: 45px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 700;
  background-color: white !important;
  color: #000 !important;
  border: 0 !important;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
  font-family: "Nunito";
}
.condition_list {
  margin-bottom: 40px;
}
.title_landing {
  font-weight: 800;
  margin-bottom: 24px;
  font-family: "Nunito";
  color: #142b72;
}
.title_landing.benefit {
  font-weight: 800;
  margin-bottom: 24px;
  font-family: "Nunito";
  color: #fff;
}
.detail_program__title {
  font-weight: 700;
  font-family: "Nunito";
}
.information_program {
  margin-bottom: 32px;
  font-family: "Nunito";
}
.information_program,
.about_program {
  line-height: 32px;
  font-size: 16px;
  color: #142b72;
}
.read_more__text {
  font-weight: 600;
}
.about_program {
  font-family: "Nunito";
  margin-bottom: 40px;
}
.header-text__bnsp {
  color: #fff;
  margin-bottom: 24px;
}
.subheader-text__bnsp {
  opacity: 0.8;
  margin-bottom: 40px;
  font-family: "Nunito";
}
#sub {
  color: white;
}
.container_campuss__list {
  background-color: #fff;
  padding: 24px;
  border-radius: 32px;
  overflow-y: auto;
  max-height: 530px;
}
.btn_landing__page {
  min-width: 245px;
  background-color: #fc3879;
  border: none !important;
  border: 4px solid #fc3879 !important;
  color: black;
  border-radius: 43px !important;
  padding: 12px 24px;
  font-weight: 600;
  color: #fff;
  transition: all 0.1s ease;
}
.btn_landing__page:hover {
  min-width: 245px;
  background-color: #fff;
  border: 4px solid #fff !important;
  color: black;
  border-radius: 43px !important;
  padding: 12px 24px;
  font-weight: 600;
  color: #eb2f6d;
  transform: scale(1.1);
  box-shadow: 0 8px 20px #db29642c;
}
.btn_landing__page_two {
  min-width: 245px;
  background-color: unset !important;
  border: 4px solid #fff !important;
  border-radius: 43px !important;
  padding: 12px 24px;
  font-weight: 600;
  transition: all 0.1s ease;
}
.btn_landing__page_two:hover {
  min-width: 245px;
  background-color: #fc3879 !important;
  border: 4px solid #fc3879 !important;
  color: #fff;
  border-radius: 43px !important;
  padding: 12px 24px;
  font-weight: 600;
  transform: scale(1.1);
  box-shadow: 0 8px 20px #db29642c;
}
.button_detail__course {
  border: none !important;
  font-family: "Nunito";
  padding: 16px 24px;
  width: 100%;
  text-align: left;
  font-weight: 700;
  border-radius: 24px !important;
}
.description_detail__course {
  line-height: 32px;
  font-family: "Nunito";
}
.header-text__bnsp,
.section-title {
  font-weight: 700;
}
.section-two {
  padding: 86px 0 56px;
}
.span-fail {
  color: red;
  font-weight: 700;
}
.container-warning__mou {
  font-size: 12px;
  background: orange;
  padding: 18px 24px;
  border-radius: 8px;
}
.button-download_mou {
  height: 50px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.button-register_mou {
  height: 50px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overflow-show {
  max-height: 250px;
  overflow-y: auto;
}
.max-card__height {
}
.blue-star {
  color: #007bff;
}
.card_register__article {
  border-radius: 12px;
  background-color: #fff;
  padding: 16px 24px;
  box-shadow: 0px 18px 43px -2px rgb(195 195 195 / 25%);
}
.card_image__article {
  background-color: lightgray;
  height: 210px;
  border-radius: 16px 16px 0 0;
}
.article_title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 32px;
  min-height: 50px;
}
.button_detail__course > .title_accordion {
  min-height: 50px;
}
.card_register__bnsp {
  min-height: 290px;
  border-radius: 12px;
  background-color: #fff;
  padding: 16px 24px;
  position: relative;
  box-shadow: 0px 18px 43px -2px rgb(195 195 195 / 25%);
  border-left: 6px solid pink;
}

.card_register__desc,
.card_mitra__text {
  font-size: 16px;
  font-family: "Nunito";
  line-height: 32px;
}
.card_mitra__bnsp {
  min-height: 150px;
  border-radius: 12px;
  background-color: #fff;
  padding: 16px 24px;
  position: relative;
  box-shadow: 0px 18px 43px -2px rgb(195 195 195 / 25%);
  border-left: 6px solid pink;
}
.card_mitra {
  border-radius: 16px;
  box-shadow: 0px 18px 43px -2px rgb(195 195 195 / 25%);
  margin-bottom: 16px;
  transition: all 0.2s ease;
}
.card_mitra:hover {
  border-radius: 16px;
  box-shadow: 0px 18px 43px -2px rgb(195 195 195 / 25%);
  margin-bottom: 16px;
  transform: scale(1.1);
}
.card_mitra > img {
  width: inherit;
}
.card_register__step {
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background-color: #ee4253;
  position: absolute;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #fff;
}
.card_register__text {
  font-weight: 700;
  font-family: "Nunito";
  margin-bottom: 16px;
  margin-top: 16px;
}
.card_benefit {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 18px 43px -2px rgb(195 195 195 / 25%);
  padding: 24px;
}

.illustration_step {
  background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/bnsp_illustration.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.campuss_list__responsive {
  flex-direction: column;
}
.campuss_min__width {
  margin-bottom: 16px;
}
.section_one,
.section_four {
  min-height: 100vh;
  padding: 118px 8px 90px;
}
@media (min-width: 576px) {
  .campuss_list__responsive {
    flex-direction: row;
  }
  .campuss_min__width {
    min-width: 180px;
  }
  .section_one,
  .section_four {
    min-height: 100vh;
    padding: 118px 8px;
  }
}
.frame-regist__campuss {
  width: 100%;
  height: 76vh;
  border: none !important;
}
.section-4_img {
  background-image: url("./../../images/korporasi_asset.svg");
  background-repeat: no-repeat;
  height: 57vh;
  background-size: contain;
}
.about-wrapper.bg-1 {
  background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/bg-homepage.webp");
  background-repeat: no-repeat;
  height: 100vh;
  background-size: inherit;
}
@media (min-width: 576px) {
  .about-wrapper.bg-1 {
    background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/bg-homepage.webp");
    background-repeat: no-repeat;
    height: 90vh;
    background-size: cover;
  }
}
.blue-card {
  background-color: #007bff;
}
.membership-title__promo {
  color: #fff;
  font-weight: 700;
}
.membership-title__unpromo {
  font-weight: 700;
}
.span-month-white {
  font-size: 12px;
  font-weight: 500;
  color: white;
  opacity: 0.5;
}
.span-month {
  font-size: 12px;
  opacity: 0.5;
  color: #000;
}
.border-white {
  border-color: #fff !important;
}
.btn-sales-promo {
  background-color: #ffffff;
  color: #007bff;
  font-weight: 600;
  font-size: 12px;
  padding: 12px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
}
.btn-sales {
  font-weight: 600;
  font-size: 12px;
  padding: 12px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}
.course-slider .video-wrap {
  width: 80%;
  margin-left: auto;
  position: relative;
  height: 100%;
}
.course-slider .video-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 60%;
  height: 100%;
  background: linear-gradient(to right, #000, transparent);
  z-index: 2;
}
.course-slider .video-wrap video {
  position: absolute;
  width: 120%;
  top: 0;
}
.cat-card-hover {
  cursor: pointer;
}
.cat-card-hover img {
  transition: all 0.4s ease !important;
  position: relative;
}
.cat-card-hover:hover img {
  transform: scale(1.2);
}
.course-card .card-image {
  overflow: hidden;
}
.course-card .card-image img {
  transition: all 0.4s ease !important;
  position: relative;
}
.course-card:hover .card-image img {
  transform: scale(1.1);
}
@media (min-width: 991px) {
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .main-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
  }
  .middle-wrap {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .pr-md--2 {
    padding-right: 0.5rem !important;
  }
  .pl-md--2 {
    padding-left: 0.5rem !important;
  }
  .pt-lg--5 {
    padding-top: 30px !important;
  }
  .pb-lg--5 {
    padding-bottom: 30px !important;
  }
  .pt-lg--7 {
    padding-top: 75px !important;
  }
  .pb-lg--7 {
    padding-bottom: 75px !important;
  }
  .pt-lg--10 {
    padding-top: 125px !important;
  }
  .pb-lg--10 {
    padding-bottom: 125px !important;
  }
  .pl-md--0 {
    padding-left: 0;
  }
  .p-md--5 {
    padding: 3rem !important;
  }
  .float-right-md {
    float: right !important;
  }
  .vh-lg--100 {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .p-md--5 {
    padding: 3rem !important;
  }
  .vh-md-100 {
    height: 100vh;
  }
}
@media (min-width: 1800px) {
  .col-xxl-9 {
    flex: 0 0 73%;
    max-width: 73%;
  }
  .col-xxl-3 {
    flex: 0 0 27%;
    max-width: 27%;
  }
  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
@media (min-width: 1600px) {
  .container-half-fluid {
    width: 1400px;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .middle-sidebar-left {
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .vh-lg-100 {
    height: 100vh;
  }
  .pl-lg--5 {
    padding-left: 3rem !important;
  }
  .pr-lg--5 {
    padding-right: 3rem !important;
  }
  .p-lg--5 {
    padding: 3rem !important;
  }
}
@media (max-width: 1600px) {
  .display5-size {
    font-size: 60px !important;
  }

  .middle-sidebar-right {
    position: fixed;
    top: 0;
    right: -350px;
    z-index: 2;
    padding: 15px;
    overflow: scroll;
    height: calc(100vh);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .middle-sidebar-right.active-sidebar {
    right: 0px;
  }
}
@media (max-width: 1400px) {
  .xl-p-5 {
    padding: 3rem;
  }
}
@media (max-width: 1200px) {
  .coming-soon-card,
  .login-card {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .display4-lg-size {
    font-size: 50px !important;
  }
}
@media (max-width: 991px) {
  .display2-md-size {
    font-size: 30px !important;
  }

  .coming-soon-card,
  .login-card {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .md-mb-2 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 768px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .sm-mb-4 {
    margin-bottom: 2rem !important;
  }
  .sm-mt-2 {
    margin-top: 1rem !important;
  }
  .sm-mt-7 {
    margin-top: 5rem !important;
  }
  .sm-mb-5 {
    margin-bottom: 3rem !important;
  }
  .font-md-xxl {
    font-size: 28px !important;
  }
  .sm-text-center {
    text-align: center;
  }
  .coming-soon-card,
  .login-card {
    min-width: 320px;
  }
  .sm-pt-10 {
    padding-top: 5rem;
  }
}
@media (max-width: 576px) {
  .d-none-xs {
    display: none !important;
  }
  .xs-mb-4 {
    margin-bottom: 2rem !important;
  }
  .xs-mb-2 {
    margin-bottom: 1rem !important;
  }
  .xs-p-4 {
    padding: 2rem;
  }
  .xs-pt-10 {
    padding-top: 150px;
  }
  .col-xss-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .display4-xs-size {
    font-size: 40px !important;
  }
  .font-md-xs {
    font-size: 18px !important;
  }
  .display1-sm-size {
    font-size: 25px !important;
  }
}
@media (max-width: 1600px) {
  .display5-size {
    font-size: 60px !important;
  }
}

.search-form {
  position: relative;
}
.search-form input {
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500;
}
.search-form i {
  position: absolute;
  top: 18px;
  right: 15px;
  color: #999;
}
input[type="text"]:focus {
  outline: unset !important;
  outline-offset: unset !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
}
.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: var(---theme-color);
  border: 0;
}
.custom-switch .custom-control-input:active ~ .custom-control-label::before {
  border: 0;
}
select.sort {
  border: 0;
  color: #999;
  width: 180px;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 15px;
  border: 2px solid #ddd;
  border-radius: 20px;
}

.custom-radio.size-sm label::before {
  width: 15px !important;
  height: 15px !important;
  top: 5px;
  left: -30px;
}

.text-current {
  color: var(--theme-color) !important;
}

.text-red {
  color: red !important;
}

.text-black {
  color: #000 !important;
}

.text-cyan {
  color: #00a9a8 !important;
}

.text-yellow {
  color: #ffde00 !important;
}
.box-none {
  box-shadow: none !important;
}

.catalogue-course {
  font-size: 16px;
  color: #0f59c7 !important;
  font-weight: 700;
}
.catalogue-course > a:hover {
  font-size: 16px;
  color: #0056b3 !important;
  font-weight: 700;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .catalogue-course > a:hover {
    font-size: 12px;
  }
}

.fill-blue {
  fill: #3776f4;
}
svg.expandable-bars {
  width: 25px !important;
  height: 25px !important;
}

p,
h4 {
  font-family: "Work Sans", sans-serif;
}

.pattern-layer {
  position: relative;
}
.pattern-layer:after {
  content: "";
  position: absolute;
  top: -300px;
  right: -10%;
  height: 100%;
  z-index: 1;
  width: 120%;
  height: 100%;
  background-image: url(../../images/layer-pattenr-bg.png);
  background-repeat: repeat-y;
  background-size: contain;
}
.pattern-layer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.8);
}
.pattern-layer .layer-after {
  position: relative;
  z-index: 3;
}

.w10 {
  width: 12px !important;
}

.w15 {
  width: 15px !important;
}

.w20 {
  width: 18px !important;
}

.w25 {
  width: 25px !important;
}

.w30 {
  width: 30px !important;
}

.w35 {
  width: 35px !important;
}

.w40 {
  width: 40px !important;
}
.w125 {
  width: 125px !important;
}

.w140 {
  width: 140px !important;
}

.w150 {
  width: 150px !important;
}

.w175 {
  width: 175px !important;
}

.w200 {
  width: 200px !important;
}

.w250 {
  width: 250px !important;
}

.w300 {
  width: 300px !important;
}

.w350 {
  width: 350px !important;
}

.w400 {
  width: 400px !important;
}

.w__12 {
  width: 12.1% !important;
  flex: 0 0 30% !important;
  max-width: 30% !important;
}

.w__30 {
  width: 30% !important;
  flex: 0 0 30% !important;
  max-width: 30% !important;
}

.w__60 {
  width: 60% !important;
  flex: 0 0 60% !important;
  max-width: 60% !important;
}

.w__70 {
  width: 70% !important;
  flex: 0 0 70% !important;
  max-width: 70% !important;
}

.w__48 {
  width: 49% !important;
  flex: 0 0 49% !important;
  max-width: 49% !important;
}

li {
  list-style: none !important;
}
.btn-round-xss {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  line-height: 25px !important;
  text-align: center;
}
.btn-round-xss i {
  line-height: 25px !important;
}

.btn-round-xs {
  width: 30px;
  height: 14px;
  border-radius: 20px;
  display: inline-block;
  line-height: 20px !important;
  text-align: center;
}
.btn-round-xs i {
  line-height: 20px !important;
}

.btn-round-sm {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: inline-block;
  line-height: 35px !important;
  text-align: center;
}
.btn-round-sm i {
  line-height: 35px !important;
}

.btn-round-md {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  display: inline-block;
  line-height: 45px !important;
  text-align: center;
}
.btn-round-md i {
  line-height: 45px;
}

.btn-round-lg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
}
.btn-round-lg i {
  line-height: 50px;
}

.btn-round-xl {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
}
.btn-round-xl i {
  line-height: 60px;
}
.btn-round-xl img {
  line-height: 60px;
}

.btn-round-xxl {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
}
.btn-round-xxl i {
  line-height: 60px;
}

.btn-round-xxxl {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: inline-block;
  line-height: 80px;
  text-align: center;
}
.btn-round-xxxl i {
  line-height: 80px;
}

.btn-round-xxxxl {
  width: 110px;
  height: 110px;
  border-radius: 110px;
  display: inline-block;
  line-height: 110px;
  text-align: center;
}
.btn-round-xxxxl i {
  line-height: 110px;
}

.z-index-1 {
  z-index: 2;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.ls-0 {
  letter-spacing: -0.4px;
}

.ls-1 {
  letter-spacing: 0.4px;
}

.ls-2 {
  letter-spacing: 0.6px;
}

.ls-3 {
  letter-spacing: 1px;
}

.lh-1 {
  line-height: 1;
}

.lh-2 {
  line-height: 1.2;
}

.lh-3 {
  line-height: 1.4;
}

.lh-4 {
  line-height: 1.6;
}

.lh-5 {
  line-height: 2;
}

.lh-16 {
  line-height: 16px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.lh-26 {
  line-height: 26px;
}

.lh-28 {
  line-height: 28px;
}

.lh-30 {
  line-height: 30px !important;
}

.lh-32 {
  line-height: 32px;
}

.lh-34 {
  line-height: 34px;
}

.lh-38 {
  line-height: 38px;
}

.info-membership__size,
.pros-text__size {
  font-size: 21px;
}

.asset-placeholder__image {
  margin-bottom: 16px;
  height: 190px;
}

.image-user__testi {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.not-found__image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
}
.not-found__image > .icon-user {
  font-size: 20px;
}
.text-membership__individu,
.info_corporate__size {
  color: #595959ff;
}
.info_corporate__size {
  font-size: 16px;
}
.font-xsssss {
  font-size: 10px !important;
}

.font-xssss {
  font-size: 12px !important;
}

.font-xsss {
  font-size: 14px !important;
}

.font-xss {
  font-size: 16px !important;
}

.font-xs {
  font-size: 18px !important;
}

.font-sm {
  font-size: 20px !important;
}

.font-md {
  font-size: 22px !important;
}

.font-lg {
  font-size: 25px !important;
}

.font-xl {
  font-size: 28px !important;
}

.font-xxl {
  font-size: 32px !important;
}

.display1-size {
  font-size: 36px !important;
}

.display2-size {
  font-size: 44px !important;
}

.display3-size {
  font-size: 50px !important;
}

.display4-size {
  font-size: 60px !important;
}

.display5-size {
  font-size: 80px !important;
}

.text-corporate {
  color: #595959ff;
  font-size: 21px;
}

.testimoni-customer {
  color: #595959ff;
}

.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0 !important;
}

.top--15 {
  top: -15px !important;
  z-index: 2;
}

.top--10 {
  top: -10px !important;
  z-index: 2;
}

.top-5 {
  top: 3px;
}

.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.left-15 {
  left: 15px;
}

.left--50 {
  left: 50%;
}

.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0;
}

.right-15 {
  right: 15px;
}

.right--10 {
  right: -10px;
}

.pt-7 {
  padding-top: 75px;
}

.pb-7 {
  padding-bottom: 75px;
}

.pt-10 {
  padding-top: 125px;
}

.pb-10 {
  padding-bottom: 125px;
}

.p-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-7 {
  padding-left: 70px;
}

.mt--1 {
  margin-top: -0.4rem !important;
}

.mt--6 {
  margin-top: -4.2rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.text-grey-100 {
  color: #f8f9fa !important;
}

.text-grey-200 {
  color: #e9ecef !important;
}

.text-grey-300 {
  color: #dee2e6 !important;
}

.text-grey-400 {
  color: #ced4da !important;
}

.text-grey-500 {
  color: #adb5bd !important;
}

.text-grey-600 {
  color: #6c757d !important;
}

.text-grey-700 {
  color: #495057 !important;
}

.text-grey-800 {
  color: #343a40 !important;
}

.text-grey-900 {
  color: #212529 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.swatch-primary {
  background-color: #fff;
}

.swatch-secondary {
  background-color: #fff;
}

.swatch-success {
  background-color: #fff;
}

.swatch-info {
  background-color: #fff;
}

.swatch-warning {
  background-color: #212529;
}

.swatch-danger {
  background-color: #fff;
}

.swatch-light {
  background-color: #212529;
}

.swatch-dark {
  background-color: #fff;
}

.pt150 {
  padding-top: 150px;
}

.dropdown-icon {
  margin-right: 12px;
}
.mdi {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-count {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -10px;
  right: -10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.menu-icon {
  position: relative;
}
.menu-icon .dot-count {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  width: 7px;
  height: 7px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
}

.search-form {
  position: relative;
}
.search-form input {
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500;
}
.search-form i {
  position: absolute;
  top: 18px;
  right: 15px;
  color: #999;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
}
.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: var(---theme-color);
  border: 0;
}
.custom-switch .custom-control-input:active ~ .custom-control-label::before {
  border: 0;
}

select.sort {
  border: 0;
  color: #999;
  width: 180px;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 15px;
  border: 2px solid #ddd;
  border-radius: 20px;
}

.custom-radio.size-sm label::before {
  width: 15px !important;
  height: 15px !important;
  top: 5px;
  left: -30px;
}

.contact-wrap {
  z-index: 1;
  top: -100px;
  padding: 60px 80px 70px 80px;
}
@media (max-width: 1199.98px) {
  .contact-wrap {
    padding: 50px;
  }
}
@media (max-width: 991.98px) {
  .contact-wrap {
    padding: 40px 30px;
  }
  .contact-wrap .display3-size {
    font-size: 40px !important;
    margin-bottom: 30px !important;
  }
}

.contact-wrapper {
  padding: 80px;
  max-width: 550px;
}
@media (max-width: 1199.98px) {
  .contact-wrapper {
    padding: 50px;
    max-width: 500px;
    margin-top: 80px;
    margin-bottom: 50px;
  }
}

.video-bttn {
  position: relative;
}
.video-bttn:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-left: -30px;
  margin-top: -30px;
  background-image: url(../../images/icon-play.png);
  background-size: contain;
  display: block;
  width: 60px;
  height: 60px;
}
.video-bttn.default {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50% !important;
  animation: videobtn 1.9s linear infinite;
  display: inline-block;
  width: 60px;
  height: 60px;
}
.video-bttn.default:after {
  margin-left: 0;
  position: relative;
  left: 0;
}

.triangle-after {
  position: relative;
}
.triangle-after:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #fff transparent transparent transparent;
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 2;
}

.arrow-right:after {
  background-image: url(../../images/arrow.png);
  width: 50px;
  right: -30px;
  z-index: 2;
  position: absolute;
  height: 17px;
  background-size: contain;
  background-position: center center;
  display: inline-block;
  content: "";
  position: absolute;
  top: 35px;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 768px) {
  .arrow-right:after {
    display: none;
  }
}

.nav-tabs li a {
  transition: all 0.4s ease;
}
.nav-tabs li a.active {
  border-bottom: 2px #333 solid !important;
  color: #000 !important;
}

.login-card {
  min-width: 380px;
  max-width: 400px;
}

.coming-soon-card {
  min-width: 500px;
  max-width: 500px;
}

.banner-slider .style-div {
  height: 570px;
}
@media (max-width: 1199.98px) {
  .banner-slider .style-div {
    height: 450px;
  }
}

.recent-post {
  padding-left: 20px;
}
.recent-post li {
  list-style-type: disc;
  margin-bottom: 5px;
}
.recent-post li a {
  font-size: 14px;
  font-weight: 500;
  color: #777;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: var(---theme-color);
  color: #9880ff;
  box-shadow: none;
  animation: dotTyping 1.5s infinite linear;
  top: -4px;
  position: relative;
}

.memberlist {
  margin-left: -15px;
  position: relative;
}
.memberlist li {
  display: inline-block;
  float: none;
  position: relative;
  width: 15px;
}
.memberlist li.last-member a {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 30px;
  line-height: 30px;
}

.chat-bottom {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
}

.cart-wrapper .table tbody td {
  border-top: 0;
  vertical-align: middle;
  padding-top: 10px;
}

.input-code {
  width: 200px;
  height: 60px;
  line-height: 60px;
}
@media (max-width: 991.98px) {
  .input-code {
    width: 165px;
  }
}

.animation-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.course-slider {
  height: 410px;
  background-color: #000;
}
@media (max-width: 1199.98px) {
  .course-slider {
    height: 370px;
  }
}
.course-slider .card-body {
  width: 60%;
  padding: 0px 0 0 70px;
  z-index: 3;
}
@media (max-width: 991.98px) {
  .course-slider .card-body {
    width: 90%;
    padding: 20px;
  }
}
@media (max-width: 991.98px) {
  .course-slider .card-body .w200 {
    width: 150px !important;
  }
}
.course-slider .video-wrap {
  width: 80%;
  margin-left: auto;
  position: relative;
  height: 100%;
}
@media (max-width: 991.98px) {
  .course-slider .video-wrap {
    width: 100%;
  }
}
.course-slider .video-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 60%;
  height: 100%;
  background: linear-gradient(to right, #000, transparent);
  z-index: 2;
}
.course-slider .video-wrap video {
  position: absolute;
  width: 120%;
  top: 0;
}
@media (max-width: 991.98px) {
  .course-slider .video-wrap video {
    width: 200%;
  }
}
.cat-card-hover {
  cursor: pointer;
}
.cat-card-hover img {
  transition: all 0.4s ease !important;
  position: relative;
}
.cat-card-hover:hover img {
  transform: scale(1.2);
}

.course-card .card-image {
  overflow: hidden;
}
.course-card .card-image img {
  transition: all 0.4s ease !important;
  position: relative;
}
.course-card:hover .card-image img {
  transform: scale(1.1);
}

@media (min-width: 991px) {
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .main-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
  }

  .middle-wrap {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .pr-md--2 {
    padding-right: 0.5rem !important;
  }

  .pl-md--2 {
    padding-left: 0.5rem !important;
  }

  .pt-lg--5 {
    padding-top: 30px !important;
  }

  .pb-lg--5 {
    padding-bottom: 30px !important;
  }

  .pt-lg--7 {
    padding-top: 75px !important;
  }

  .pb-lg--7 {
    padding-bottom: 75px !important;
  }

  .pt-lg--10 {
    padding-top: 125px !important;
  }

  .pb-lg--10 {
    padding-bottom: 125px !important;
  }

  .pl-md--0 {
    padding-left: 0;
  }

  .p-md--5 {
    padding: 3rem !important;
  }

  .float-right-md {
    float: right !important;
  }

  .vh-lg--100 {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .p-md--5 {
    padding: 3rem !important;
  }

  .vh-md-100 {
    height: 100vh;
  }
}
@media (min-width: 1800px) {
  .col-xxl-9 {
    flex: 0 0 73%;
    max-width: 73%;
  }

  .col-xxl-3 {
    flex: 0 0 27%;
    max-width: 27%;
  }

  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
@media (min-width: 1600px) {
  .container-half-fluid {
    width: 1400px;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .middle-sidebar-left {
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .vh-lg-100 {
    height: 100vh;
  }

  .pl-lg--5 {
    padding-left: 3rem !important;
  }

  .pr-lg--5 {
    padding-right: 3rem !important;
  }

  .p-lg--5 {
    padding: 3rem !important;
  }
}
@media (max-width: 1600px) {
  .display5-size {
    font-size: 60px !important;
  }

  .middle-sidebar-right {
    position: fixed;
    top: 0;
    right: -350px;
    z-index: 2;
    padding: 15px;
    overflow: scroll;
    height: calc(100vh);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .middle-sidebar-right.active-sidebar {
    right: 0px;
  }
}
@media (max-width: 1400px) {
  .overflow-visible-xl,
  .overflow-visible-xl .owl-stage-outer {
    overflow: visible !important;
  }

  .xl-p-5 {
    padding: 3rem;
  }
}
@media (max-width: 1200px) {
  .coming-soon-card,
  .login-card {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .display4-lg-size {
    font-size: 50px !important;
  }
}
@media (max-width: 991px) {
  .display2-md-size {
    font-size: 30px !important;
  }

  .coming-soon-card,
  .login-card {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .md-mb-2 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 768px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sm-mb-4 {
    margin-bottom: 2rem !important;
  }

  .sm-mt-2 {
    margin-top: 1rem !important;
  }

  .sm-mt-7 {
    margin-top: 5rem !important;
  }

  .sm-mb-5 {
    margin-bottom: 3rem !important;
  }

  .font-md-xxl {
    font-size: 28px !important;
  }

  .sm-text-center {
    text-align: center;
  }

  .coming-soon-card,
  .login-card {
    min-width: 320px;
  }

  .sm-pt-10 {
    padding-top: 5rem;
  }
}
@media (max-width: 576px) {
  .d-none-xs {
    display: none !important;
  }

  .xs-mb-4 {
    margin-bottom: 2rem !important;
  }

  .xs-mb-2 {
    margin-bottom: 1rem !important;
  }

  .xs-p-4 {
    padding: 2rem;
  }

  .xs-pt-10 {
    padding-top: 150px;
  }

  .col-xss-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .display4-xs-size {
    font-size: 40px !important;
  }

  .font-md-xs {
    font-size: 18px !important;
  }

  .display1-sm-size {
    font-size: 25px !important;
  }
}

.shadow-box__search {
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2), 0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
}
.header_left__item.dropdown::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  background-image: url("../../images/icon-nav-arow-botom.png");
  width: 10px;
  height: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}
.header_left__item.dropdown:hover::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  background-image: url("../../images/icon-nav-bottom-hover.png");
  width: 10px;
  height: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}
.header_left__item a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.dropdown-navigator {
  font-size: 14px !important;
}
.header_left__item a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.h100 {
  height: 100px !important;
}
.h125 {
  height: 125px !important;
}
.h150 {
  height: 150px !important;
}
.h175 {
  height: 175px !important;
}
.h200 {
  height: 200px !important;
}
.h240 {
  height: 242px !important;
}
.h290 {
  height: 290px !important;
}
.h300 {
  height: 300px !important;
}
.h361 {
  height: 361px !important;
}
.h400 {
  height: 400px !important;
}
.h450 {
  height: 450px !important;
}
.h500 {
  height: 500px !important;
}
.h600 {
  height: 600px !important;
}
.btn-round-xss {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  line-height: 25px !important;
  text-align: center;
}
.btn-round-xss i {
  line-height: 25px !important;
}

.btn-round-xs i {
  line-height: 20px !important;
}
.btn-round-sm {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: inline-block;
  line-height: 35px !important;
  text-align: center;
}
.btn-round-sm i {
  line-height: 35px !important;
}
.btn-round-md {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  display: inline-block;
  line-height: 45px !important;
  text-align: center;
}
.btn-round-md i {
  line-height: 45px;
}
.btn-round-lg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
}
.btn-round-lg i {
  line-height: 50px;
}
.btn-round-xl {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
}
.btn-round-xl i {
  line-height: 60px;
}
.btn-round-xl img {
  line-height: 60px;
}
.btn-round-xxl {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
}
.btn-round-xxl i {
  line-height: 60px;
}
.btn-round-xxxl {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: inline-block;
  line-height: 80px;
  text-align: center;
}
.btn-round-xxxl i {
  line-height: 80px;
}
.btn-round-xxxxl {
  width: 110px;
  height: 110px;
  border-radius: 110px;
  display: inline-block;
  line-height: 110px;
  text-align: center;
}
.btn-round-xxxxl i {
  line-height: 110px;
}
.z-index-1 {
  z-index: 2;
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
.ls-0 {
  letter-spacing: -0.4px;
}
.ls-1 {
  letter-spacing: 0.4px;
}
.ls-2 {
  letter-spacing: 0.6px;
}
.ls-3 {
  letter-spacing: 1px;
}
.lh-1 {
  line-height: 1;
}
.lh-2 {
  line-height: 1.2;
}
.lh-3 {
  line-height: 1.4;
}
.lh-4 {
  line-height: 1.6;
}
.lh-5 {
  line-height: 2;
}
.lh-16 {
  line-height: 16px !important;
}
.lh-24 {
  line-height: 24px !important;
}
.lh-26 {
  line-height: 26px;
}
.lh-28 {
  line-height: 28px;
}
.lh-30 {
  line-height: 30px !important;
}
.lh-32 {
  line-height: 32px;
}
.lh-34 {
  line-height: 34px;
}
.lh-38 {
  line-height: 38px;
}
.shadow-md {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}
.shadow-xs {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
}
.shadow-xss {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05) !important;
}
.border-size-sm {
  border-width: 1px;
}
.border-size-md {
  border-width: 2px !important;
}
.border-size-lg {
  border-width: 3px !important;
}
.border-light {
  border: 1px #eee solid;
}
.border-light-md {
  border: 2px #ddd solid;
}
.border-right-light {
  border-right: 1px #eee solid;
}
.border-bottom-light {
  border-bottom: 1px #eee solid;
}
.border-bottom-dark {
  border-bottom: 2px #333 solid;
}
.border-bottom-blue {
  border-bottom: 1px #1254a1 solid;
}
.border-dashed {
  border: 2px #ddd dashed !important;
}
.border-black {
  border: 2px #000 dashed !important;
}
.border-top-lg {
  border-top: 5px solid #000;
}

.bottom-0 {
  bottom: 0;
}
.top-0 {
  top: 0 !important;
}
.top--15 {
  top: -15px !important;
  z-index: 2;
}
.top--10 {
  top: -10px !important;
  z-index: 2;
}
.top-5 {
  top: 3px;
}
.left-auto {
  left: auto !important;
}
.left-0 {
  left: 0px !important;
}
.left-15 {
  left: 15px;
}
.left--50 {
  left: 50%;
}
.right-auto {
  right: auto !important;
}
.right-0 {
  right: 0;
}
.right-15 {
  right: 15px;
}
.right--10 {
  right: -10px;
}
.pt-7 {
  padding-top: 75px;
}
.pb-7 {
  padding-bottom: 75px;
}
.pt-10 {
  padding-top: 125px;
}
.pb-10 {
  padding-bottom: 125px;
}
.p-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-7 {
  padding-left: 70px;
}
.mt--1 {
  margin-top: -0.4rem !important;
}
.mt--6 {
  margin-top: -4.2rem !important;
}
.round-lg-btn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
}
.rounded-xxl {
  border-radius: 15px !important;
}
.rounded-xl {
  border-radius: 30px !important;
}
.list-style-disc li {
  list-style-type: disc;
  margin-bottom: 5px;
}
.avatar.w90 {
  overflow: hidden;
}
