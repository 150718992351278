.dashboard_contact__us {
  background-color: #008bff;
}
.contact_us__title {
  font-weight: 700;
  color: #fff;
}
.contact_us__subtitle_top {
  color: #fff;
  opacity: 0.75;
}
.contact_us__subtitle {
  margin-bottom: 24px;
}
.modal-topup__selection {
  margin-bottom: 24px;
}
.card_contact__us {
  background-color: #f4f4f4;
}
.dashboard_top__contact_us {
  font-weight: 600;
}
.contact_us__left {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 28px;
}
.contact_us__left_bottom {
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
#list_contact > ul {
  padding-left: 28px;
  margin-bottom: 32px;
}
#list_contact > ul > li {
  list-style: disc !important;
  line-height: 28px;
  letter-spacing: 1px;
}
.button_whatsapp {
  font-weight: 600;
}
