.rbc-calendar {
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  border-radius: 24px;
  width: max-content;
}
.overflow_mobile {
  overflow-x: auto;
}
@media (min-width: 576px) {
  .rbc-calendar {
    box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
    border-radius: 24px;
    width: unset;
  }
  .overflow_mobile {
    overflow-x: unset;
  }
}

.rbc-toolbar {
  margin-bottom: 24px;
}

.rbc-toolbar button {
  color: #0f59c7 !important;
  background: #fff;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  border: none;
  padding: 8px;
  margin-right: 8px;
}

.rbc-toolbar-label {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
  font-size: 16px;
}
.rbc-toolbar > .rbc-btn-group:first-child > button {
  width: 35px;
  height: 35px;
  border-radius: 20px !important;
}

.rbc-btn-group > button {
  height: 50px !important;
}

.rbc-toolbar > .rbc-btn-group:first-child > button:first-child {
  display: none;
}
.rbc-toolbar > .rbc-btn-group:last-child > button {
  padding: 16px;
}
.rbc-toolbar-label {
  text-align: left !important;
}
.rbc-toolbar > .rbc-btn-group:last-child button {
  margin-right: 0 !important;
}
.rbc-header {
  background: #fff;
  color: #383838;
  padding: 16px 0;
  font-size: 16px;
  line-height: 24px;
}

.rbc-date-cell {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}
.title-webinar__detail {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #383838;
  font-family: "Poppins", sans-serif !important;
}
.date-webinar__detail {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
}
.container-webinar__detail {
  width: 265px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none !important;
}
.instructor-webinar__img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: lightgray;
}
.instructor-webinar__name {
  font-family: "Work Sans", sans-serif;
}
.button-webinar__detail {
  background: #c9d7ed;
  border-radius: 8px;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: none !important;
  width: 120px;
  color: #0f59c7;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: #c9d7ed;
  border-radius: 8px;
  padding: 3px 5px;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: rgb(130 175 216) !important;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}
@media (max-width: 768px) {
  .rbc-date-cell {
    font-weight: bold;
    font-size: 24px;
    line-height: 15px;
  }
  .rbc-date-cell > a,
  .rbc-date-cell > a:active,
  .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
    font-size: 12px;
  }
  .rbc-header {
    background: #fff;
    color: #383838;
    padding: 16px 0;
    font-size: 12px;
    line-height: 4px;
    font-weight: 500;
  }
  .rbc-toolbar button {
    color: #0f59c7 !important;
    background: #fff;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
    border-radius: 12px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    border: none;
    padding: 8px;
    margin-right: 8px;
    border-radius: 6px !important;
  }
}
