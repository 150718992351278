.related_material__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
  margin-bottom: 12px;
}

.related_material__item_wrapper {
  background: #f4f4f4;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  border-radius: 24px;
  padding: 24px;
  display: flex;
}

.related_material__item_wrapper:not(:last-of-type) {
  margin-bottom: 24px;
}

.related_material__img_wrapper {
  margin-right: 16px;
  background: #3776f4;
  border-radius: 12px;
  width: 58px;
  height: 58px;
  flex-shrink: 0;
}
.related_material__thumbnail {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 12px;
}
.related_material__item_title {
  font-weight: bold;
  font-size: 20px;
  line-height: normal;
  color: #332c2c;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.related_material__course_num {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(51, 44, 44, 0.5);
  font-family: "Nunito", sans-serif;
}
.show_all__button {
  height: 35px;
  font-size: 12px;
  border-radius: 6px !important;
}
.recommend_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
  .related_material__item_title {
    font-size: 16px;
    line-height: 24px;
  }
}
