.parent__heroshot {
  margin-bottom: 32px;
}
.badge__heroshot {
  color: #306ccc;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  padding: 4px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 38px;
  background: #c5dbff;
}
.hero__shot-apindo {
  background-image: url("./../../images/atpi/heroshot/bg-heroshot-apindo.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero__shot-atpi {
  background-image: url("./../../images/atpi/heroshot/banner-hershot-atpi.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1200px) {
  .hero__shot-atpi {
    background-position: right;
  }
  .hero__shot-apindo {
    background-position: right;
  }
}
.title__heroshot-atpi {
  color: #306ccc;
  font-family: Poppins;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3px;
}
.subtitle__heroshot-atpi {
  width: 469px;
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.5%; /* 44.65px */
  letter-spacing: 1.14px;
}
@media (min-width: 768px) {
  .subtitle__heroshot-atpi {
    width: 469px;
  }
  .desc__heroshot-atpi {
    width: 738px;
  }
}
.desc__heroshot-atpi {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%; /* 30.51px */
  letter-spacing: 0.72px;
}
.btn__heroshot-atpi:hover,
.btn__cta-footer:hover {
  background: #fff;
  color: #306ccc;
  text-decoration: unset;
}
.btn__heroshot-atpi {
  color: #f2f2f2;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 2px solid #306ccc;
  background: #306ccc;
}
.wrapp__desc-footer-atpi .footer__desc-atpi {
  margin-top: 34px;
}
.footer__desc-atpi {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 275;
  line-height: 150%; /* 18px */
  letter-spacing: 0.48px;
  display: inline-block;
}
@media (min-width: 1200px) {
  .footer__desc-atpi {
    width: 802px;
  }
  .wrapp__desc-footer-atpi .footer__desc-atpi {
    margin-top: 54px;
  }
}
.img__heroshot-right {
  width: 100%;
  height: 508px;
  object-fit: contain;
  object-position: bottom;
}
.parent__visi-misi {
  margin-bottom: 49px;
}
.wording__visi-misi {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 768px) {
  .title__misi {
    padding-left: 1em;
  }
}
.title__misi,
.title__visi {
  color: #2d2c2a;
  text-align: justify;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 169.5%; /* 40.68px */
  letter-spacing: 0.96px;
}
.desc__visi {
  color: #2d2c2a;
  text-align: justify;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%; /* 30.51px */
  letter-spacing: 0.72px;
}
.bg__visi {
  position: absolute;
  top: -60px;
  right: 0;
  z-index: -1;
}
@media (min-width: 1200px) {
  .desc__visi {
    width: 693px;
  }
  .parent__visi-misi ol,
  .parent__cta ol {
    width: 693px;
  }
}
@media (max-width: 767px) {
  .parent__visi-misi ol,
  .parent__cta ol {
    padding-left: 15px;
  }
}
.parent__visi-misi ol li,
.parent__cta ol li {
  list-style: decimal !important;
  display: list-item;
  color: #2d2c2a;
  text-align: justify;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%; /* 30.51px */
  letter-spacing: 0.72px;
}

.bg__misi {
  position: absolute;
  bottom: -58px;
  left: 0;
  z-index: -1;
}
.parent__cta {
  margin-bottom: 46px;
}
.wording__cta {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 1200px) {
  .desc__cta {
    width: 693px;
  }
  .wording__cta {
    margin-left: 1em;
  }
}
.desc__cta {
  color: #2d2c2a;
  text-align: justify;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%; /* 30.51px */
  letter-spacing: 0.72px;
}
.link__cta {
  color: #065757;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.parent__certificate,
.parent__certificate-profesional {
  background: #eee;
  padding: 30px;
  margin-bottom: 46px;
}
.woding__certificate,
.woding__certificate-prop {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 30px;
}
.woding__certificate em,
.woding__certificate-prop em {
  font-weight: 500;
}
@media (max-width: 767px) {
  .woding__certificate,
  .woding__certificate-prop {
    font-size: 24px;
    text-align: left;
  }
  em.sub__wording-certificate,
  em.sub__wording-certificate-prop {
    font-size: 15px;
    text-align: left;
  }
}
.sub__wording-certificate,
.sub__wording-certificate-prop {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  display: block;
  position: relative;
  z-index: 2;
}
.bg__tiktik-certificate {
  position: absolute;
  top: 80px;
  left: -15px;
}
.bg__tiktik-certificate-apindo {
  position: absolute;
  top: 120px;
  left: -15px;
}
.wording__cta-footer {
  color: #11427d;
  font-family: Open Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 147.182%; /* 58.873px */
  letter-spacing: -1.2px;
}
.wording__cta-footer span {
  font-weight: 800;
}
@media (min-width: 768px) {
  .wording__cta-footer {
    width: 621px;
  }
}
@media (max-width: 767px) {
  .wording__cta-footer {
    font-size: 30px;
  }
}
.btn__cta-footer {
  border-radius: 12px;
  border: 2px solid #306ccc;
  background: #306ccc;
  display: inline-flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 10px;
  color: #f2f2f2;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.btn__cta-footer:focus {
  color: #306ccc;
}
.woding__certificate-apindo {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.woding__certificate-apindo em {
  font-weight: 500;
}
@media (max-width: 767px) {
  .woding__certificate-apindo {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .woding__certificate-apindo {
    width: 555px;
  }
}
@media (min-width: 1200px) {
  .woding__certificate-apindo {
    margin-left: -70px;
  }
}
.icon__galih-apindo {
  position: absolute;
  bottom: 70px;
  left: -110px;
}
@media (min-width: 768px) {
  .w-md-50-apindo {
    width: 50% !important;
  }
}
