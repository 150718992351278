.separator {
  padding-top: 16px;
  color: #666666;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: right;
}
.dashboard__section_title {
  margin-bottom: 24px;
}

.breadcrumb {
  background-color: #fff !important;
  padding: 0 !important;
}
.score_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #232323;
}
.mobile_container__breadcrumb {
  display: block;
}
@media (min-width: 768px) {
  .mobile_container__breadcrumb {
    display: none;
  }
}
.not_found__course {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.5;
}
.course_min__height {
  min-height: calc(100vh - 290px);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid rgba(0, 0, 0, 0.185);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
  margin-right: 2rem;
}

@media (max-width: 768px) {
}

.my_course__page {
  padding-top: 40px;
  padding-bottom: 40px;
}

.my_course__section_title {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 24px;
}
