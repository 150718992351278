.course_detail__container {
  background-color: #fff;
  padding: 24px 32px;
  border-radius: 24px;
  margin-bottom: 32px;
  background-size: cover;
}
.btn_satukelas_bookmark_wishlist {
  background-color: #f4f4f4 !important;
  padding: 12px 16px;
  border-radius: 8px;
  margin-right: 16px;
}
.title_social__media {
  color: #000;
  font-weight: 600;
  margin-bottom: 24px;
}
.social_media__link {
  font-weight: 600;
  font-size: 12px;
  padding: 8px;
  border: 3px solid #0062cc;
  border-radius: 8px;
  width: 60px;
  height: 60px;
  color: #0062cc;
  text-decoration: none;
  transition: 0.15s all ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social_media__link:hover {
  font-weight: 600;
  text-decoration: none;
  font-size: 12px;
  padding: 8px;
  background-color: #0062cc;
  color: white;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  color: #0062cc;
  text-decoration: none;
  transform: scale(1.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.social_media__link > span {
  font-size: 20px;
  color: #0062cc;
}
.social_media__link:hover > span {
  font-size: 20px;
  color: #fff;
}
.social_media__link > svg {
  fill: #0062cc !important;
}
.social_media__link:hover > svg {
  fill: #fff !important;
}
.btn_satukelas_bookmark_wishlist:hover {
  background-color: #f4f4f4 !important;
  padding: 12px 16px;
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
  opacity: 0.75;
}
.btn_satukelas_bookmark_wishlist .icon-bookmark:before {
  color: #3776f4;
  font-size: 20px;
}
.fill-yellow {
  fill: yellow;
}
.btn_satukelas_share .icon-share:before {
  font-size: 20px;
}
.course_front__rating {
  display: none;
}
.rating-mobile {
  display: flex;
}
@media (min-width: 768px) {
  .rating-mobile {
    display: none;
  }
  .course_front__rating {
    display: flex;
  }
}

.btn_satukelas_bookmark .icon-bookmark:before {
  color: #fff;
  font-size: 20px;
}
.wrapper_course__detail {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0px 24px 40px -28px rgb(31 38 135 / 30%);
  backdrop-filter: blur(8px);
}
.course_detail__cta_wrapper > * {
  margin: 0;
}
@media (min-width: 576px) {
  .wrapper_course__detail {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 32px;
    border-radius: 24px;
    box-shadow: 0px 24px 40px -28px rgb(31 38 135 / 30%);
    backdrop-filter: blur(8px);
  }
  .course_detail__cta_wrapper > * {
    margin-right: 8px;
    margin-bottom: 16px;
  }
}

#course_detail__title.course_detail__title_front {
  font-size: 14px;
}
.course_detail__title_front {
  font-weight: 600;
}
.font-size__button {
  font-size: 12px;
}

.star-size {
  width: 30px !important;
  height: 30px !important;
}
.course_front_big__title_detail {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
}
.course_front__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
}
.course_front__title {
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
}
.course_detail__learning_path__wrapper {
  display: flex;
  margin-bottom: 24px;
}
.course_detail__learning_path {
  color: #000;
  background: #feb448;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 20px;
  margin-right: 4px;
}
.course_detail__course_title {
  font-weight: 700;
  color: #fff;
  font-size: 21px;
  line-height: normal;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.course_detail__course_title__black {
  font-size: 30px;
  line-height: 45px;
  color: #383838;
  font-weight: 700;
}
.course_desc__course {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  font-family: "Work Sans", sans-serif;
  color: #383838;
}
.see_more__description {
  color: #0f59c7;
  font-weight: 700;
}
.img-what__people {
  width: 40px;
  height: 40px;
  background-color: lightgray;
  border-radius: 20px;
}
.see_more__description:hover {
  cursor: pointer;
  text-decoration: underline;
}
.course_detail__info__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.course_detail__info__item {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  margin-right: 24px;
  margin-bottom: 8px;
  font-family: "Nunito", sans-serif;
}
.course_detail__icon {
  fill: #fff;
  margin-right: 8px;
}
.course_detail__summary__wrapper {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;
  letter-spacing: 1.15px;
  margin-bottom: 32px;
  font-family: "Nunito", sans-serif;
}
.course_detail__tutor__wrapper {
  margin-bottom: 32px;
}
.course_detail__tutor__item {
  display: flex;
  align-items: center;
}
.course_detail__tutor__item:not(:last-of-type) {
  margin-bottom: 4px;
}
.course_detail__tutor_avatar {
  background-color: #c4c4c4;
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.course_detail__tutor_avatar__white {
  background-color: white;
  width: 35px;
  height: 35px;
}
.course_detail__tutor_avatar__default {
  background-color: lightgray;
  width: 35px;
  height: 35px;
  object-fit: cover;
}
button {
  margin-right: 8px;
}
.course_duration__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0f59c7;
}
.course_duration__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  /* Grayscale/Black-Font */

  color: #383838;
}
.course_detail__tutor_name {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #383838;
}
.webinar_title__time,
.webinar_title__duration {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0f59c7;
}
.detail_session {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #383838;
  margin-bottom: 24px;
}
.course_more__list {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */

  text-align: center;

  /* Grayscale/Black-Font */

  color: #383838;
}
.related-webinar__more {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  /* Primary/SatuKelas/Primary-Normal */

  color: #0f59c7;
}
.course_more__description {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  /* Grayscale/Black-Font */

  color: #383838;
}
.button-see_more {
  font-weight: 700;
  font-size: 16px;
  line-height: 60px;
  border: 1px solid #0f59c7;
  border-radius: 8px;
  background-color: unset !important;
  color: #0f59c7;
  margin-bottom: 40px;
}
.button-see_more:hover {
  font-weight: 700;
  font-size: 16px;
  line-height: 60px;
  border: 1px solid #0b4cae;
  transform: scale(1.05);
  border-radius: 8px;
  background-color: unset !important;
  color: #0b4cae;
  margin-bottom: 40px;
}
.thumbnail_section {
  border-radius: 8px;
  width: 100%;
  height: 380px;
  object-fit: cover;
  margin-top: 24px;
}
@media (min-width: 768px) {
  .thumbnail_section {
    border-radius: 8px;
    width: 100%;
    height: inherit;
    object-fit: contain;
  }
}
.instructor_division {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #636262;
}
.course_detail__cta_wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.course_initial__instructor {
  position: absolute;
  top: 0;
  font-size: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;
}

/* utils */
.btn_satukelas_primary {
  height: 70px;
  background: #0f59c7 !important;
  border-radius: 8px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  color: #f8f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_satukelas_primary:hover {
  background: #174385 !important;
  color: #f8f6f6 !important;
  text-decoration: none !important;
}
@media (min-width: 576px) {
  .btn_satukelas_primary {
    height: 70px;
    background: #0f59c7 !important;
    border-radius: 8px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    color: #f8f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn_satukelas_share {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #e9e9e9;
  border-radius: 8px;
  margin-right: 16px;
}
.btn_satukelas_share:hover {
  background-color: #cccccc;
  cursor: pointer;
  margin-right: 16px;
}
.btn_satukelas_bookmark {
  padding: 12px;
  background: #3776f4;
  border-radius: 8px;
  margin-right: 16px;
}
.btn_satukelas_bookmark:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  cursor: pointer;
  margin-right: 16px;
}
.btn_satukelas_bookmark > span.icon-bookmark {
  font-size: 24px;
}
@media (min-width: 768px) {
  .course_detail__container {
    padding: 32px;
  }
  .course_detail__learning_path {
    font-size: 16px;
    line-height: 24px;
    font-family: "Nunito", sans-serif;
  }
  .course_detail__course_title {
    font-size: 32px;
    line-height: 60px;
  }
  .course_detail__summary__wrapper {
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 1.15px;
    font-family: "Nunito", sans-serif;
  }

  .btn_satukelas_share {
    padding: 16px 18px;
  }
  .btn_satukelas_bookmark {
    padding: 16px 18px;
  }
}
