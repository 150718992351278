.dashboard__title_wrapper {
  margin-bottom: 16px;
}
.dashboard__section_number {
  font-size: 14px;
  font-weight: 700;
}
.border-bottom__modal {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.transaction_time {
  font-size: 12px;
  font-weight: 600;
}
.va_container {
  background: #f4f4f4;
  padding: 8px 17px;
  border-radius: 8px;
  display: block;
}
@media (min-width: 576px) {
  .va_container {
    background: #f4f4f4;
    padding: 8px 17px;
    border-radius: 8px;
    display: flex;
  }
}
.info_icon__check:hover {
  cursor: pointer;
}
.check-payments {
  padding: 8px 16px;
  background-color: #008bff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
}
.accordion_bank {
  background: #008bff;
  border-radius: 6px !important;
  border: none;
  padding: 8px 16px;
  width: 100%;
}
.button_copy__va {
  margin-left: 8px;
  padding: 4px 16px;
  background-color: #008bff;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 4px;
}
.bank_list {
  font-size: 12px;
  font-weight: 500;
  color: white;
}
#bank_accordion {
  margin-top: 16px;
  padding-left: 16px;
}
#bank_accordion li {
  list-style-type: inherit !important;
}
.modal-content {
  max-height: 550px;
  overflow: auto;
}
.virtual_account {
  font-size: 16px;
  font-weight: 600;
}
.fill-date {
  fill: rgba(0, 0, 0, 0.5) !important;
}
.dashboard__section_date {
  font-size: 14px;
  font-weight: 500;
}
.topup-item__container {
  padding: 24px;
  border-radius: 24px;
  background-color: #f4f4f4;
}
@media (min-width: 576px) {
  .topup-item__container {
    padding: 32px;
    border-radius: 24px;
    background-color: #f4f4f4;
  }
}
.dashboard__main_content {
  width: 100%;
}

/* payment page */
.payment_bundle__wrapper {
  background: #f4f4f4;
  box-shadow: 0px 18px 40px -12px rgba(196, 196, 196, 0.25);
  border-radius: 24px;
  padding: 24px;
  width: 100%;
}

.payment_bundle__wrapper .payment_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.payment_bundle__wrapper .payment_status {
  background: #008bff;
  box-shadow: 0px 18px 40px -12px rgba(196, 196, 196, 0.25);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 8px 16px;
  display: inline-block;
}

.payment_bundle__wrapper .payment_status svg {
  fill: #fff;
}

.payment_item {
  display: flex;
  margin-right: 60px;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
}

.payment_item:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.payment_item .thumbnail_wrapper {
  background: #c4c4c4;
  border-radius: 12px;
  min-width: 100px;
  margin-right: 16px;
}
@media (max-width: 768px) {
  .payment_item .thumbnail_wrapper {
    background: #c4c4c4;
    border-radius: 12px;
    min-width: 60px;
    height: 60px;
    margin-right: 16px;
  }
}
.payment_item .payment_info__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}
.payment_info__others {
  display: flex;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
}

.payment_info__others div:first-child {
  margin-right: 24px;
}

.payment_item .payment_info__price {
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  color: #000000;
}
.topup-item__info-bottom {
  display: flex;
}
.topup-item__virtual-acc {
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  color: #000000;
  border: none;
  width: 100%;
}
.topup-item__info-top {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.topup-item__info-top span {
  margin-left: 8px;
  padding: 4px 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}
.dangerStatus {
  background-color: #dc3545;
}
.succesStatus {
  background-color: #28a745;
}
.primaryStatus {
  background-color: #008bff;
}
.grayStatus {
  background-color: #474747;
}
.topup-item__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topup-item__price {
  font-size: 24px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .payment_bundle__wrapper .payment_status {
    float: right;
  }
}
