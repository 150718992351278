.terbaru {
  background: radial-gradient(
    170.97% 1262.11% at 21.67% 50%,
    #0f59c7 0%,
    rgba(15, 89, 199, 0.991615) 11.79%,
    rgba(15, 89, 199, 0.967585) 21.38%,
    rgba(15, 89, 199, 0.9296) 29.12%,
    rgba(15, 89, 199, 0.879348) 35.34%,
    rgba(15, 89, 199, 0.818519) 40.37%,
    rgba(15, 89, 199, 0.7488) 44.56%,
    rgba(15, 89, 199, 0.671881) 48.24%,
    rgba(15, 89, 199, 0.589452) 51.76%,
    rgba(15, 89, 199, 0.5032) 55.44%,
    rgba(15, 89, 199, 0.414815) 59.63%,
    rgba(15, 89, 199, 0.325985) 64.66%,
    rgba(15, 89, 199, 0.2384) 70.88%,
    rgba(15, 89, 199, 0.153748) 78.62%,
    rgba(15, 89, 199, 0.0737185) 88.21%,
    rgba(15, 89, 199, 0) 100%
  );
}

.terbaru .terbaru-content {
  padding: 124px 0 102px;
}

.terbaru .terbaru-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 78px;
  margin-bottom: 16px;
  color: #f8f6f6;
}

.terbaru .terbaru-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 24px;
  color: #f8f6f6;
}

.terbaru .terbaru-cta {
  padding: 20px;
  text-decoration: none;
  /* height: 70px; */
  background: #f8f6f6;
  border-radius: 8px;
  width: fit-content;
  line-height: 70px;
  color: #0f59c7;
  font-weight: 700;
  font-size: 14px;
}

.terbaru-image {
  position: relative !important;
  right: 0 !important;
  left: 0 !important;
}

#terbaru iframe {
  height: 1200px;
  border: none;
}

@media (min-width: 992px) {
  .terbaru-image {
    position: absolute !important;
    bottom: 0;
    right: 0;
  }
  .terbaru .terbaru-cta {
    padding: 30px 20px;
    /* height: 70px; */
    background: #f8f6f6;
    border-radius: 8px;
    width: fit-content;
    line-height: 70px;
    color: #0f59c7;
    font-weight: 700;
    font-size: 16px;
  }
  .terbaru .terbaru-subtitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 24px;
    color: #f8f6f6;
  }
  .terbaru .terbaru-title {
    font-weight: 700;
    font-size: 52px;
    line-height: 78px;
    margin-bottom: 24px;
    color: #f8f6f6;
  }
}
