.hero-shot__container {
  background-image: url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/about-hero-shot.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-shot__box {
  background-color: rgba(0, 0, 0, 0.5);
}
.hero-shot__text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
  align-items: center;
}
.hero-shot__heading {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 24px;
  color: #f8f6f6;
}
.hero-shot__subheading {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #f8f6f6;
}
.information-section__illustration {
  margin-bottom: 62px;
  height: 100%;
}
.information-section__illustration .carousel.slide,
.information-section__illustration .carousel-inner,
.information-section__illustration .carousel-item,
.information-section__illustration .carousel-section__img {
  height: 100%;
  border-radius: 16px;
}
.information-section__illustration .carousel-section__img {
  width: 100%;
  background-size: cover;
  object-fit: cover;
}

.information-section__list {
  background: #ffffff !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
}
.information-section__accordion {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
}
.information-section__line {
  border-top: 4px solid #6c98da;
  width: 50%;
}
.information-section__accordion:hover,
.information-section__accordion > button:focus {
  cursor: pointer;
  text-decoration: none !important;
}
.information-section__accordion.accordion {
  margin-bottom: 0px;
}
.information-section__accordion > button {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  width: 100%;
  text-align: left;
  color: #000000 !important;
  border: none !important;
  background-color: #ffffff !important;
}
.information-section__accordion > button:hover {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  width: 100%;
  text-align: left;
  color: #000000 !important;
  border: none !important;
  background-color: #ffffff !important;
  text-decoration: none !important;
}

.information-section__description {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.information-section__list-unshow > button {
  box-shadow: none;
  background: #ffffff !important;
  padding: 24px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  color: #000000 !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 3px solid #9d9d9d;
  text-align: left;
}
/* border-top: 0px solid #9d9d9d; */
.client-section__list {
  padding: 42px 0 82px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.client-section__list > img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .client-section__list {
    flex-direction: row;
  }
}
.evolution-section__title {
  padding-top: 80px;
}
.evolution-section__subtitle {
  padding-bottom: 80px;
  margin-bottom: 0 !important;
}
.evolution-section__title,
.client-section__title,
.founder-section__main,
.ketuakelas-section__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  color: #383838;
  margin-bottom: 16px;
}
.founder-section__main {
  margin-bottom: 40px !important;
}
.blue-border {
  color: #0f59c7;
  padding-bottom: 16px !important;
  border-bottom: 8px solid #0f59c7;
}
.founder-section__title,
.information-section__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #383838;
  margin-bottom: 16px;
}
.evolution-section__subtitle,
.founder-section__subtitle,
.ketuakelas-section__subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #383838;
  margin-bottom: 56px;
}
.information-section__title {
  text-align: left;
  margin-bottom: 32px;
}
.profile-card__wrapper:first-child {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto 56px;
}
.profile-card__wrapper {
  margin-bottom: 56px;
}
.profile-card__wrapper > img.profile-img {
  margin-bottom: 24px;
  width: 100%;
}
.profile-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0f59c7;
  margin-bottom: 12px;
}
.profile-division {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #636262;
  margin-bottom: 12px;
}
