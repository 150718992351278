ul {
  padding: 0;
}
.new-search-form {
  border: none;
  padding: 0;
}

.new-search-bar-input {
  display: inline-flex;
  align-items: center;
  gap: 13px;
  height: unset !important;
  line-height: unset !important;
  width: 260px;
  border: unset !important;
  display: block;
  padding: 9px 4px 9px 40px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}
ul.mega-menu__container {
  background: #fff;
  /* padding: 1rem 1rem 0px 1rem !important; */
}
ul.submenu {
  /* padding: 1rem 1rem 0px 0px; */
}
.list_menu_nav {
  width: 311px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  transition: all ease 2s;
}
.list_menu_nav:hover #sub_menu {
  display: block;
  transition: all ease 2s;
}

.list_menu_nav:hover > div > p,
.list_menu_nav:hover > div > a {
  color: #306ccc;
}

.btn_close-modal {
  font-size: large;
  flex-shrink: 0;
  font-weight: 600;
  position: absolute;
  top: 10px;
  right: 25px;
  z-index: 9;
}
.header_modal_title {
  margin-top: 2rem;
  color: #306cce;
  text-align: center;
  font-family: Open Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.list_menu_nav > div > p {
  margin-bottom: unset !important;
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.list_menu_nav > div > a > p {
  margin-bottom: unset !important;
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.list_menu_nav .submenu li:hover,
.list_menu_nav .submenu li:hover a {
  /* margin-left: 5px; */
  color: #306ccc;
}
.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu > li {
  position: relative;
  display: inline-block;
}

.menu > li > a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  padding: 0;
}

.submenu.show {
  display: block;
}

.submenu li {
  width: 311px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.submenu a {
  padding: 10px;
  color: #333;
}
@keyframes blink {
  0% {
    color: #ffd23f;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #ffd23f;
  }
}

.blink_text {
  animation: blink 2s infinite;
  width: 66px;
  height: 25px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px 0px;
  background: #306ccc;
}
.third.header_left__item:hover #menu.mega-menu__container {
  opacity: 1;
  visibility: visible;
}

.call_sales_btn-header {
  display: inline-flex;
  padding: 8px 16px;
  align-items: flex-start;
  border-radius: 8px;
  background: #306ccc;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
}
.call_sales_btn-header:hover {
  background-color: #fff;
  color: #306ccc;
}
.call_sales_btn-header:focus {
  color: #306ccc !important;
}
.btn_login_header {
  border-radius: 8px;
  border: 1px solid #0f59c7;
  background: #fff;
  display: inline-flex;
  padding: 8px 16px;
  align-items: flex-start;
  color: #0f59c7;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}

.btn_login_header:focus,
.form-submit-button:focus {
  color: #0f59c7;
}
.carousel-inner {
  height: 100%;
}
.carousel-indicators {
  bottom: 0 !important;
}
.carousel-indicators .active {
  border-radius: 50%;
  width: 13px !important;
  height: 13px !important;
}
.modal.login_popup_modal {
  background-color: rgb(0, 0, 0, 0.5);
}
/* LoginForm.css */

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
  display: grid;
  align-items: center;
}
.form-group_checkbox {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 1.5rem;
}

.form-label {
  color: #2d2c2a;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: start;
}
.form-checkbox-label_remember {
  margin-bottom: unset !important;
}

.form-input {
  border-radius: 8px;
  border: 2px solid #d3d3d3;
  width: 322.9px;
  height: 42.958px;
  flex-shrink: 0;
  padding: 10px;
}
.form-input:focus {
  border: 2px solid #000;
}
.form-checkbox {
  margin-right: 3px;
}

.form-checkbox-label {
  color: #5f626b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%; /* 24.32px */
}

.form-submit-button {
  border-radius: 8px;
  background: #306cce;
  padding: 8px 133px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-submit-button:hover {
  background-color: #0056b3;
}
.link_reset_password {
  color: #306cce;
  text-decoration: underline;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.link_register {
  color: #306cce;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
}
.text_footer_popup {
  color: #d3d3d3;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 129.682%; /* 15.562px */
  margin-top: 3rem;
  font-family: Open Sans;
}
.position_eye_login {
  position: absolute;
  top: 47px;
  right: 15px;
}
.position_eye_login:hover {
  cursor: pointer;
}
.btn_search_input {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.list_input_type li:hover {
  background: #e9e9e9;
}
.list_input_type li:hover a {
  text-decoration: none;
}
.list_input_type li a {
  margin-right: 2rem;
  margin-left: 2rem;
}
.wrapp_list_input {
  width: 414px;
  padding-top: 30px;
}
.list_input_type li a {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.wrapp_input_popular {
  padding-left: unset;
  padding-right: unset;
}
.badge_input_seacrh {
  display: inline-flex;
  padding: 4px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 38px;
  background: #e7f0ff;
  color: #306ccc;
  text-align: center;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.title_search_input {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.seacrh_new {
  color: #306ccc;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.seacrh_popular {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.main.header_left__item,
.third.header_left__item {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: normal;
}
.main.header_left__item:hover,
.third.header_left__item:hover {
  background: unset !important;
  padding: unset;
  line-height: normal;
  font-weight: 600;
}
.main-content .middle-sidebar-header {
  width: 100%;
}
.main-content .middle-sidebar-header:hover {
  line-height: unset;
  background: unset;
  padding: unset;
  border-radius: unset;
}
.container__register {
  max-width: 500px !important;
  padding-top: 50px;
  padding-bottom: 50px;
}
.wording__title_register {
  color: #306cce;
  text-align: center;
  font-family: Open Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transform: unset !important;
  justify-content: center;
}

.modal__auth {
  height: 550px;
  padding-left: unset;
  padding-right: unset;
  overflow-x: hidden !important;
}
@media (max-width: 991px) {
  .modal__auth {
    top: unset;
    border-radius: unset !important;
  }
}
@media (min-width: 1280px) {
  .modal__auth {
    top: 12%;
  }
}
.header_left__item-sertifikasi a {
  text-align: left;
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal !important;
}
.menu-dropdown.menu-dropdown-profile .dropdown-item {
  margin-bottom: unset !important;
  margin-top: 19px;
  padding-bottom: 17px;
  border-bottom: 1px solid #e2e2e2;
}
.menu-dropdown.menu-dropdown-profile .dropdown-item:first-child {
  margin-top: unset;
}
.menu-dropdown.menu-dropdown-profile .dropdown-item:last-child {
  border-bottom: unset;
  padding-bottom: unset;
}
