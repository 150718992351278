.hero-shot__bootcamp {
  background-color: #0f59c7da;
}
.hero-shot__bootcamp-image {
  background-image: url("./../../images/bootcamp/bootcampbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 42px;
}
.hero-shot__wrapper {
  padding: 56px 0;
}
.hero-shot__header {
  font-weight: 700;
  font-size: 36px;
  color: #f8f6f6;
  line-height: 44px;
  margin-bottom: 24px;
}
.hero-shot__header > span {
  color: #ffc240;
}
.hero-shot__subheader {
  font-weight: 700;
  font-size: 24px;
  color: #f8f6f6;
  line-height: 36px;
  margin-bottom: 24px;
}
.hero-shot__cta {
  background: #f8f6f6;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  color: #0f59c7;
  border: none !important;
}
.navigation-card__section {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px;
  position: sticky;
  top: 24px;
}
.navigation-card__list {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #383838 !important;
  margin-bottom: 16px;
  font-family: "Work Sans", sans-serif;
  text-decoration: none;
}
.navigation-card__list > a {
  color: #383838 !important;
  text-decoration: none;
}

.navigation-card__list:last-child {
  margin-bottom: 32px !important;
  font-family: "Work Sans", sans-serif;
}
.navigation-card__button {
  background: #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  width: 100%;
  text-align: center;
  color: #f8f6f6;
}
.client-section__main-title,
.benefit-section__main-title,
.learning-section__main-title,
.metode-section__main-title,
.faq-section__title,
.lainnya-section__main-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #1b3965;
  margin-bottom: 24px;
}
.metode-section__main-title {
  margin-bottom: 42px !important;
}
.client-section__secondary-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #383838;
  margin-bottom: 42px;
  font-family: "Work Sans", sans-serif;
}
.navigation-card__list > .active-menu {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0f59c7 !important;
}
.benefit-section__container {
  padding: 32px 24px 16px;
  box-shadow: 0px 80px 100px -35px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  margin-bottom: 24px;
  height: 100%;
}
.benefit-section__circle {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 24px;
  background: #c9d7ed;
  box-shadow: 0px 4px 70px rgba(91, 91, 91, 0.05);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.benefit-section__card-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #383838;
  margin-bottom: 10px !important;
}
.benefit-section__card-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
}
.benefit-section__list {
  display: flex;
  justify-content: space-between;
}
.learning-section__accordion.accordion {
  margin-bottom: 0;
  padding: 24px;
}
#learning-section__listing > ul {
  padding-left: 24px !important;
}
#learning-section__listing > ul > li {
  list-style-type: disc !important;
}
.learning-section__title-list {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #1b3965;
}
.learning-section__desc-list {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #383838;
  position: relative;
  left: -20px;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 24px;
}
.learning-section__accordion-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.learning-section__accordion-text:hover {
  text-decoration: none !important;
}
.learning-section__accordion > button:hover {
  text-decoration: none !important;
}
.learning-section__accordion-desc__list {
  padding: 16px 24px;
  font-family: "Work Sans", sans-serif;
}
li.learning-section__title {
}
.learning-section__card {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 30px 24px;
  height: 100%;
}
.learning-section__title-blue {
  background: #0f59c7;
  padding: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 16px;
}
.learning-section__card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #174385;
  margin-bottom: 10px;
}
.learning-section__card-desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
}
.learning-section__wrapper {
  display: flex;
}
.learning-section__main-wrapper {
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
  padding: 24px 24px 16px;
  margin-bottom: 56px;
}
.learning-section__card-instructor {
  position: relative;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
  margin-bottom: 42px;
  width: fit-content;
}
.learning-section__card-instructor__detail {
  position: absolute;
  bottom: 16px;
  left: 16px;
}
.learning-section__card-instructor__detail > div:first-child {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.learning-section__card-instructor__detail > div:last-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #d3d6dc;
}
.metode-section__card {
  padding: 24px 32px;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
  margin-bottom: 32px;
}
.metode-section__card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #0f59c7;
  margin-bottom: 10px;
}
.metode-section__card-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
}
.metode-section__card-subtitle > span {
  font-weight: 700;
}

.metode-section__wrapper-main {
  background: #ffffff;
  padding: 24px 32px 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  text-align: start;
}

.metode-section__wrapper-main__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #1b3965;
  margin-bottom: 4px;
}
.metode-section__wrapper-main__sub {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .metode-section__wrapper-main {
    background: #ffffff;
    padding: 24px 32px 40px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .metode-section__wrapper-main__paragraph {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #838282;
  }
}
.metode-section__wrapper-main__paragraph {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #838282;
  margin-bottom: 24px;
}
.metode-section__wrapper-main__price {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #0f59c7;
  margin-bottom: 20px;
}
.metode-section__wrapper-main__btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  padding: 0 20px;
  text-align: center;
  border: none !important;
  color: #383838 !important;
  background: #ffc240;
  border-radius: 8px;
}
.metode-section__circle-wrapper {
  text-align: center;
  display: flex;
  margin-bottom: 32px;
}
.metode-section__line {
  display: none;
}
.metode-section__cirlce-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.metode-section_circle-yellow {
  background: #ffc240;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #383838;
  margin-bottom: 24px;
  flex-shrink: 0;
  align-self: normal;
}
.metode-section__circle-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  color: #1b3965;
  margin-bottom: 24px;
  margin-left: 16px;
}
.metode-section__circle-sub {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  color: #232323;
  font-family: "Work Sans", sans-serif;
  margin-left: 16px;
}
@media (min-width: 768px) {
  .metode-section__line {
    border-bottom: 6px solid #0f59c7;
    position: absolute;
    top: 16px;
    width: 65%;
    z-index: -1;
    display: block;
  }
  .metode-section__cirlce-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .metode-section_circle-yellow {
    background: #ffc240;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #383838;
    margin-bottom: 24px;
    align-self: center;
  }
  .metode-section__circle-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #1b3965;
    margin-bottom: 24px;
  }
  .metode-section__circle-sub {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #232323;
    font-family: "Work Sans", sans-serif;
  }
}

.metode-section__circle-desc {
  display: flex;
  justify-content: center;
  text-align: center;
}

.faq-section__accordion {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px;
}
.faq-section__accordion > .btn-link  {
  text-decoration: none !important;
}
.faq-section__accordion.accordion {
  margin-bottom: 24px;
}
.faq-section__accordion-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #383838;
  overflow-wrap: anywhere;
}
#learning-section-tab-pelatihan.nav-link,
#learning-section-tab-penugasan.nav-link,
#learning-section-tab-pasca.nav-link {
  background: #f8f6f6;
  border-radius: 8px 8px 0px 0px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #a3a2a2;
  padding: 10px 24px !important;
  margin-right: 16px;
}
#learning-section-tab-pelatihan.nav-link.active,
#learning-section-tab-penugasan.nav-link.active,
#learning-section-tab-pasca.nav-link.active {
  background: #0f59c7 !important;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 6px solid #ffc240 !important;
  width: 250px !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 10px 24px !important;
}
.client-section__title,
.benefit-section__title,
.learning-section__title,
.metode-section__title,
.lainnya-section__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #0f59c7;
  margin-bottom: 24px;
}
.closing-section__bootcamp {
  background-color: #1b3965;
  padding: 80px 0;
}
.closing-section__bootcamp-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #f8f6f6;
  margin-bottom: 16px;
}
.closing-section__bootcamp-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #f8f6f6;
  font-family: "Work Sans", sans-serif;
}
.closing-section__bootcamp-button {
  background: #ffc240 !important;
  border-radius: 8px;
  padding: 0 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  border: none !important;
  color: #383838 !important;
}
