.course_front__container {
  padding: 0;
}
.dashboard_page__container {
  padding-top: 40px;
  padding-bottom: 40px;
}
.main.dashboard_page__container {
  padding-top: 40px;
  padding-bottom: 40px;
}
.course_front__deskripsi_requirement__wrapper {
  padding: 24px 32px;
  background-color: #f4f4f4;
  border-radius: 24px;
  margin-bottom: 32px;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
}
.course_front__inner_title {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 16px;
}
.badge-skill {
  padding: 0px 16px;
  line-height: 32px !important;
  border: 3px solid #0069d9;
  border-radius: 8px;
  margin-right: 12px;
  margin-bottom: 8px;
}
.course_front__deskripsi {
  background: #f4f4f4;
  border-radius: 24px;
  line-height: 40px;
  padding: 24px;
  display: flex;
  margin-bottom: 32px;
  flex-wrap: wrap;
  font-family: "Nunito", sans-serif;
}
@media (min-width: 576px) {
  .course_front__deskripsi {
    background: #f4f4f4;
    border-radius: 24px;
    line-height: 40px;
    padding: 24px 32px;
    display: flex;
    margin-bottom: 32px;
    flex-wrap: wrap;
    font-family: "Nunito", sans-serif;
  }
}
.course_front__persyaratan > ul > li,
.course_front__target_audience__wrapper > ul > li {
  font-family: "Nunito", sans-serif;
}
.course_front__persyaratan {
  background: #f4f4f4;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  margin-bottom: 32px;
}
@media (min-width: 576px) {
  .course_front__persyaratan {
    background: #f4f4f4;
    border-radius: 24px;
    padding: 24px 32px;
    display: flex;
    margin-bottom: 32px;
  }
}

.course_front__persyaratan ul,
.course_front__target_audience__wrapper ul {
  padding-inline-start: 20px;
}
.course_front__persyaratan li,
.course_front__target_audience__wrapper li {
  list-style: initial !important;
}
.course_front__target_audience__wrapper {
  background: #f4f4f4;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  margin-bottom: 32px;
}
@media (min-width: 576px) {
  .course_front__target_audience__wrapper {
    background: #f4f4f4;
    border-radius: 24px;
    padding: 24px 32px;
    display: flex;
    margin-bottom: 32px;
  }
}
.course_front__target_audience_icon {
  fill: #000;
  margin-top: 8px;
  height: 40px;
  width: 40px;
  margin-right: 24px;
}
.course_front__target_audience {
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  color: #000000;
  font-family: "Nunito", sans-serif;
}
.alert-warning > div {
  font-family: "Nunito", sans-serif;
}
.course_front__sertifikasi__img__wrapper {
  margin-right: 32px;
  /* background: #cccccc; */
  border-radius: 8px;
  height: 300px;
  width: 100%;
  flex-shrink: 0;
}
.course_front__sertifikasi__wrapper {
  margin-bottom: 32px;
}
.course_front__sertifikasi_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}
@media (min-width: 768px) {
  .course_front__inner_title {
    font-size: 21px;
    line-height: 32px;
  }
  .course_front__sertifikasi__img__wrapper {
    height: 100%;
    width: 100%;
  }
}

/* utils */
.alert-warning {
  color: #fff;
  background-color: #ffaa47;
  border-color: #ffeeba;
  border-radius: 24px;
}

@media (max-width: 768px) {
  .course_front__container {
    padding: 15px;
  }
  .dashboard_page__container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.course-related__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.fade.modal.show {
  z-index: 9999;
}
