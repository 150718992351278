.hero-shot__container-cdlc {
  background-image: url("./../../images/cdlc/hero-cdlc.png");
}
.hero-shot__container-cdlc__illustration {
  background-color: #0f59c7c5;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.hero-shot__container-cdlc > div {
  height: 100%;
  display: flex;
  align-items: center;
}

.hero-shot__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #f8f6f6;
  margin-bottom: 24px;
}
.hero-shot__title > span {
  color: #ffc240;
}
.hero-shot__subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #f8f6f6;
}
.client-container {
  padding: 80px 0;
}
.client-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}
.client-list > div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .client-list > div {
    flex-direction: row;
  }
}
.client-list > img {
  display: flex;
  align-self: center;
}
.client-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #1b3965;
  margin-bottom: 24px;
}
.client-description {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #1b3965;
}
.cdlc-title {
  width: fit-content;
  margin: 0 auto 24px auto;
  padding: 10px 42px;
  background: #0f59c7;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #f8f6f6;
}
.benefit-box__container {
  background: #ffffff;
  padding: 32px 24px;
  box-shadow: 0px 50px 100px -20px rgba(0, 0, 0, 0.06);
  margin-bottom: 40px;
}
.benefit-circle__illustration {
  width: 80px;
  height: 80px;
  background: rgba(15, 89, 199, 0.1);
  border-radius: 200px;
}
.benefit-circle__illustration {
  width: 80px;
  height: 80px;
  background: rgba(15, 89, 199, 0.1);
  border-radius: 200px;
  display: flex;
  padding: 24px;
  margin-bottom: 20px;
}
.benefit-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #383838;
  margin-bottom: 16px;
}
.benefit-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Work Sans", sans-serif;
  color: #383838;
}
.see-catalog__container {
  background: radial-gradient(
    170.97% 1262.11% at 21.67% 50%,
    #0f59c7 0%,
    rgba(15, 89, 199, 0.991615) 11.79%,
    rgba(15, 89, 199, 0.967585) 21.38%,
    rgba(15, 89, 199, 0.9296) 29.12%,
    rgba(15, 89, 199, 0.879348) 35.34%,
    rgba(15, 89, 199, 0.818519) 40.37%,
    rgba(15, 89, 199, 0.7488) 44.56%,
    rgba(15, 89, 199, 0.671881) 48.24%,
    rgba(15, 89, 199, 0.589452) 51.76%,
    rgba(15, 89, 199, 0.5032) 55.44%,
    rgba(15, 89, 199, 0.414815) 59.63%,
    rgba(15, 89, 199, 0.325985) 64.66%,
    rgba(15, 89, 199, 0.2384) 70.88%,
    rgba(15, 89, 199, 0.153748) 78.62%,
    rgba(15, 89, 199, 0.0737185) 88.21%,
    rgba(15, 89, 199, 0) 100%
  );
  padding: 40px 0;
}
.see-catalog__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #f8f6f6;
  margin-bottom: 24px;
}
.see-catalog__description {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f8f6f6;
  margin-bottom: 24px;
}
.see-catalog__btn {
  background: #ffc240 !important;
  border-radius: 8px !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  color: #383838 !important;
  padding: 0 20px;
}
.cdlc-desc__container {
  padding: 0 0 80px;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .cdlc-desc__container {
    height: 100vh;
  }
  section.cdlc-desc__container:first-of-type {
    height: 65vh;
  }
}
.img-container {
  min-height: 358px;
  margin-top: 32px;
}
.cdlc-desc__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #1b3965;
  margin-bottom: 24px;
}
.cdlc-desc__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #1b3965;
  margin-bottom: 32px;
}
.cdlc-desc__subtitle > span > ul {
  padding: 0 0 0 18px !important;
}
.cdlc-desc__subtitle > span > ul > li {
  list-style-type: disc !important;
}
.cdlc-step__title {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1b3965;
  margin-bottom: 24px;
}
.cdlc-step__wrapper {
  display: flex;
  justify-content: space-around;
  position: relative;
  align-items: center;
}
.cdlc-step__circle {
  background: #1b3965;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  z-index: 9;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cdlc-step__line {
  background: #ffc240;
  height: 4px;
  position: absolute;
  width: 70%;
}
.how-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #232323;
  margin-bottom: 24px;
}
.how-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #232323;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 32px;
}
.footer-dlc {
  background: #1b3965;
}
.footer-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #f8f6f6;
  margin-bottom: 16px;
}
.footer-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #f8f6f6;
}
.cdlc-text__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
  margin-bottom: 24px;
}
.cdlc-text__subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #383838;
  margin-bottom: 24px;
}
.cdlc-btn__homepage {
  background: #0f59c7 !important;
  border-radius: 8px !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  color: #f8f6f6 !important;
  padding: 0 24px;
}

/* form mailchimp */
#mc_embed_signup_scroll {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.cdlc-title__form {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #1b3965;
  text-align: center;
  margin-bottom: 24px;
}
.mc-field-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.mc-field-group > label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  margin-bottom: 16px;
}
.mc-field-group input {
  border: 1px solid #c3c1c1;
  border-radius: 8px;
  padding: 16px;
}
.wp-block-button__link {
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  background: #ffc240 !important;
  border: none !important;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  color: #383838;
}
.newsletter-form-info > div.newsletter-form-error {
  color: #ff685d;
  font-weight: 700;
}
.newsletter-form-info > div {
  color: #00815c;
  font-weight: 700;
}
.cdlc-slide__wrapper {
  position: sticky;
  top: 50%;
  margin-top: 24px;
  display: flex;
  width: 100%;
  height: 224px;
  display: none;
}
@media (min-width: 768px) {
  .cdlc-slide__wrapper {
    display: block;
  }
}
.cdlc-slide__sticky {
  position: absolute;
  top: 20px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cdlc-slide__sticky a {
  transition: all 50ms ease-in-out;
}
.cdlc-cirlce__shape {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  left: 16px;
  background: #1b3965;
  margin-bottom: 16px;
  position: relative;
}
.cdlc-cirlce__shape.big-circle {
  transform: scale(1.8);
  background-color: #0f59c7 !important;
  border-radius: 24px;
}
