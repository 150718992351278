.company-profil__title,
.company-profil__division-title {
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  margin-bottom: 16px;
}
.logo_company__size {
  width: 55px;
  height: 55px;
  font-size: 12px;
}
@media (min-width: 576px) {
  .logo_company__size {
    width: 55px;
    height: 55px;
    font-size: 16px;
  }
}

.logo_company__size_header {
  max-height: 30px;
}
.company-prodil__mandatory-course {
  display: flex;
}
.not-found__membership {
  font-weight: 500;
  color: white;
  font-size: 12px;
}
.company-profil__wrapper-company {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.company-profil__image-profil {
  background: #7a7a7a;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
}
.company-profil__company-name,
.company-profil__leaderboard-title,
.company-profil__membership-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.company-profil__membership-card {
  padding: 21px;
  background: #ee4253;
  border-radius: 16px;
  margin-bottom: 29px;
}
.company-profil__contact-card {
  padding: 21px;
  background: #4271d6;
  border-radius: 16px;
  margin-bottom: 29px;
}
.company-profil__membership-title {
  color: #fff;
  margin-bottom: 20px;
}
.company-profil__membership-wrapper__list {
  display: flex;
  margin-bottom: 4px;
}
.company-profil__membership-badge {
  background-color: #ffc726;
  border-radius: 3px;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #17213d;
  margin-left: 8px;
  padding: 2px 8px;
}
.company-profil__leaderboard-title {
  margin-bottom: 0;
}
.company-profil__membership-image {
  width: 33px;
  height: 33px;
  background: #ffffff;
  border-radius: 6px;
  margin-right: 16px;
}
.company-profil__membership-info__wrapper {
  display: flex;
  flex-direction: column;
}
.company-profil__membership-title__detail {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
}
.company-profil__membership-title__detail-white {
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
.company-profil__membership-title__detail-white:hover {
  background-color: white;
  opacity: 0.6;
  color: black;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.company-profil__membership-subtitle__detail {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.5);
}
.company-profil__container-leaderboard {
  display: flex;
  flex-direction: column;
}
.company-profil__wrapper-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.company-profil__leaderboard-container {
  background: #f4f4f4;
  box-shadow: 0px 8px 40px -12px rgba(196, 196, 196, 0.25);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 16px;
}
.not_found__data {
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
}
.width-fit {
  width: fit-content;
}
