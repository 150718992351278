.card_round__task {
  border-radius: 0 0 24px;
  padding: 32px;
}
.card_round__task {
  background-color: #f4f4f4 !important;
}
#task > .course_card__wrapper.my-task {
  position: relative;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
  margin-bottom: 32px;
  margin-right: 24px !important;
  margin-left: 24px !important;
}
.height_task {
  min-height: calc(100vh - 210px);
}
.not-found__task {
  opacity: 0.5;
  padding: 16px 24px;
}
.blue {
  background: rgba(66, 113, 214, 0.15);
}
.blue:hover {
  background: rgba(66, 113, 214, 0.5);
}
.orange {
  background: rgba(255, 193, 7, 0.15);
}
.orange:hover {
  background: rgba(255, 193, 7, 0.5);
}
.green {
  background: rgba(40, 167, 69, 0.15);
}
.green:hover {
  background: rgba(40, 167, 69, 0.5);
}
@media (min-width: 992px) {
  #task > .course_card__wrapper.my-task {
    width: 100%;
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
}
