.file-upload-h1 {
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
}

.text-inside-upload {
  font-size: 21px;
  font-weight: 700;
}
.size.icon-file:before {
  font-size: 32px;
  color: #3776f4;
}
.wrapper {
  padding: 32px;
}
.modal-90w {
  width: 90%;
  max-width: none !important;
}
.wrapper-number {
  background-color: #3776f4;
  width: 115px;
  height: 115px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector_bucket {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 16px 0;
}
.selector_bucket > select {
  margin-right: 24px;
}
.number-inside {
  font-size: 32px;
  color: white;
  font-weight: 700;
}
.subtitle-poin {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}
.border-radius-file {
  border-radius: 24px;
}

.assignment-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}

.assignment-file:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
