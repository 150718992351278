.card_padding {
  padding: 32px;
  margin-bottom: 32px;
}
.percentage-number {
  font-weight: 700;
}
.progress-bar {
  background-color: yellow;
}
.progress {
  display: flex;
  height: 8px !important;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background: rgba(255, 255, 0, 0.6) !important;
  border-radius: 0.25rem;
}
.padding-progress {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
