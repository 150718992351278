.card_wrapper {
  border-radius: 24px;
  padding: 48px;
  display: flex;
  background: linear-gradient(98.41deg, #0059ff 0.47%, #0095ff 100%);
  box-shadow: 0px 18px 40px -12px rgba(48, 108, 208, 0.25);
  margin-bottom: 32px;
}
.button-start__course {
  background: #ffffff !important;
  box-shadow: 0px 18px 40px -12px rgba(16, 107, 180, 0.35);
  border-radius: 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #332c2c;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 24px;
  margin-top: 24px;
}
.button-start__course:hover {
  background: #ffffffe9 !important;
  text-decoration: none !important;
}
.learning-path__container {
  background: linear-gradient(
      89.83deg,
      #0f59c7 49.97%,
      rgba(15, 89, 199, 0) 99.83%
    ),
    url("https://satukelas-fe-asset.ap-south-1.linodeobjects.com/bannerlp.png");
  background-repeat: round;
  filter: drop-shadow(0px 2px 2px rgba(66, 66, 66, 0.2))
    drop-shadow(0px 0px 4px rgba(66, 66, 66, 0.2));
  padding: 80px 0;
  margin-bottom: 40px;
}
.learning-path__title-white {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #f8f6f6;
  margin-bottom: 16px;
}
.learning-path__live-black {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
}
.learning-path__live-desc {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
}

.learning-path__desc {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  font-family: "Work Sans", sans-serif;
  color: #f8f6f6;
}
.card_image {
  max-width: 200px;
  width: 200px;
  height: 130px;
  max-height: 130px;
  background-color: #fff;
  border-radius: 12px;
}
.wrapper-learning__path {
  background: #0f59c7;
  padding: 40px 0;
}
.main-header__learning-path {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 16px;
  color: #f8f6f6;
}
.sub-header__learning-path {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  font-family: "Work Sans", sans-serif;
  color: #f8f6f6;
  margin-bottom: 40px;
}
.container-kearning__path {
  background: #ffffff;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 24px;
}
.image-learning__path {
  width: 100px;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.info-learning__path {
  padding: 24px;
}
.header-learning__path {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1em;
  margin-bottom: 8px;
  color: #000000;
}
.title-learning__path {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 16px;
  color: #000000;
}
.desc-learning__path {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  color: #000000;
  font-family: "Work Sans", sans-serif;
}
.link-learning__path {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0f59c7;
  font-family: "Work Sans", sans-serif;
}
.benefit_lp {
  background: #ffc825;
}
.card_title__title_main {
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #fff;
}
.card_course_wrapper {
  margin-bottom: 24px;
}
.card_title__description {
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #fff;
}
.container-video__information {
  background: #ffffff;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
}
.next-course,
.prev-course {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #0f59c7;
}
.assesment_skill__wrapper {
  margin-bottom: 32px;
}
.assesment_skill__pengantar {
  font-size: 21px;
  line-height: 32px;
  font-weight: 700;
}