.cart_container {
  padding: 32px;
  border-radius: 24px;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  margin-bottom: 24px;
  background-color: #f4f4f4;
}
#cart.cart_container {
  background-color: #ffc825;
  max-height: 75vh;
  overflow-y: auto;
}
.card_wrapper_instructor {
  display: flex;
}
.cart_container__cert {
  padding: 32px 0;
}
.card__maximize {
  background-color: #ddd;
}
.cart_label {
  font-weight: 700;
}
.card_thumbnail__certificate {
  width: inherit;
  height: inherit;
}
.card_title_instructor {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 32px;
}
.button-save__address {
  font-size: 14px;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
  border-radius: 8px !important;
  padding: 12px 20px;
  font-weight: 500;
  background: linear-gradient(98.41deg, #0059ff 0.47%, #0095ff 100%) !important;
}
.insufficient__ {
  font-size: 0.9rem;
  color: red;
  font-weight: 600;
  margin: 0;
}
.width-fit {
  width: fit-content;
}
.button-start {
  font-size: 16px;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
  border-radius: 8px !important;
  padding: 20px 16px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  
  width: 20%;
  background: #0f59c7 !important;
}
.button-start:hover {
  font-size: 16px;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
  border-radius: 8px !important;
  padding: 20px 16px;
  font-weight: 700;
  color: #0059ff;
  text-align: center;
  width: 20%;
  text-decoration: none;
  background: white !important;
}
input[type="checkbox"] {
  box-sizing: border-box;
  margin-right: 0;
  width: 16px;
  height: 16px;
  background: gray;
}
.card_content__category {
  margin-left: 8px;
  padding: 4px 8px;
  background-color: #008bff;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}
.card_content__button_danger {
  width: 50px;
  height: 50px;
}
.card_content__button_danger_smaller {
  width: 20px;
  height: 20px;
}
.card_wrapper__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.5); */
  padding-bottom: 32px;
}
.card_content__status {
  background-color: #008bff;
  padding: 4px 8px;
  color: #fff;
  border-radius: 6px;
  display: flex;
  margin-bottom: 8px;
  font-size: 10px;
  width: -moz-fit-content;
  width: fit-content;
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 8px !important;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.card_content__wrapper {
  display: flex;
}

.card_content__title__main {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}
.card_content__title__sub {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.card_content__time {
  font-size: 12px;
  font-family: "Nunito", sans-serif;
}
.card_content__course {
  font-size: 12px;
  font-family: "Nunito", sans-serif;
}
.card_content__title__price {
  font-size: 21px;
  font-weight: 700;
}
.card_content__checkbox {
  display: flex;

  margin-right: 16px;
}

.card_promo_wrapper {
  margin-bottom: 32px;
}
.card_border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 32px;
}
.card_title {
  font-size: 16px;
  font-weight: 700;
}
.card_subtitle {
  font-size: 12px;
}
.card_price {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 8px;
}
.card_button {
  display: flex;
  flex-direction: column;
}
.modal-topup__container {
  padding: 32px;
}
.modal-topup__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}
.modal-topup__section {
  margin-bottom: 24px;
}
.modal-topup__selection {
  margin-bottom: 24px;
}
.modal-topup__button-cancel {
  background: rgba(225, 225, 225, 0.5);
  box-shadow: 0px 18px 40px -12px rgba(196, 196, 196, 0.25);
  border-radius: 12px;
  padding: 16px;
  font-weight: 600;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  border: none !important;
  color: #000000;
}
.modal-topup__button {
  background: linear-gradient(93.09deg, #3776f4 0.51%, #0095ff 100%);
  box-shadow: 0px 18px 40px -12px rgba(21, 67, 185, 0.25);
  border-radius: 12px;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  width: inherit;
}
.modal-topup__container-button {
  display: flex;
}
.modal-content {
  border-radius: 24px;
}
.modal-topup__button-cancel,
.modal-topup__button {
  width: 100%;
}
.card_content__image {
  margin-right: 10px;
  height: inherit;
  background-color: lightgray;
  width: 90px;
  height: 85px;
  border-radius: 12px;
}
.cart__wrapper {
  margin: 0;
  border: none;
}
@media (min-width: 576px) {
  .card_content__image {
    margin-right: 24px;
    height: inherit;
    background-color: lightgray;
    width: 135px;
    border-radius: 12px;
  }
}
@media (max-width: 768px) {
  .card_content__button_danger {
    margin: 0;
  }
  .card_content__button_danger {
    width: 40px;
    height: 40px;
  }
  .card_content__button_danger_smaller {
    margin: 0;
    width: 15px;
    height: 15px;
  }
  .icon-trash:before {
    content: "\e90e";
    font-size: 14px;
  }

  .icon-trash-smaller:before {
    content: "\e90e";
    font-size: 7px;
  }

  .card_content__title__main {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .card_content__category {
    margin-left: 8px;
    padding: 4px 8px;
    background-color: #008bff;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    border-radius: 4px;
  }
  .card_content__checkbox {
    display: flex;
    margin-right: -20px;
    margin-top: -4px;
  }
  .cart_container {
    padding: 32px 24px;
    border-radius: 24px;
    box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
    margin-bottom: 24px;
    background-color: #f4f4f4;
  }
  .cart__wrapper {
    margin-top: 32px;
    padding-top: 16px;
    border-style: solid;
    border-width: 1px 0 0 0;
  }
}
