input._feedback {
  margin-right: 2px;
}

._feedback.line-height__cust {
  list-style: 0.7;
  margin-right: 8px;
}

._feedback.title_label {
  font-size: 16px;
  font-weight: 700;
}

._feedback.hero_title_label {
  font-size: 24px;
  font-weight: 700;
}

.selected_feedback {
  border: 4px solid #000;
  border-radius: 4px;
}

._feedback.sub_title_label {
  font-size: 16px;
  font-weight: 400;
}

._feedback.title_label__sub {
  font-size: 14px;
  font-weight: 500;
}

::marker {
  font-weight: 700;
}

textarea._feedback {
  border-radius: 8px;
  background: #f4f4f4;
  border: none;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
}

._feedback.text-submit {
  font-weight: 700;
  font-size: 14px;
  padding: 12px 40px;
}

.col-md-10 {
  /* background-color: #fff; */
}

._feedback-input {
  position: absolute;
  opacity: 0;
}

img._feedback {
  width: 40px !important;
  height: 40px !important;
}

@media (min-width: 576px) {
  img._feedback {
    width: 60px !important;
    height: 60px !important;
  }
}

@media (min-width: 768px) {
  img._feedback {
    width: 65px !important;
    height: 65px !important;
  }
}

._feedback-input + img {
  cursor: pointer;
  transition: 0.1s all ease;
  /* margin-right: 16px; */
}

._feedback-input:hover + svg,
._feedback-input:checked + svg,
._feedback-input:focus + svg {
  transform: scale(1.1);
  border: 3px solid #008bff;
  border-radius: 4px;
}

.justify-emoticon {
  text-align-last: justify;
}
