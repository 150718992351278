.course_front__kurikulum_title,
.related_material__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
  margin-bottom: 12px;
}
.course_description__text {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
}

.tabs_course__detail {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
  padding: 24px 32px;
}
.tabs_course__detail > .nav-tabs {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 12px 0 0 12px;
}
.tabs_course__detail .nav-tabs .nav-link {
  font-weight: 700;
}
.course_front__kurikulum_title__accordion {
  font-weight: bold;
  font-size: 21px;
  line-height: normal;
  color: #000;
  margin-bottom: 16px;
}
.sertificate_wrapper__container {
  padding: 24px 32px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.course_front__duration {
  margin-top: 12px;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.course_detail__quiz__title {
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  text-align: left;
}

.course_front__kurikulum_wrapper__accordion {
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 32px;
}
.course_front__kurikulum_wrapper__accordion_detail {
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 32px;
}
.course_front__kurikulum_wrapper {
  margin-bottom: 8px;
  background: #fff;
  border-radius: 16px;
}
.course_front__kurikulum_wrapper:last-child {
  margin-bottom: unset;
  background: #fff;
  border-radius: 16px;
}
.course_detail__quiz_wrapper {
  width: 100%;
  background: #fff;
  border-radius: 16px;
  text-align: left;
}
.course_detail__quiz_wrapper__title:hover,
.course_detail__quiz_wrapper__title:focus {
  text-decoration: none;
}
.thumbnail-border {
  border-radius: 8px;
}
.course_detail__quiz_wrapper__title {
  width: 100%;
  background: #fff;
  border-radius: 16px;
  text-align: left;
}
.course_front__kurikulum_wrapper__title {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  text-align: left;
}

.course_front__kurikulum_wrapper {
  border-bottom: 1px solid #e3e1e1;
}
.course_front__kurikulum_wrapper:last-child {
  border-bottom: none;
}

.course_front__kurikulum_wrapper__title:hover,
.course_front__kurikulum_wrapper__title:focus {
  text-decoration: none;
  background-color: rgb(245, 245, 245);
}
.course_front__kurikulum__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.course_front__kurikulum__icon {
  fill: #000;
}
.course_front__material_wrapper {
  padding: 16px 0;
  width: 100%;
}
.w80p {
  width: 80% !important;
}
.max-width__detail {
  max-width: 220px;
}
.max-width__accordion {
  max-width: 286px;
}
@media (min-width: 375px) {
  .max-width__accordion {
    max-width: 220px;
  }
}
@media (min-width: 768px) {
  .max-width__accordion {
    max-width: 220px;
  }
  .max-width__detail {
    max-width: 92px;
  }
}
@media (min-width: 992px) {
  .max-width__accordion {
    max-width: 324px;
  }
  .max-width__detail {
    max-width: 92px;
  }
}
@media (min-width: 1148px) {
  .max-width__detail {
    max-width: 120px;
  }
}
@media (min-width: 1200px) {
  .max-width__accordion {
    max-width: 400px;
  }
  .max-width__detail {
    max-width: 146px;
  }
}
@media (min-width: 1300px) {
  .max-width__detail {
    max-width: 184px;
  }
}
@media (min-width: 1440px) {
  .max-width__detail {
    max-width: 214px;
  }
}
.course_front__material_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.checklist-done {
  color: #fff;
  border-radius: 3px;
}
.done-svg {
  fill: #64d480;
}
@media (min-width: 768px) {
  .course_front__kurikulum_wrapper__title {
    padding: 16px 24px;
  }
  .course_front__kurikulum__title {
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
