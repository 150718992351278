:root {
  --transition: 0.4s ease;
  --blue-gradient: linear-gradient(93.09deg, #3776f4 0.51%, #0095ff 100%);
}

html {
  scroll-behavior: smooth;
}

div:where(.swal2-container) {
  z-index: 99999 !important;
}

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: "Poppins", sans-serif !important;
  background-color: #fff;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html > body.swal2-height-auto {
  height: 100vh !important;
}

.tooltip-inner {
  /* background-color: red; */
}

/* mechanism chro */
.mechanism-chro__wrap {
  margin-top: 32px;
  padding: 32px 24px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 24px;
  background: linear-gradient(
    90deg,
    #306cce 49.35%,
    rgba(48, 108, 206, 0) 135.53%
  );
}

.mechanism-chro__wrap .mechanism-chro__content {
  width: 70%;
}

.mechanism-chro__title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  margin-bottom: 4px;
}

.mechanism-chro__desc {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  color: #fff !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.mechanism-chro__wrap .mechanism-chro__button > button {
  padding: 12px;
  border-radius: 8px;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #306ccc !important;
  background-color: #fff !important;
  border: none !important;
}

.small-notes {
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  margin-top: 16px;
  color: #8d8d8d;
}

.title_floating-card {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.percentage-progress {
  font-family: Poppins;
  color: #154a9e;
  font-size: 16px;
  line-height: 24px;
}

.hero-brevet-size {
  font-size: 20px;
  line-height: 30px;
}

.search {
  padding-top: 42px;
}

#certification_modal ol {
  padding-left: 14px !important;
}

#certification_modal li {
  list-style-type: decimal !important;
}

.next-button__modal {
  background: #0f59c7 !important;
  border-radius: 8px !important;
}

.bubble-rating__course {
  background-color: lightgray;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubble-stick-1 {
  left: -12px;
  position: relative;
}

.bubble-stick-2 {
  left: -24px;
  position: relative;
}

.bubble-stick-3 {
  left: -36px;
  position: relative;
}

#search-placeholder::placeholder {
  color: #0f59c7;
}

.background-contain {
  background-size: contain !important;
}

.layout > .dropdown-menu.show {
  width: max-content;
}

.space-padding {
  padding-top: 80px;
}

#catalogue_course .dropdown-menu.show {
  transform: translate(-172px, 80px) !important;
}

#custom-height-list {
  min-height: 330px;
}

.dropdown-text-expanded {
  font-size: 14px;
  line-height: 24px;
  color: #333;
}

.dropdown-text-expanded:hover {
  color: #000;
}

.dropdown-text {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-text::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blue-fluish {
  background-color: #f1f6ff;
  color: #327dfa;
  width: fit-content;
  font-size: 12px;
}

.blue-fluish:hover {
  background-color: #327dfa;
  color: #f1f6ff;
  width: fit-content;
  text-decoration: none;
  font-size: 12px;
}

.gray-bg {
  background-color: #f7f7f7 !important;
}

#btn-spc > #bt {
  background-color: #f7f7f7 !important;
  border: none !important;
}

.sosmeds-but {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #f7f7f7 !important;
}

.sosmeds-but:hover {
  width: 40px;
  height: 40px;
  background-color: #327dfa !important;
  border-radius: 20px;
}

.sosbtn:hover {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  fill: white !important;
}

.text-salin {
  color: #327dfa !important;
}

.table td,
.table th {
  padding: none !important;
  border-top: none !important;
}

.table thead th {
  border-bottom: none !important;
}

@media (max-width: 1200px) {
  .space-padding {
    padding-top: 50px;
  }
}

@media (max-width: 1100px) {
  .space-padding {
    padding-top: 0px;
  }
}
