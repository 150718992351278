.quiz-card {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px 24px;
}

.header-quiz {
  background: #1b3965 !important;
  border: 1px solid #1b3965 !important;
  padding: 8px !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f8f6f6;
}
.start-attempt__button {
  background: #0f59c7 !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 60px;
  color: #f8f6f6;
}
.button_lp__bnsp {
  font-weight: 600;
  background: unset;
  color: black;
  border: none;
}
.button_lp__bnsp:hover {
  opacity: 0.5;
  font-weight: 600;
  background: unset;
  color: black;
  border: none;
}
.quiz-parent-row {
  height: 100%;
}
.button_minimize {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
}
.quiz-quest-main-col,
.quiz-details-main-col {
  height: 90%;
  margin: 8px;
  padding: 16px;
  border-radius: 24px;
  background-color: #fff;
}
.quiz-details-title {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 4px;
}
.quiz-details-subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  color: #383838;
}
.feedback-details-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #383838;
}
.form-control.feedback-input {
  box-shadow: 0px 1.68453px 8.42266px rgba(0, 0, 0, 0.1),
    0px 0px 1.68453px rgba(0, 0, 0, 0.2);
  border-radius: 6.73813px;
  border: none !important;
}
.form-control.feedback-input::placeholder {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #c3c1c1;
}
.form-control.feedback-input:focus {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #c3c1c1;
  border: 2px solid #c3c1c1 !important;
}
.congrats-details-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #383838;
}
.congrats-details-subtitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #383838;
}
.quiz-details-desc {
  font-size: 0.8rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  padding: 8px;
}

.quiz-quest-main-col {
  padding: 8px 12px;
}
.quiz-quest-no {
  font-size: 0.9rem;
  font-weight: 600;
  color: #1966ff;
}
#header-table__quiz > th {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}
.quiz-next__button {
  background: #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 60px;
  padding: 0px 16px;
  color: #f8f6f6;
}
.quiz-prev__button {
  border: 1px solid #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  padding: 0px 16px;
  line-height: 60px;
  background-color: unset !important;
  color: #0f59c7 !important;
}
.duration-text__quiz {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Work Sans", sans-serif;
  color: #a3a2a2;
}
.quiz-quest-wrapper-scrollbar {
  overflow-y: auto;
  height: 100%;
}
.quiz-quest-wrapper-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f541;
}
.quiz-quest-wrapper-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f54b;
}
.quiz-quest-wrapper-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00000034;
  border: 2px solid #55555500;
}

.quiz-quest-question {
  font-size: 1rem;
  text-align: justify;
  padding: 16px;
}
.quiz-quest-ans-col {
  padding: 0px;
}
.btn-quest {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #383838;
  position: relative;
}
.btn-quest__selected {
  width: 12px;
  height: 12px;
  top: 3px;
  left: 3.5px;
  border-radius: 10px;
  position: absolute;
  background-color: #0f59c7;
}
.quiz-quest-ans-row {
  margin: 16px 0px 16px 0px;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
#content-table td {
  color: #383838;
}
.container-what__people {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
  padding: 24px;
}
.title-what__people {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
}
.desc-what__people {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}
.quiz-quest-ans-row {
  border: 1px solid transparent;
}
.quiz-quest-ans-row-selected,
.quiz-quest-ans-row:hover {
  background: #ffffff;
  border: 1px solid #f8f6f6;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
}
.quiz-quest-ans-wrapper {
  flex: 3;
}

.quiz-quest-ans-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 24px;
}

.quiz-next-submit-btn {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-right: 0px;
  margin-left: 0px;
}
@media (max-width: 992px) {
  .quiz-quest-main-col,
  .quiz-details-main-col {
    height: 90%;
  }
  .quiz-quest-main-col {
    padding: 24px;
  }
}
thead tr:nth-child(1) th {
  background-color: #fff;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 10;
}
