#zmmtg-root {
  display: none;
}
.card-wrapper__container {
  margin-bottom: 32px;
  background-color: #f4f4f4;
  padding: 24px;
}
.webinar-banner {
  background-color: lightgray;
  height: 250px !important;
  border-radius: 8px;
  margin-bottom: 24px;
}
.webinar-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .card-wrapper__container {
    margin-bottom: 32px;
    background-color: #f4f4f4;
    padding: 32px;
  }
  .webinar-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}

.webinar-subtitle {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
}
.wrapper-main__content {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .wrapper-main__content {
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
  }
}
.button-join {
  /* height: 70px; */
  background: #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #f8f6f6;
  border: none;
}
@media (min-width: 768px) {
  .button-join {
    /* height: 70px; */
    line-height: 60px;
  }
}
.instruktor-wrapper,
.date-wrapper {
  display: flex;
  margin-right: 32px;
  align-items: center;
}
.instruktor-teks,
.date-teks {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}
.wrapper-description {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 32px;
}
.related-webinar_wrapper {
  margin-bottom: 16px;
}

.related-webinar__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
}
.load-more {
  color: #007bff;
}
.load-more:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #0044ff;
}
