.height-full {
  height: 100vh;
}
.height-inherit {
  height: inherit;
}
.bg-main {
  background-color: #e4f7fe;
}
.sertifikasi_thumbnail {
  width: 100%;
  border-radius: 8px;
}
.course-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 768px) {
  .course-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
.card_container {
  /* box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25); */
  background-color: #fafafa;
}
.card_container:hover {
  /* box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25); */
  background-color: #ededee;
}
.card_inside__padding {
  padding: 24px 32px 32px;
  font-size: 14px;
}
.dashboard__section_title {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
}
.certification__section_title {
  font-size: 21px;
  line-height: 32px;
  font-weight: 700;
}
.satukelas_btn {
  transition: 0.4s ease;
}

.satukelas_btn:hover {
  cursor: pointer;
  opacity: 0.7;
}
.percentage__button {
  border-radius: 8px !important;
  font-size: 14px;
  font-weight: 600;
  padding-left: 24px;
  padding-right: 24px;
  height: fit-content;
  display: flex;
  align-self: center;
  padding-top: 12px;
  padding-bottom: 12px;
}
.sertifikasi_thumbnail__detail {
  width: 100%;
  border-radius: 4px;
  height: 100%;
  object-fit: contain;
}
.done-quiz__container {
  background: #ffffff;
  /* Shadow-4dp */

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 40px;
}
#mobile .dropdown-menu.show {
  background-color: #fff !important;
}
.breadcrumb-item > a {
  color: #0f59c7 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.badge-filter__course-reset {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ff685d;
  padding: 4px 8px;
  border: 1px solid #ff685d;
  border-radius: 8px;
}
.badge-filter__course-reset:hover {
  cursor: pointer;
}

.badge-filter__course {
  display: flex;
  padding: 4px 8px;
  background: #0f59c7;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: max-content;
  text-align: center;
  color: #f8f6f6;
}

.button-next {
  margin-top: 40px;
  width: 30%;
  background: #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  text-align: center;
  color: #f8f6f6;
}
.button-next.half {
  width: 15%;
}
.button-next:hover,
.button-next.half:hover {
  background: #174385 !important;
  color: #f8f6f6 !important;
}
.badge-filter__remove {
  color: white;
  font-size: 24px;
  margin-left: 8px;
}
.badge-filter__remove:hover {
  cursor: pointer;
}

.rbc-day-slot .rbc-event-content {
  font-size: 12px;
  margin-top: 4px;
}

.thumbnail_prerequisite {
  width: 100%;
  border-radius: 24px;
  object-fit: cover;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.certificate-desc__wrapper > div {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #383838;
  margin-bottom: 40px;
}
.certificate-desc__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
}
.certificate-btn__send {
  border: 1px solid #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  color: #0f59c7;
  background-color: transparent !important;
  padding: 0 20px;
}
.certificate-btn__send:active {
  color: #0f59c7 !important;
}
.btn-primary.certificate-btn__send:hover {
  color: #0f59c7 !important;
}
.certificate-container__download {
  box-shadow: 0px 80px 100px -35px rgb(0 0 0 / 6%);
  padding: 24px;
  margin-bottom: 42px;
}
.certificate-btn__download {
  background: #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  padding: 0 20px;
  color: #f8f6f6;
}
.step1_session,
.step2_session,
.step3_session,
.step4_session {
  width: 30px;
  height: 30px;
  background: #0f59c7;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  z-index: 2;
  flex-shrink: 0;
}
.swal2-styled.swal2-confirm {
  background: #0f59c7 !important;
  border-radius: 8px !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 60px;
  padding: 0 20px;
}
.swal2-styled.swal2-cancel {
  background: #ff685d !important;
  border-radius: 8px !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 60px;
  padding: 0 20px;
}
.react-pdf__Page > canvas {
  width: 100% !important;
  object-fit: contain;
}
.form-select {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAb0lEQVRIie2PsQ2AMAwELyyWcVIyCqSCaQmNI1kIRwEF0fjq998bHMf5lQDEjlyU7OPyDBzA3MglyeyWZGocFzlaDEkCNqu4hwCsIrp+UpcXGuvfSoaVa0lWklqeR5RrSf1k2HJL8km5lnxW7jj3nAwCHOMFhaBLAAAAAElFTkSuQmCC");
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.65rem center;
  background-size: 12px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.text_rating {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #383838;
  font-family: "Work Sans", sans-serif;
}
.dashboard-btn__download {
  border: 1px solid #0f59c7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  background-color: transparent !important;
  text-align: center;
  color: #0f59c7;
  padding: 0 20px;
}
.green-text {
  color: #00815c !important;
}
.red-text {
  color: #ff685d !important;
}
.desc-fail {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  color: #383838;
}
.seemore-btn__download {
  background: #0f59c7 !important;
  border: 1px solid #0f59c7 !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 70px;
  text-align: center;
  color: #f8f8f8;
  padding: 0 20px;
}
.btn-primary.dashboard-btn__download:hover {
  color: #0f59c7 !important;
}
.height-line {
  height: 60px;
  position: relative;
}
.line_paragrah {
  position: absolute;
  width: 80%;
  border-top: 1px solid black;
  /* margin: 0 auto; */
  top: 15px;
  display: flex;
  height: inherit;
  z-index: 0;
}
.step_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #f8f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step_desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #232323;
  text-align: center;
}
@media (min-width: 576px) {
  .sertifikasi_thumbnail {
    width: 100%;
    border-radius: 8px;
    height: 100%;
    background-size: contain;
  }
  .sertifikasi_thumbnail__detail {
    width: 100%;
    border-radius: 4px;
    height: 100%;
    object-fit: contain;
  }
}
.instructor_card__layout {
  display: flex;
  font-size: 24px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .instructor_card__layout {
    display: flex;
    font-size: 24px;
    flex-direction: row;
  }
}
.subtitle_course__info {
  margin-bottom: 24px;
  padding-left: 0px;
  padding-right: 16px;
  font-family: "Work Sans", sans-serif;
}
@media (min-width: 768px) {
  .subtitle_course__info {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.green-badge__done {
  background-color: #00815c;
  color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
  width: fit-content;
  align-items: center;
}
.done-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.subtitle_course__info_title {
  padding-left: 0px;
}
@media (min-width: 768px) {
  .subtitle_course__info_title {
    padding-left: 16px;
  }
}
.course-hover__container-category {
  padding: 4px 12px;
  background: #4271d6;
  border-radius: 16px;
  color: white;
  width: fit-content;
  margin-bottom: 4px;
}

.avatar {
  background-color: gray;
  border-radius: 15px;
  width: 25px;
  height: 25px;
}
.avatar-big {
  background-color: gray;
  border-radius: 15px;
  width: 60px;
  height: 60px;
}
.bg-white {
  background-color: #ffffff;
}
.card-rounded {
  border-radius: 20px !important;
}
.width-full {
  width: 100%;
}
.icon-post {
  position: absolute;
  left: 25px;
  top: 15px;
}
.style1-input {
  height: 56px !important;
  line-height: 55px !important;
  display: block;
  font-size: 12px;
  border: none !important;
}
.p-header {
  padding: 0.75rem 1.25rem;
}
.b-2px {
  border: 2px solid rgba(0, 0, 0, 0.125) !important;
}

.quote-style:first-child {
  font-size: 56px;
  font-weight: 600;
  opacity: 0.5;
  margin-right: 8px;
}
.quote-style:last-child {
  font-size: 56px;
  font-weight: 600;
  opacity: 0.5;
  margin-left: 8px;
}
.remove-whitespace {
  white-space: nowrap;
}

.button_interest__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #595959ff;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
}
.margin-bottom__register {
  margin-bottom: 24px;
}
.form-control:focus {
  color: #000 !important;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.memberlist {
  margin-left: -15px;
  position: relative;
}
.memberlist li {
  display: inline-block;
  float: none;
  width: 15px;
}
#ul > ul > li {
  list-style: unset;
}
.placeholder-img {
  width: 100%;
  height: 150px;
  background-color: lightgray;
}
.w30 {
  width: 30px;
}
.ls-3 {
  letter-spacing: 1px;
}
.card {
  border: none !important;
}

.blue-background {
  background-color: #3776f4;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: #008bff !important;
  padding-left: 20px;
  border-radius: 4px;
}
.green-background {
  background-color: green;
}
.white-font {
  color: #ffffff;
}
.text-blue {
  color: #3776f4;
}
.text-blacks {
  color: #000000;
}
.h-50px {
  height: 55px;
}
.btn-sort {
  background-color: rgba(0, 0, 0, 0.03);
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.box {
  min-width: 30px;
  min-height: 30px;
}
.green {
  background-color: #cff7e4;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  font-size: 14px;
  font-weight: 600;
}
.lh-30 {
  line-height: 30px;
}
.circle-1 {
  width: 40px;
  height: 40px;
  border-radius: 25px;
}
.borders {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.orange-font {
  color: #b4a47d;
}
.br-8 {
  border-radius: 8px;
}
.memberlist li.last-member a {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 30px;
  line-height: 30px;
}
.bg-greylight {
  background-color: #f5f5f5 !important;
}
.color-gray {
  color: #acb4bb;
}
.editorClassName {
  background: white;
  padding: 16px;
}
.width-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rdw-editor-main {
  height: 300px;
  box-sizing: border-box;
}
.button-share,
.button-save {
  height: 50px;
  width: 50px;
  border-radius: 4px;
}
.button-share {
  background-color: #f5f5f5;
}
.width-inherit {
  width: inherit;
}
