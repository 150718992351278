.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: unset;
  font-weight: bold;
  border: none;
  color: #000;
}

.nav-tabs #tab-unique.nav-link {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  background-color: #f7c22a;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.nav-tabs #tab-unique .nav-link:hover {
  font-weight: bold;
  border: none;
  background-color: #f7c22a;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
