.hero__shot-cdlc {
  background-image: url("./../../images/cdlc/banner-heroshot-cdlc.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
.hero__shot-dlcs {
  background-image: url("./../../images/cdlc/bg-dlcs.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
.title__heroshot-cdlc,
.title__heroshot-dlcs {
  color: #f2f2f2;
  font-family: Open Sans !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 1200px) {
  .title__heroshot-dlcs {
    width: 719px;
  }
}
.title__heroshot-cdlc span,
.title__heroshot-dlcs span {
  color: #f0c02a;
  font-family: Open Sans !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.desc__heroshot-cdlc {
  color: #f2f2f2;
  text-align: justify;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.96px;
}
@media (min-width: 768px) {
  .desc__heroshot-cdlc,
  .desc__bottom {
    width: 696px;
  }
}
.desc__heroshot-dlcs {
  color: #f2f2f2;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.96px;
}
.wrapper__heroshot-form {
  display: flex;
  justify-content: center;
  width: 501px;
  height: 474px;
  padding: 31px 34px 40px 34px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  background: #fff;
}
@media (max-width: 767px) {
  .wrapper__heroshot-form {
    width: 360px;
    height: 530px;
  }
}
.wording_heroshot-form {
  color: #306cce;
  text-align: center;
  font-family: Open Sans !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.wrapp__heroshot-form,
.wrapp__heroshot-form-popup {
  margin-bottom: 24px;
  padding-left: unset;
  padding-right: unset;
}
.wrapp__heroshot-form:last-child {
  margin-bottom: unset;
}
.wrapp__heroshot-form label,
.wrapp__heroshot-form-popup label {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: unset;
}
.wrapp__heroshot-form input,
.wrapp__heroshot-form-popup input {
  border-radius: 8px;
  border: 1.432px solid #d3d3d3 !important;
  padding: 10px 16px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.btn__form-hero-cdlc {
  display: flex;
  width: 433px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #306cce;
  border: 1px solid #306ccc;
  padding: 16px 0px;
}
@media (max-width: 767px) {
  .btn__form-hero-cdlc {
    width: 100%;
  }
}
.btn__form-cdlc {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 133px;
  border-radius: 8px;
  background: #306cce;
  border: 1px solid #306ccc;
}
@media (max-width: 1200px) {
  .btn__form-cdlc {
    padding: 15px;
  }
}
.btn__form-cdlc span,
.btn__form-hero-cdlc span {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}
.btn__form-cdlc:hover,
.btn__form-hero-cdlc:hover {
  background-color: #fff;
  border: 1px solid #306ccc;
}
.btn__form-cdlc:hover span,
.btn__form-hero-cdlc:hover span {
  color: #306ccc;
}
.modal__popup-cdlc {
  position: fixed;
  z-index: 99999999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal__body-cdlc {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 12px;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: 1120px;
  height: 643px;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .modal__body-cdlc {
    top: 50%;
    padding: 20px;
    width: 340px;
    height: 530px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .modal__body-cdlc {
    width: 500px;
    height: 530px;
  }
}
.modal__content-cdlc {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .modal__content-cdlc form {
    width: 100%;
  }
}
.modal__body-cdlc .btn__close {
  position: absolute;
  top: -20px;
  right: -8px;
  background-color: unset;
  z-index: 999999;
}
.modal__body-cdlc .btn__close span {
  font-size: 2rem;
  opacity: 0.4;
}
.parent__client-cdlc {
  background: #f5f7fb;
  margin-bottom: 70px;
}
.wording__client-cdlc {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .wording__client-cdlc {
    width: 488px;
  }
}
.wording__client-cdlc ~ p {
  color: #2d2c2a;
  text-align: justify;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.72px;
}
@media (min-width: 992px) {
  .wording__client-cdlc ~ p {
    width: 500px;
  }
}
.client__cdlc-page section div {
  margin-left: unset !important;
}
.parent__service-cdlc {
  margin-bottom: 100px;
}
.wording__title-service-cdlc {
  color: #022a6c;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.wrapp__service-cdlc {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: 3px solid #f4f4f4;
}
.wrapp__service-cdlc:last-child {
  border: unset;
}
.wording__service-cdlc {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
}
.wording__service-cdlc ~ p {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
.parent__banner-cdlc {
  margin-bottom: 63px;
}
.hero__banner-cdlc {
  background-image: url("./../../images/cdlc/banner/bg-banner.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
.wording__title-banner-cdlc {
  color: #022a6c;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.banner__circle-satukelas {
  display: flex;
  justify-content: center;
}
.desc__banner-cdlc {
  color: #065757;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .banner__wrapp-bulet img {
    width: 90px;
    height: 90px;
  }
}
.banner__wrapp-circle {
  animation: fadeInWrapp 2s ease-in-out both;
}
@keyframes fadeInWrapp {
  0% {
    justify-content: center;
  }
  100% {
    justify-content: space-between;
  }
}
.banner__display {
  display: none;
  transition: all ease-out 1s;
  opacity: 0;
}
.banner__circle-1,
.banner__circle-2,
.banner__circle-3 {
  display: block !important;
  transition: all ease-in 2s;
}

.banner__circle-1 {
  animation: fadeInCircle1 1.5s ease-in both;
}
.banner__circle-2 {
  animation: fadeInCircle2 1.5s ease-in both;
}
.banner__circle-3 {
  animation: fadeInCircle3 1.5s ease-in both;
}

@keyframes fadeInCircle1 {
  0% {
    transform: translateX(-128px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInCircle2 {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInCircle3 {
  0% {
    transform: translateX(205px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.parent__banner-katalog-cdlc {
  margin-bottom: 44px;
}
.parent__banner-lp {
  margin-bottom: 27px;
}
.wrapp__banner-katalog-cdlc .carousel-inner {
  border-radius: 12px;
}
.hero__banner-katalog-cdlc {
  background-image: url("./../../images/cdlc/bannerKatalog/banner-katalog.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1200px) {
  .hero__banner-katalog-cdlc {
    background-position: top;
  }
}
.wording__title-banner-katalog-dlcs {
  color: #f2f2f2;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (min-width: 1200px) {
  .wording__title-banner-katalog-dlcs {
    width: 633px;
  }
}
.wording__title-banner-katalog-dlcs span {
  color: #ffd23f;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.wording__title-banner-katalog-cdlc {
  color: #fff;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}
.desc__banner-katalog-cdlc {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}
@media (min-width: 768px) {
  .desc__banner-katalog-cdlc {
    width: 609px;
  }
}
.btn__banner-katalog {
  border-radius: 12px;
  background: #306ccc;
  display: inline-flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.btn__banner-katalog:hover {
  background: #fff;
  text-decoration: unset;
}
.parent__banner-cta,
.parent__banner-reverse-cta {
  background: #f5f8fb;
}
.parent__banner-reverse-cta {
  margin-bottom: 104px;
}
.parent__banner-cta {
  margin-bottom: 52px;
}
@media (min-width: 1200px) {
  .wrapp__content-cta {
    padding-left: unset;
    padding-right: unset;
  }
}
.subtitle__banner-cta {
  color: #065757;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: unset;
}
.title__banner-cta {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (min-width: 1200px) {
  .desc__banner-cta p:first-child {
    width: 668px;
  }
}
.desc__banner-cta p {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  position: relative;
  z-index: 1;
}
.footer__banner-cta {
  color: #065757;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.icon__shape-content-cta {
  position: absolute;
  bottom: 0;
  right: 0;
}
.icon__tik-1-content-cta {
  position: absolute;
  bottom: -3rem;
  right: 0;
}
.icon__tik-2-content-cta {
  position: absolute;
  top: 2rem;
  right: 0;
}
.icon__shape-content-cta-reverse {
  position: absolute;
  bottom: 0;
  left: 0;
}
.icon__tik-content-cta-reverse {
  position: absolute;
  left: 0;
  top: 2rem;
}
.hero__banner-lp-cdlc {
  background-image: url("./../../images/cdlc/banner/LP/bg-lp.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
}
@media (min-width: 991px) {
  .hero__banner-lp-cdlc {
    background-position: top;
  }
}
.hero__banner-lp-reverse-cdlc {
  background-image: url("./../../images/cdlc/banner/LP/bg-lp-reverse.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  width: 100vw;
}
@media (min-width: 991px) {
  .hero__banner-lp-reverse-cdlc {
    background-position: bottom;
  }
}
.wording__subtitle-banner-lp-cdlc,
.wording__subtitle-banner-lp-reverse-cdlc,
.wording__subtitle-banner-lms {
  color: #065757;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.wording__title-banner-lp-cdlc,
.wording__title-banner-lp-reverse-cdlc {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 56px;
}
.wording__title-banner-lms {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.subheading__banner-lp,
.subheading__banner-lp-reverse,
.subheading__banner-lms-reverse {
  color: #065757;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.list__banner-lp,
.list__banner-lp-reverse,
.list__banner-lms-reverse {
  color: #2d2c2a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  list-style: disc !important;
  margin-left: 2rem;
}
.parent__banner-lms {
  margin-bottom: 147px;
}
.parent__section-step {
  margin-bottom: 70px;
}
.wrapp__section-step {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.05);
  position: relative;
}
.wording__title-section-step {
  color: #022a6c;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 58px;
  margin-bottom: 28px;
  position: relative;
  z-index: 1;
}
.wrapp__image-step {
  margin-bottom: 31px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .wrapp__image-step img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .wrapp__image-step img {
    width: 100%;
    height: 72%;
  }
}
.wrapp_content-step {
  padding-bottom: 29px;
}
.title__desc-step {
  color: #2d2c2a;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
}
.desc__step {
  color: #2d2c2a;
  text-align: justify;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  width: 341px;
}
.icon__shape-step-left {
  position: absolute;
  top: 0;
  left: 0;
}
.icon__shape-step-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.parent__banner-bottom {
  background: #022a6c;
}
.wording__title-bottom-banner {
  color: #f2f2f2;
  font-family: Open Sans !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.desc__bottom {
  color: #f2f2f2;
  text-align: justify;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.96px;
}
div:where(.swal2-container) {
  z-index: 9999999999 !important;
}
