.pdf-main-div {
  padding: 32px;
}
.pdf-main-div:hover,
.pdf-selected {
  opacity: 0.7;
  background-color: #222;
  cursor: pointer;
}
.pdf-btn-div {
  width: 100%;
}

.pdf-btn {
  margin: 0px 8px 16px 0px;
}
