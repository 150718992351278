@charset "UTF-8";

.read_more__button {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.header-bnsp {
  display: none;
}

.close-search__button {
  top: 4px;
  right: 12px;
  font-size: 24px;
  color: white;
}

.close-search__button:hover {
  cursor: pointer;
}

.popular-course__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 60px;
  color: #f8f6f6 !important;
}

.nav-link {
  width: max-content !important;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: #0f59c7 !important;
  border-radius: 8px;
  padding: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f8f6f6;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
}

.mobile-filter__tag {
  display: flex;
  flex-flow: row;
  overflow: auto;
}

@media (min-width: 768px) {
  .mobile-filter__tag {
    display: flex;
    flex-flow: unset;
    overflow: hidden;
  }
}

.filter-course__popular {
  width: fit-content;
  position: absolute;
  left: -32px;
  height: 60px;
}

.popular-course__title:hover {
  text-decoration: none !important;
  color: #f8f6f691 !important;
}

.search-container {
  background-color: #0f59c7;
  padding: 24px 0;
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.dropdown-menu.show {
  border-radius: 8px;
  background: #0f59c7;
}

.dropdown-menu.show .dropdown-navigator {
  color: #fff;
}

.title_product {
  font-weight: 700;
  color: #fff !important;
}

.subtitle_product {
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 24px;
  color: #f8f6f6;
  font-family: "Work Sans", sans-serif;
}

.tax-review__placeholder {
  height: 200px;
  border-radius: 24px;
  margin-bottom: 40px;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
}

@media (min-width: 576px) {
  .read_more__button {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 2px;
  }

  .header-bnsp {
    display: block;
  }
}

.disabled-link {
  cursor: default;
  opacity: 0.5;
}

.disabled-link:hover {
  text-decoration: none;
}

#education_level,
#university {
  padding: 12px !important;
}

.thumbnail-wrapper {
  width: 100%;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.thumbnail-wrapper:hover {
  cursor: pointer;
}

#tutor-header>.close {
  padding: 0;
}

.modal-header#tutor-header {
  border-bottom: 0 !important;
}

.tutorial_modal {
  color: #aaa;
  font-size: 12px;
  line-height: 34px;
  font-weight: 500;
}

.tutorial_modal:hover {
  color: #aaa;
  cursor: pointer;
  line-height: 34px;
  font-weight: 500;
  opacity: 0.8;
}

.button_tutorial {
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
}

.notification_container>span.icon-notification {
  font-size: 28px;
}

.notification_container img,
.container-user img {
  cursor: pointer;
}

.name_notification__detail {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
}

.name_notification__detail_active {
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
}

.see_all__notification {
  font-size: 12px;
  font-weight: 700;
}

.notification_receipt {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
  text-align: end;
  width: 40%;
}

@media (min-width: 576px) {
  .notification_receipt {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    text-align: end;
    width: 100%;
  }
}

.notification_text {
  font-weight: 700;
  font-size: 16px;
}

.badge_soon {
  background-color: orange;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 700;
}

.login-button__header-left {
  border: 3px solid #0f59c7 !important;
  background: white;
  color: #0f59c7 !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  height: 60px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-course {
  border: 3px solid #0f59c7 !important;
  background: white;
  color: #0f59c7 !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-course:hover {
  border: 3px solid #0f59c7 !important;
  background: #0f59c7;
  color: white !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-button__header {
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  height: 60px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0f59c7;
}

.logout-text {
  cursor: pointer;
}

.satu-kelas-logo__main {
  background-image: url("../../images/logo/logo.png");
  height: 3vh;
  width: 95px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 8px;
  margin-right: 16px;
}

.satu-kelas-logo__main-footer {
  background-image: url("../../images/logo/logo.png");
  height: 5vh;
  background-size: 96px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 16px;
}

.catalogue-course>#membership {
  padding-left: 0;
  padding-right: 0;
}

.icon-bars:before {
  content: "\e900";
}

.satukelas_jamboard_dropdown .icon-bars:before {
  content: "\e900";
  font-size: 17.5px;
}

.icon-bars:hover {
  content: "\e900";
  cursor: pointer;
}

.name_notification__body {
  font-weight: 500;
  font-size: 12px;
  color: lightgray;
}

.name_notification__subtitle {
  font-size: 12px;
  color: lightgray;
  font-weight: 600;
}

.satu-kelas-logo {
  margin-right: 16px;
}

.text-black {
  color: #000 !important;
}

.box-none {
  box-shadow: none !important;
}

.catalogue-course>a {
  font-size: 16px;
  color: #0f59c7 !important;
  font-weight: 700;
}

@media (max-width: 1024px) {
  .catalogue-course>a {
    font-size: 12px;
  }
}

.bg-current {
  background-color: #3776f4 !important;
}

.fill-blue {
  fill: #3776f4;
}

svg.expandable-bars {
  width: 25px !important;
  height: 25px !important;
}

p,
h4 {
  font-family: "Work Sans", sans-serif;
}

.w30 {
  width: 30px !important;
}

.w40 {
  width: 40px !important;
}

.w200 {
  width: 200px !important;
}

li {
  list-style: none !important;
}

.z-index-1 {
  z-index: 2;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.lh-3 {
  line-height: 1.4;
}

.chat-font__size {
  font-size: 12px;
}

.bottom-0 {
  bottom: 0;
}

.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.mt--1 {
  margin-top: -0.4rem !important;
}

.mt--6 {
  margin-top: -4.2rem !important;
}

.text-grey-500 {
  color: #adb5bd !important;
}

.splide__list {
  display: flex !important;
  padding: 0 !important;
  overflow-x: auto;
}

.main-content {
  background-color: #ffff;
  /* height: 84px; */
  display: flex;
  align-items: center;
}

.main-content.menu-active {
  padding-left: 90px;
}

.main-content.menu-active .middle-sidebar-header.sticky-header {
  left: 90px;
}

.main-content .left-sidebar,
.main-content .right-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-content .left-sidebar .left-sidebar-content,
.main-content .right-sidebar .left-sidebar-content {
  background: var(--theme-color-shade);
  overflow: hidden;
  width: 270px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0px 10px 10px 10px;
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-header,
.main-content .right-sidebar .left-sidebar-content .left-sidebar-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 13px 15px 0 15px;
  background: var(--theme-color-shade);
}

@media (max-width: 767.98px) {

  .main-content .left-sidebar .left-sidebar-content .left-sidebar-header,
  .main-content .right-sidebar .left-sidebar-content .left-sidebar-header {
    padding: 20px 20px 0 20px;
  }
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom,
.main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
  background: var(--theme-color-shade);
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 20px 15px 15px 15px;
}

@media (max-width: 1399.98) {

  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom,
  .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    width: 400px;
  }
}

@media (max-width: 991.98px) {

  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom,
  .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    width: 100%;
  }
}

@media (max-width: 767.98px) {

  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom,
  .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    padding: 0 20px 0 20px;
  }
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-title a i,
.main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-title a i {
  position: relative;
  top: 3px;
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul,
.main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul {
  padding-left: 31px;
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul li a,
.main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul li a {
  font-size: 12px;
}

.main-content .middle-sidebar-header {
  top: 0;
  left: 280px;
  right: 0;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  -webkit-transition: left 0.25s;
  -o-transition: left 0.25s;
  transition: left 0.25s;
}

@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header {
    display: none;
    padding: 10px 15px 10px 15px;
    height: auto;
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
  }
}

.main-content .middle-sidebar-header .header-menu {
  display: none;
  border: 0;
  background-color: transparent;
  flex-shrink: 0;
  width: 32px;
  height: 38px;
  margin-right: 32px;
  font-size: 0;
}

.main-content .middle-sidebar-header .header-menu:focus {
  outline: none;
}

.main-content .middle-sidebar-header .header-menu:before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 2px;
  margin: 3px auto;
  border-radius: 1px;
  background: #1b1d21;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.main-content .middle-sidebar-header .header-menu:after {
  content: "";
  display: inline-block;
  width: 32px;
  height: 2px;
  margin: 3px auto;
  border-radius: 1px;
  background: #1b1d21;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.main-content .middle-sidebar-header .header-menu.active:before {
  transform: translateY(4px) rotate(45deg);
}

.main-content .middle-sidebar-header .header-menu.active:after {
  transform: translateY(-4px) rotate(-45deg);
}

@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header .header-menu {
    display: inline-block;
  }
}

@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header .header-search {
    display: none;
  }
}

.main-content .middle-sidebar-header .header-search .form-group.icon-input i {
  top: 10px;
}

.main-content .middle-sidebar-header .right-menu-icon li {
  margin-left: 25px;
}

.main-content .middle-sidebar-header .right-menu-icon li div {
  position: relative;
}

.main-content .middle-sidebar-header .right-menu-icon li div .dot-count {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  width: 7px;
  height: 7px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
}

.main-content .middle-sidebar-header .right-menu-icon li div:hover .menu-dropdown {
  visibility: visible;
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0;
  clear: both;
  font-weight: 500;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:not(:last-of-type) {
  margin-bottom: 16px;
}

.accordion {
  margin-bottom: 50px;
}

.accordion .card {
  box-shadow: none;
  background: #fff;
  margin-bottom: 25px;
  border: 0;
}

.accordion .card .card-body,
.accordion .card .card-header {
  background: #fff;
  padding: 20px 30px;
  border-bottom: 0 solid #000;
}

.accordion-course-main-div {
  padding: 24px;
  border-radius: 8px;
}

.accordion-course-main-div:hover,
.accordion-course-main-div-active {
  background: #174385;
  color: #fff;
  /* margin: 8px 0; */
}

@media only screen and (max-width: 768px) {

  .accordion .card .card-body,
  .accordion .card .card-header {
    padding: 15px 40px 15px 15px;
  }
}

.accordion .card button {
  font-size: 15px;
  color: #111;
  font-weight: 600;
  padding-left: 0;
}

.accordion .card button:focus,
.accordion .card button:hover {
  text-decoration: none;
}

.accordion .card button.small-text {
  font-size: 16px;
}

.accordion .card button.small-text a {
  color: #111;
}

.accordion .card button i {
  font-size: 30px;
  margin-right: 10px;
  position: relative;
  top: 32px;
  margin-right: 10px;
  color: #333;
  position: absolute;
  left: 33px;
}

.accordion .card button span {
  font-size: 11px;
  color: #999;
  font-weight: 400;
  display: block;
}

@media only screen and (max-width: 768px) {
  .accordion .card button:after {
    right: 10px;
  }
}

.accordion .card button.small-text:after {
  top: 33px;
}

.accordion .card button.collapsed:after {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.accordion .card p {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

.dropdown-icon {
  margin-right: 12px;
}

.mdi {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-item .dropdown-icon svg {
  height: auto;
  width: 20px;
  fill: #000;
}

.dropdown-item:last-of-type .dropdown-icon svg {
  fill: #ff5151;
}

#icon_jam .dropdown-icon svg {
  fill: #000 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: unset;
  opacity: 1;
}

.main-content .middle-sidebar-header .right-menu-icon li div .menu-dropdown {
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  width: 300px;
  padding: 25px 30px;
  box-shadow: 0px 15px 125px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  z-index: 999;
  border: 0;
  position: absolute;
  top: 120%;
  right: -5px;
  text-align: left;
  transition: all 0.4s ease;
}

.main-content .middle-sidebar-header .right-menu-icon li div .menu-dropdown.menu-dropdown-profile {
  width: 220px;
  padding: 32px 20px;
}

.menu_name {
  word-break: break-word;
}

.main-content .middle-sidebar-header .right-menu-icon li a .menu-dropdown:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -10px;
  right: 10px;
  display: inline-block;
}

.satu-kelas-logo {
  display: block;
  padding-left: 0px;
  padding-right: 0px;
  width: 148px;
  max-width: 100%;
  height: 100%;
  vertical-align: center;
  border: 0;
  box-sizing: inherit;
}

.satu-kelas-logo:hover {
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header .right-menu-icon li {
    display: none;
  }
}

.main-content .middle-sidebar-bottom {
  width: 100%;
  padding: 20px 20px 10px 20px;
}

@media (min-width: 992px) {
  .main-content .middle-sidebar-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .tax-review__placeholder {
    font-size: 4rem;
  }
}

@media (max-width: 1399.98) {
  .main-content .middle-sidebar-bottom {
    width: 400px;
  }
}

@media (max-width: 991.98px) {
  .main-content .middle-sidebar-bottom {
    width: 100%;
    padding: 76px 15px 15px;
  }
}

.main-content .middle-sidebar-bottom .middle-sidebar-left {
  width: calc(100% - 320px);
}

.main-content .middle-sidebar-bottom .middle-sidebar-right {
  z-index: 2;
  overflow-x: hidden;
}

@media (max-width: 991.98px) {
  .main-content .middle-sidebar-bottom .middle-sidebar-right {
    position: fixed;
    top: 0;
    right: -350px;
    z-index: 2;
    padding: 76px 15px 56px;
    height: calc(100vh);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .main-content .middle-sidebar-bottom .middle-sidebar-right.active-sidebar {
    right: 0px;
    width: 100%;
  }
}

.main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content {
  padding: 0 2px 0 0;
  width: 320px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 0px;
}

.main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content .card {
  border: 1px #eee solid !important;
}

@media (max-width: 991.98px) {
  .main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content {
    margin-left: auto;
  }
}

.main-content .middle-sidebar-bottom .sidebar-right {
  background-color: #1a73e9;
  position: fixed;
  top: 47%;
  right: -27px;
  transform: translateY(-47%);
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 50%;
  z-index: 222;
  visibility: hidden;
  opacity: 0;
}

.main-content .middle-sidebar-bottom .sidebar-right i {
  font-size: 18px;
  position: relative;
  left: -10px;
}

body:not(.theme-full) .main-content .middle-sidebar-header.sticky-header {
  position: fixed;
}

body:not(.theme-full) .main-content .middle-sidebar-bottom.header-padding {
  padding-top: 116px;
}

.accordion-toggle {
  position: relative;
  display: block;
  line-height: 25px;
}

.accordion-toggle.icon-none:after {
  display: none;
}

.accordion-toggle:after {
  font-family: "themify";
  position: absolute;
  content: "";
  right: -10px;
  top: 1px;
  color: #999;
  font-weight: lighter;
  transform: rotate(-180deg);
  transition: all 0.2s ease-in;
}

.accordion-toggle.collapsed::after {
  color: #999;
  transform: rotate(0deg);
  transition: all 0.2s ease-in;
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  display: none;
  height: 40px;
}

@media only screen and (max-width: 992px) {
  .navbar-toggler {
    display: inline-block;
  }
}

.navbar-toggler-icon {
  width: 27px;
  height: 2px;
  background-color: #999;
  position: relative;
  top: -2px;
}

.navbar-toggler-icon:after {
  content: "";
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  top: -8px;
  left: 0;
}

.navbar-toggler-icon:before {
  content: "";
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  bottom: -8px;
  left: 0;
}

.menu-icon {
  position: relative;
}

.menu-icon .dot-count {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  width: 7px;
  height: 7px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
}

.banner-wrap {
  background-position: right 50px top 10px;
  background-color: #e4f7fe;
}

.chat-body {
  margin: 0px 0 0;
}

@media (max-width: 991.98px) {
  .banner-wrap {
    background-position: right -150px center;
    background-size: 80%;
  }
}

@media (max-width: 767.98px) {
  .banner-wrap {
    background-position: top 0px left;
  }
}

.modal-backdrop-none .modal-backdrop.show {
  opacity: 0 !important;
}

.modal .modal-content .close {
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 1;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  display: inline-block;
  line-height: 10px;
}

.modal .modal-content .close i {
  color: #fff;
  font-size: 16px;
}

.modal.in .modal-dialog {
  transform: none;
}

.modal.in {
  opacity: 1 !important;
}

.modal-backdrop.in {
  opacity: 0.5;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -280px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -280px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.bottom.fade .modal-dialog {
  bottom: -50%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog,
.modal.left.fade.show .modal-dialog {
  left: 0;
}

.modal.bottom.fade.in .modal-dialog,
.modal.bottom.fade.show .modal-dialog {
  bottom: 0px;
}

.modal.right.fade.in .modal-dialog,
.modal.right.fade.show .modal-dialog {
  right: 0px;
}

.modal-open .modal {
  overflow-y: hidden;
}

@media (min-width: 576px) {
  .modal.wide.fade .modal-dialog {
    max-width: 800px;
  }

  .modal.wide-xl.fade .modal-dialog {
    max-width: 1000px;
  }

  .modal.side.fade .modal-dialog {
    max-width: 400px;
  }

  .modal-dialog.video-wrap {
    max-width: 900px;
  }

  .modal-dialog.video-wrap .close {
    right: -20px;
    top: -20px;
  }
}

.toggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  box-sizing: border-box;
  align-self: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}

.toggle input[type="checkbox"] {
  display: none;
}

.toggle .toggle-icon {
  z-index: 0;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  position: relative;
  transition: 0.3s;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  width: calc(40px);
  height: calc(20px + 2px);
  border-radius: 20px;
  background: #eee;
}

.toggle .toggle-icon:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  height: calc(20px - 4px);
  width: calc(20px - 4px);
  border-radius: 18px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition-duration: 0.3s;
  transform: scale(1);
}

.toggle .toggle-icon:after {
  height: calc(20px - 4px);
  width: calc(20px - 4px);
  top: 2px;
  left: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: calc(20px - 4px);
  background: #fff;
  position: absolute;
  z-index: 2;
  transform: translateX(0px);
  transition-duration: 0.3s;
  content: "";
}

.toggle input[type="checkbox"]:checked+.toggle-icon {
  background: #ff3b30;
}

.toggle input[type="checkbox"]:checked+.toggle-icon:after {
  transform: translateX(20px);
}

.toggle input[type="checkbox"]:checked+.toggle-icon:before {
  opacity: 0;
}

.form-group.icon-input {
  position: relative;
}

.form-group.icon-input i {
  position: absolute;
  left: 15px;
  top: 19px;
}

.form-group.icon-input input {
  padding-left: 35px;
}

.form-group.icon-right-input {
  position: relative;
}

.form-group.icon-right-input i {
  position: absolute;
  right: 15px;
  top: 12px;
}

.form-group.icon-right-input input {
  padding-left: 35px;
}

.form-group .style1-input {
  height: 55px;
  line-height: 55px;
  display: block;
  font-size: 12px;
}

.form-group .style1-select {
  line-height: 55px;
  height: 55px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important;
}

.form-group .style2-select {
  line-height: 60px;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important;
  border: 2px #eee solid;
  border-radius: 7px;
}

.form-group .style2-input {
  height: 60px;
  line-height: 60px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px;
}

.form-group .style2-input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input::-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input:-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input i {
  position: relative;
  top: 6px;
}

.form-group .style2-textarea {
  line-height: 20px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px;
}

.form-control {
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  display: inline-block;
  border: 2px #eee solid;
}

.form-control:focus {
  border-color: var(--theme-color);
}

.form-control.style2 {
  height: 60px;
  line-height: 60px;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file+.js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  vertical-align: middle;
  padding: 10px;
}

.input-file+.js-labelFile.btn-tertiary {
  color: #555;
  padding: 0;
  line-height: 40px;
  width: 300px;
  margin: auto;
  display: block;
  border: 2px solid #555;
}

.input-file+.js-labelFile.btn-tertiary:hover,
.input-file+.js-labelFile.btn-tertiary:focus {
  color: #888888;
  border-color: #888888;
}

.input-file+.js-labelFile i {
  font-size: 24px;
  position: relative;
  top: 5px;
  display: inline-block;
}

.input-file+.js-labelFile i.large-icon {
  font-size: 42px;
}

.input-file+.js-labelFile.has-file i {
  display: none;
}

.custom-control-input:checked~.custom-control-label.bg-success::before {
  background-color: #10d876 !important;
  border-color: #10d876 !important;
}

.bar-container {
  position: relative;
  background-color: #eee;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 5px;
  margin-top: 7px;
  width: 100%;
  float: left;
}

.bar-percentage {
  background-color: #aaa;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 5px;
  padding: 0;
  width: 0;
  transition: all 0.4s ease;
}

select.sort {
  border: 0;
  color: #999;
  width: 180px;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 15px;
  border: 2px solid #ddd;
  border-radius: 20px;
}

.nav-tabs li div {
  transition: all 0.4s ease;
}

.nav-tabs li div.active {
  border-bottom: 2px #333 solid !important;
  color: #000 !important;
}

.banner-slider .style-div {
  height: 570px;
}

@media (max-width: 1199.98px) {
  .banner-slider .style-div {
    height: 450px;
  }
}

.list-group .list-group-item {
  border-bottom: 1px solid #eee;
  position: relative;
}

.list-group .list-group-item.p-0:after {
  content: "";
  position: absolute;
  color: #ddd;
  font-family: "themify";
  right: -5px;
  top: 0px;
  font-size: 11px;
}

.list-group .list-group-item.no-icon:after {
  display: none;
}

.list-group .list-group-item .badge {
  position: absolute;
  right: 0;
  top: 18px;
}

.list-group .list-group-item span {
  font-size: 12px;
  color: #999;
  margin-top: 3px;
}

.list-group .list-group-item.style2 {
  padding: 12px;
}

.list-group .list-group-item.style2 i {
  width: auto;
  height: auto;
  color: #555;
  line-height: 24px;
}

@media (min-width: 991px) {
  .vh-lg--100 {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .vh-md-100 {
    height: 100vh;
  }
}

@media (min-width: 1600px) {
  .container-half-fluid {
    width: 1400px;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }
}

@media (min-width: 1400px) {
  .middle-sidebar-left {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .main-content .middle-sidebar-bottom .sidebar-right {
    visibility: visible;
    opacity: 1;
  }

  .main-content .middle-sidebar-bottom .middle-sidebar-left {
    width: 100%;
  }

  .middle-sidebar-right {
    position: fixed;
    top: 0;
    right: -350px;
    z-index: 2;
    padding: 15px;
    overflow: scroll;
    height: calc(100vh);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .middle-sidebar-right.active-sidebar {
    right: 0px;
  }
}

@media (max-width: 1400px) {

  .overflow-visible-xl,
  .overflow-visible-xl .owl-stage-outer {
    overflow: visible !important;
  }

  .xl-p-5 {
    padding: 3rem;
  }
}

@media (max-width: 1200px) {
  .display4-lg-size {
    font-size: 50px !important;
  }
}

@media (max-width: 991px) {
  .display2-md-size {
    font-size: 30px !important;
  }

  .display4-md-size {
    font-size: 40px !important;
  }

  .md-mb-2 {
    margin-bottom: 1rem !important;
  }

  .main-content .middle-sidebar-bottom .sidebar-right {
    display: none;
  }
}

@media (max-width: 768px) {
  .sm-mb-4 {
    margin-bottom: 2rem !important;
  }

  .sm-mt-2 {
    margin-top: 1rem !important;
  }

  .sm-mt-7 {
    margin-top: 5rem !important;
  }

  .sm-mb-5 {
    margin-bottom: 3rem !important;
  }

  .font-md-xxl {
    font-size: 28px !important;
  }

  .sm-text-center {
    text-align: center;
  }

  .sm-pt-10 {
    padding-top: 5rem;
  }
}

@media (max-width: 576px) {
  .d-none-xs {
    display: none !important;
  }

  .xs-mb-4 {
    margin-bottom: 2rem !important;
  }

  .xs-mb-2 {
    margin-bottom: 1rem !important;
  }

  .xs-p-4 {
    padding: 2rem;
  }

  .xs-pt-10 {
    padding-top: 150px;
  }

  .col-xss-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .display4-xs-size {
    font-size: 40px !important;
  }

  .font-md-xs {
    font-size: 18px !important;
  }

  .display1-sm-size {
    font-size: 25px !important;
  }
}

/*# sourceMappingURL=style.css.map */

.form-control.satukelas_input {
  background: #fff;
  border-radius: 8px;
  border: none;
  padding: 4px 12px;
  height: unset;
  line-height: 24px;
  color: rgba(44, 44, 44, 0.5);
  font-weight: 600;
}

.anim-search {
  background-color: #ffc240;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 12px;
  animation: blink 0.75s linear infinite;
}

.input-wrap {
  background-color: #fff !important;
  display: flex;
  border-radius: 8px;
  align-items: center;
  width: 100%;
}

.form-control.satukelas_input__search {
  border: none !important;
  padding: 8px 16px !important;
  background-color: #fff !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 60px;
  color: white;
}

.search-icon__hover:hover {
  cursor: pointer;
}

.form-control.satukelas_input__search ::placeholder {
  color: red;
}

#search::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-control.satukelas_input__search:focus {
  background: #f8f8f8;
  color: #0f59c7 !important;
}

.form-control.satukelas_input:focus {
  background: #f8f8f8;
  color: #000 !important;
}

.form-control.satukelas_input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(44, 44, 44, 0.5);
  opacity: 1;
  /* Firefox */
}

.form-control.satukelas_input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(44, 44, 44, 0.5);
}

.form-control.satukelas_input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(44, 44, 44, 0.5);
}

.shadow-box__search {
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
}

.main.header_left__item,
.second.header_left__item,
.third.header_left__item {
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 24px;
  /* color: #0f59c7; */
  display: flex !important;
  padding: 20px 20px !important;
  align-items: center;
  position: inherit;
  cursor: pointer;
}

.main.header_left__item:hover,
.second.header_left__item:hover,
.third.header_left__item:hover,
.catalogue-course:hover {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0f59c7;
  background: #c9d7ed;
  padding: 20px 20px !important;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  text-decoration: none !important;
}

.catalogue-course>.nav-link {
  padding: 0 !important;
}

@media (max-width: 1024px) {
  .header_left__item {
    font-size: 12px;
  }
}

.dropdown-navigator {
  font-size: 14px !important;
}

.header_left__item a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.title_megamenu {
  background: #ffc240;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.subtitle_megamenu {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: "Work Sans", sans-serif;
  color: #383838;
}

.menu_list__mega {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Work Sans", sans-serif;
  color: #f8f6f6;
  position: relative;
}

.menu_list__mega span#new {
  font-weight: 600;
  font-size: 10px;
  position: absolute;
  right: 4px;
  top: -4px;
}

.menu_list__mega span#new::before {
  content: "";
  background-color: #ffc240;
  width: 20px;
  height: 20px !important;
  padding: 0px 6px;
  line-height: 0 !important;
  margin-right: 5px;
  border-radius: 15px;
  animation: blink 0.75s linear infinite;
}

.menu_list__mega_more {
  color: #ffc240;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  font-family: "Work Sans", sans-serif;
}

.title_menu_list__mega {
  color: #f8f6f6;
  font-weight: 700;
}

.second_title__megamenu,
.third_title__megamenu {
  background: #0f59c7;
}

.header_left__item.interaction-active {
  background: #c9d7ed;
  padding: 20px !important;
  border-radius: 8px;
}

.catalogue-course.interaction-active {
  background: #c9d7ed;
  padding: 16px !important;
  border-radius: 8px;
}

.second_title__megamenu:last-child {
  background: #0f59c7;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.last_menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.modal-header .close {
  margin: 0;
}

@media(min-width:1199px) {
  .container_header {
    display: block !important;
  }
}