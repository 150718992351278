.course-card {
  position: relative;
  background-color: #fcfcfc;
  box-shadow: 0px 2px 2px rgb(66 66 66 / 20%), 0px 0px 4px rgb(66 66 66 / 20%);
  border-radius: 8px;
}
.course-category__hover {
  margin-bottom: 8px;
}
.instructor_name__popular {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapper_start__date_course {
  background: linear-gradient(98.41deg, #0059ff 0.47%, #0095ff 100%);
  padding: 16px 24px !important;
  box-shadow: 0px 18px 40px -12px rgba(48, 108, 208, 0.25);
  border-radius: 12px;
  margin-bottom: 40px;
}
li.listing {
  list-style: auto !important;
}
.progress_number__styling {
  font-weight: bold;
  font-size: 32px;
  line-height: 60px;

  color: #000000;
}
.detail_front__page {
  padding-bottom: 12px;
  padding-top: 12px;

  background: linear-gradient(98.41deg, #0059ff 0.47%, #0095ff 100%);
  box-shadow: 0px 18px 40px -12px rgba(48, 108, 208, 0.25);
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 31px;
  color: #ffffff;
}
.progress_wrapper__card {
  padding: 24px;
}
.modal_progress {
  max-width: 630px;
}
.progress_wrapper__title_small {
  font-weight: 600;
  font-size: 16px;
  line-height: 31px;
  color: #000000;
}
.course_modal__title {
  font-weight: bold;
  font-size: 24px !important;
  line-height: 40px !important;
  color: #000000;
  margin-bottom: 40px !important;
}
.data_time {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
}
.started_time__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 16px;
}
.wrapper-progress__mycourse {
  background: linear-gradient(98.41deg, #0059ff 0.47%, #0095ff 100%);
}
.pt-lg--7 {
  padding-top: 75px !important;
}
.course-hover__thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.bg-grey {
  background-color: #eee !important;
}
.bg-greylight {
  background-color: #f5f5f5 !important;
}
.course-card .card-image {
  overflow: hidden;
}
.course-card .card-image img {
  transition: all 0.4s ease !important;
  position: relative;
}
.course-card:hover .card-image img {
  transform: scale(1.1);
}
.container_done__task,
.container_inprogress__task,
.container_progress__task {
  font-weight: 700;
  font-size: 14px;
  color: #212529 !important;
  font-family: "Poppins", sans-serif !important;
}
.button_plus__container {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
}
#popular .course_card__wrapper:hover .course-hover__container {
  display: block;
}

.course_card__wrapper:hover .course-hover__container {
  display: block;
}

#progress-course .progress-bar {
  background-color: #4271d6;
}
#progress-course .progress {
  background: lightgray !important;
}
.course_card__lp_price__wrapper {
  margin-bottom: 8px;
  white-space: nowrap;
}
.course_card__count {
  background: #ff685d;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #f8f6f6;
}
.course_card__learning_path {
  padding: 4px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0f59c7;
  background-color: #c9d7ed;
  border-radius: 8px;
  overflow: hidden;
  max-width: 170px;
}
.course_card__webinar {
  padding: 4px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: #383838;
  color: #f8f6f6;
  border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  max-width: 160px;
}
.course_card__wishlist {
  position: absolute;
  top: 0;
  margin: 16px;
  font-size: 10px;
  color: #000;
  right: 0;
}
.fill-red {
  fill: #ee4253;
}

.course-hover__container::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid #fff;
  border-bottom: 8px solid transparent;
  position: absolute;
  top: 10%;
  left: -8px;
  transform: translateY(-50%);
}
@media (min-width: 375px) {
  #course_search .course_card__wrapper {
    width: calc(100% - 16px);
  }
}
@media (min-width: 576px) {
  #course_search .course_card__wrapper {
    width: calc(100% - 16px);
  }
  .search > .row {
    justify-content: center;
  }
}
@media (min-width: 768px) {
  #course_search .course_card__wrapper {
    width: calc(50% - 16px);
  }
}
@media (min-width: 992px) {
  #course_search .course_card__wrapper {
    width: calc(50% - 16px);
  }
  .course_card__wrapper:hover:nth-last-child(3n - 2) .course-hover__container {
    display: block;
    position: absolute;
    width: 290px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 6px -3px rgb(48 56 64 / 20%),
      0 10px 14px 1px rgb(48 56 64 / 14%), 0 4px 18px 3px rgb(48 56 64 / 12%);
    border: none;
    right: 250px;
    top: 0;
    margin-left: 8px;
    z-index: 2;
    color: #212936;
  }

  .course_card__wrapper:hover:nth-last-child(3n - 2)
    .course-hover__container::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 8px solid #fff;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 10%;
    left: 290px;
    transform: translateY(-50%);
  }
}
@media (min-width: 1200px) {
  #course_search .course_card__wrapper {
    width: calc(33% - 16px);
  }
  .course_card__wrapper:hover:nth-last-child(3n - 2) .course-hover__container {
    display: block;
    position: absolute;
    width: 290px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 6px -3px rgb(48 56 64 / 20%),
      0 10px 14px 1px rgb(48 56 64 / 14%), 0 4px 18px 3px rgb(48 56 64 / 12%);
    border: none;
    right: 130px;
    top: 0;
    margin-left: 8px;
    z-index: 2;
    color: #212936;
  }

  .course_card__wrapper:hover:nth-last-child(3n - 2)
    .course-hover__container::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 8px solid #fff;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 10%;
    left: 290px;
    transform: translateY(-50%);
  }
}
#popular .course-hover__container {
  position: absolute;
  max-height: 350px;
  overflow-y: auto;
  width: 320px;
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 6px 6px -3px rgb(48 56 64 / 20%),
    0 10px 14px 1px rgb(48 56 64 / 14%), 0 4px 18px 3px rgb(48 56 64 / 12%);
  border: none;
  right: -200px;
  top: 0;
  margin-left: 8px;
  z-index: 2;
  color: #212936;
  display: none;
}
.course-hover__container {
  position: absolute;
  width: 320px;
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 6px 6px -3px rgb(48 56 64 / 20%),
    0 10px 14px 1px rgb(48 56 64 / 14%), 0 4px 18px 3px rgb(48 56 64 / 12%);
  border: none;
  right: -200px;
  top: 0;
  margin-left: 8px;
  z-index: 2;
  color: #212936;
  display: none;
}

.course-hover__topInfo {
  display: flex;
  margin-bottom: 8px;
}

.course-hover__logo-wrapper {
  width: 40px;
  height: 40px;
  background: blueviolet;
  margin-right: 8px;
}

.course-hover__tutor-wrapper {
  font-size: 12px;
}
.course_card__learningpath_wrapper {
  border-radius: 8px;
  padding: 4px 8px;
  background-color: #f5d698;
  position: absolute;
  top: 0;
  margin: 16px;
  font-size: 10px;
  color: #000;
}
.course_card__infocourse_wrapper {
  display: none;
}
.course-hover__tutor-wrapper .tutor-name {
  font-weight: 600;
}

.course-hover__objectives > li {
  letter-spacing: 1px;
}

.course-hover__summary {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.75px;
}

.course-hover__container ul {
  margin: 0;
  padding-inline-start: 16px;
}

.course-hover__container li {
  list-style-type: disc !important;
}

.course-hover__objectives {
  font-size: 14px;
  margin-bottom: 16px;
}

.course-hover__bottomInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-hover__additionalInfo {
  font-size: 14px;
}

.course_card__wrapper {
  position: relative;
  box-shadow: 0px 2px 2px rgba(66, 66, 66, 0.2),
    0px 0px 4px rgba(66, 66, 66, 0.2);
  border-radius: 8px;
  margin-bottom: 32px;
  margin-right: 8px;
  margin-left: 8px;
}
.course_card__wrapper_task {
  position: relative;
  border-radius: 16px;
  max-width: unset;
  background: #ffffff;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  margin-bottom: 32px;
  margin-right: 32px;
  margin-left: 32px;
}
.course_card__thumbnail_wrapper {
  height: 124px;
  border-radius: 0;
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;
}

.course_card__thumbnail {
  width: 100%;
  flex-shrink: 0;
  object-fit: cover;
  height: 100%;
  border-radius: 8px 8px 0 0;
}
.size-icon {
  width: 15px !important;
  height: 15px !important;
}
.label_tab {
  position: absolute;
  color: #fff;
  background: #0691fd;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
  border-radius: 12px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  width: 100px;
  height: 28px;
  bottom: 16px;
  left: 16px;
  font-weight: bold;
}
.course_card__info_wrapper {
  padding: 0 20px;
  margin-bottom: 16px;
  height: 100%;
}

.course_card__info_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course_card__lesson_num {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #383838;
}
.course_card__lesson_hours {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #383838;
  display: flex;
  align-items: center;
}
.course_card__lesson_price {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: end;
}
.see-detail__button {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  color: #0f59c7;
}
.see-detail__button.hoverable {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  color: #f8f8f8;
  background-color: #0f59c7;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}
.course_title__list {
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
}
.container-instructor__name {
  height: 42px;
}
.instructor-name__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #383838;
}
.instructor-img__container {
  width: 35px;
  height: 35px;
  background-color: lightgray;
  border-radius: 25px;
  margin-right: 12px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-name__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #636262;
}
.course_subtitle__list {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.5);
}
.course_card__lesson_title {
  font-weight: bold;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #383838;
  min-height: 48px;
  display: -webkit-box;
  margin-bottom: 8px;
  /* max-width: 200px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 60px;
}
.course_card__lesson_title.list {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
  max-width: 100%;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: hidden;
}
.certificate_card__wrapper .course_card__lesson_title {
  margin-bottom: 24px;
}
.certification__subtitle {
  font-size: 16px;
  margin-bottom: 24px;
}
.satukelas_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sinarmas_certificate_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
}

.certificate_card__wrapper .btn {
  background: #0691fd;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
  border-radius: 11px;
  height: 36px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 0px 16px;
  color: #ffffff;
}

.certificate_card__wrapper .btn.transparent {
  background: transparent;
  color: #0691fd;
}
.score-item,
.repeat-item {
  font-size: 14px;
}
.certificate_card__wrapper .btn.transparent svg {
  fill: #0691fd;
}

.certificate_card__wrapper svg {
  margin-right: 4px;
  fill: white;
}
.score-item > svg,
.repeat-item > svg {
  fill: black;
}
.certificate_card__wrapper .failed svg {
  fill: rgba(0, 0, 0, 0.5);
}

.label_sertifikat {
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  background: #96f4a7;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  border-radius: 12px;
  top: 16px;
  right: 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  width: 75px;
  height: 28px;
  font-weight: bold;
}

.label_sertifikat.failed {
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
  background: #f49696;
}
.listing_card {
  width: 255px !important;
}
@media (max-width: 768px) {
  .course_card__infocourse_wrapper {
    display: block;
    position: absolute;
    top: 0;
    margin: 20px;
    font-size: 10px;
    color: #000;
    right: 0;
  }
}

.vertical-text {
  width: 100%;
  font-size: 1.3em;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.vertical-text-wrapper {
  max-width: unset;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-text-wrapper:hover {
  cursor: pointer;
}
