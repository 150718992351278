.minat-container{
    display: flex;
    flex-wrap: wrap;
}

.minat-wrapper {
    display: block;
    position: relative;
}

.minat-check-input:checked+label{
    padding: 12px 16px;
    border-radius: 12px;
    width: auto;
    background-color: #4271d6;
    opacity: 1;
    color: white;
    border-color: transparent;
    cursor: pointer;
}

.complete-profile.button_interest__user {
    color: #595959ff;
    padding: 12px 16px;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 12px;
    width: auto;
    margin-bottom: 12px;
    margin-right: 8px;
    position: relative;
    left: -16px;
    background-color: #fff;
}

.button_interest__user:hover {
    padding: 12px 16px;
    border-radius: 12px;
    width: auto;
    background-color: #4271d6;
    opacity: 1;
    color: white;
    border-color: transparent;
    cursor: pointer;
}