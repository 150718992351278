.container-user {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}
.see_membership {
  font-size: 12px;
  margin-bottom: 12px;
}
.profil_info {
  width: 100%;
}

.profile_saldo__information {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.social_media__container {
  display: flex;
  flex-flow: wrap;
}
@media (min-width: 576px) {
  .profile_saldo__information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
  }
  .profile_saldo__information .balance_title {
    margin-bottom: 16px;
  }
  .see_membership {
    font-size: 16px;
    margin-bottom: 8px;
  }
}
.button_topup__profile {
  padding: 0 12px;
  font-size: 12px;
  height: 28px;
  font-weight: 700;
  background: white;
  color: #000;
  border-radius: 8px !important;
  border: none !important;
}
.button_topup__profile:hover {
  padding: 0 12px;
  font-size: 12px;
  height: 28px;
  font-weight: 700;
  background: white;
  opacity: 0.5;
  color: #000;
  border-radius: 8px !important;
  border: none !important;
}
.container-user.grey {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-color: lightgray;
  align-items: center;
  justify-content: center;
  display: flex !important;
}
.balance_info__user,
.expired_info__user,
.expire_info__membership {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
.icon-user.size-profile {
  width: 16px;
}
.icon-user.size-homepage {
  font-size: 32px;
}
.membership_text {
  font-weight: 700;
}
.membership_include {
  font-size: 12px;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0px 16px;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 12px;
}
.expired_at__text {
  font-size: 14px;
  opacity: 0.6;
}
.profile__top_wrapper {
  background: #ffffff;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  border-radius: 24px;
  margin-bottom: 32px;
}

.my_own__course_profile {
  max-height: 285px;
  overflow: auto;
}
.profile_cancel__btn,
.profile_edit__btn {
  font-weight: 500;
}

.profile__name_wrapper {
  background: #008bff;
  box-shadow: 0px 18px 40px -12px rgba(27, 124, 206, 0.25);
  border-radius: 24px;
  padding: 24px;
}
.profile__kontak_wrapper {
  padding: 24px;
}
@media (min-width: 576px) {
  .profile__name_wrapper {
    background: #008bff;
    box-shadow: 0px 18px 40px -12px rgba(27, 124, 206, 0.25);
    border-radius: 24px;
    padding: 32px;
  }
  .profile__kontak_wrapper {
    padding: 32px;
  }
}
.balance_title {
  font-weight: 600;
  color: #fff;
  font-size: 12px;
}

.profile__name_container {
  display: flex;
  align-items: center;
}

.profile__kontak {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 16px;
  color: #000000;
}

.profile__name {
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  color: #ffffff;
  word-break: break-word;
}

.profile__join_date {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 8px;
}

.profile_icon__wrapper {
  background: #008bff;
  border-radius: 8px;
  width: 40px;
  margin-right: 8px;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_icon__wrapper svg {
  fill: #fff;
}
svg.svg-sosmed {
  width: 16px;
  height: 16px;
}
.profile_email {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  word-break: break-word;
}

/*  */
.biodata_wrapper {
  /* background: #ffffff; */
  /* box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25); */
  /* border-radius: 0 24px 24px 24px; */
  /* padding: 32px; */
  margin-bottom: 32px;
}

.biodata_title {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 16px;
}

.profile_label_input {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 16px;
}

.satukelas_input {
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
  /* background: rgba(225, 225, 225, 0.5); */
  border-radius: 12px;
  border: none;
  padding: 14px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  width: -webkit-fill-available;
}

.satukelas_input:focus {
  outline: none;
}

.date_picker_input {
  color: transparent;
}

.biodata_wrapper .satukelas_input {
  margin-bottom: 24px;
  width: 100%;
}

.profile_biodata_btn__wrapper {
  float: right;
}
.profile_cancel__btn {
  font-size: 14px;
  line-height: 24px;
  padding: 4px 24px;
}
.profile_biodata_btn {
  background: linear-gradient(93.09deg, #3776f4 0.51%, #0095ff 100%);
  box-shadow: 0px 18px 40px -12px rgb(21 67 185 / 25%);
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  padding: 4px 24px;
  font-weight: 500;
}

.profile_biodata_btn:hover {
  cursor: pointer;
  opacity: 0.7;
}

.profile_biodata_btn.secondary {
  background: #efefef;
  color: rgba(0, 0, 0, 0.38);
}

.membership_card__wrapper {
  background: #ffffff;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .membership_card__wrapper {
    background: #ffffff;
    box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
    border-radius: 24px;
    padding: 32px;
    display: flex;
    justify-content: space-between;
  }
}

.membership_card__price {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
}

.membership_card__type {
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  position: relative;
}

/* .membership_card__type::before {
  content: "";
  background-color: #7f7f7f;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
} */

.profile__my_course__wrapper {
  background: #ffffff;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 32px;
}
@media (min-width: 576px) {
  .profile__my_course__wrapper {
    background: #ffffff;
    box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 32px;
  }
}
.Pajak {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* identical to box height */

  color: #000000;
}

.Grey-Pajak {
  background: #efefef;
  box-shadow: 0px 18px 40px -12px rgba(195, 195, 195, 0.25);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.Grey-Pajak > img {
  object-fit: cover;
  border-radius: 6px;
}

.White-Pajak {
  background: #ffffff;
  width: 40px;
  height: 30px;
  margin-right: 19px;
}
.custom_class_change_password {
  padding: 16px 0px;
}
.modal_sinarmas__profile .modal-content {
  max-height: unset !important;
}
