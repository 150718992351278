.filter_button {
  padding-left: 16px;
  height: 45px !important;
  /* line-height: 25px !important; */
  border-radius: 6px !important;
  display: block;
  box-shadow: 0px 18px 40px -12px rgb(195 195 195 / 25%);
  padding-right: 16px;
}
.font-sorted {
  font-weight: 500;
  color: lightgray;
}
.course_search__filter_container {
  border-radius: 24px;
  padding: 40px 32px;
  background-color: #f4f4f4;
}
.filter-text {
  font-size: 12px;
  font-weight: 600;
}
.filter-info {
  font-weight: 600;
}
.course_search__flter_by {
  font-weight: bold;
  font-size: 21px;
  line-height: 36px;
  color: #332c2c;
  margin-bottom: 16px;
}

.course_search__category_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* color: rgba(51, 44, 44, 0.5); */
  margin-bottom: 16px;
}

.search_box__btn_search {
  background-color: #0f59c7;
  border-radius: 8px;
  padding: 10px;
}

.show_filter__accordion {
  background: #0069d9;
  width: 120px;
  border-radius: 8px !important;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  margin-right: 0;
  border: none;
  color: white;
}
@media(min-width: 768px){
  .show_filter__accordion {
    background: #0069d9;
    width: auto;
    border-radius: 8px !important;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
    margin-right: 0;
    border: none;
    color: white;
  }
}
.accordion-sticky {
  position: sticky;
  top: 24px;
  height: calc(100vh - 40px);
  overflow: scroll !important;
}
/* containers */
.dashboard_page__container.search {
  max-width: 100%;
}
.course_card__wrapper.list {
  width: 100%;
}
.course-result__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 16px);
}

.course-result__container .course_card__wrapper {
  margin-left: 8px;
  margin-right: 8px;
}

.course_card__wrapper {
  width: 100%;
  background-color: #ffff;
}
.course_card__wrapper:hover {
  background-color: #fcfcfc;
  box-shadow: 0px 2px 2px rgb(66 66 66 / 20%), 0px 0px 4px rgb(66 66 66 / 20%);
}
#task .course_card__wrapper {
  width: -webkit-fill-available;
  margin: 0 24px 24px 24px;
}
@media (min-width: 576px) {
  .course_card__wrapper {
    width: calc(50% - 16px);
  }
}
@media (min-width: 992px) {
  .course_card__wrapper {
    width: calc(33% - 16px);
  }
}

@media (min-width: 1300px) {
  .course_card__wrapper {
    width: calc(25% - 16px);
  }
}
@media (min-width: 1440px) {
  .dashboard_page__container.search {
    max-width: 1440px;
  }
}
